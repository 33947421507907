<template>
	<div>
		<div class="py-3 px-5 fs-4 fw-800 bg-white- text-primary-">
			PRO GESTION SOFT
		</div>
		<hr class="m-0" />
		<div class="bg-light- py-4" style="height: calc(100vh - 12vh)">
			<div class="container h-100">
				<div class="row h-100">
					<div class="my-auto">
						<div class="row">
							<div class="col-md-6 text-center">
								<img src="@/assets/images/SANS_FOND_SIGNATURE.png" alt="" />
								<h4 class="text-center fw-600 text-three- mt-4">
									{{$t('auth.coming_back')}}
								</h4>
								<h2 class="text-primary- text-center fs-3 mt-4">
									{{$t('auth.manag_effectively')}} <br />
									{{$t('auth.your_business')}} <br />
									{{$t('auth.now')}}
								</h2>
							</div>
							<div class="col-md-6">
								<div
									class="col-lg-8 rounded bg-primary-light- mx-auto border-black- shadow"
								>
									<form
										@submit.prevent="loginSubmit"
										class="py-4 px-4"
										v-if="step == 1"
									>
										<div class="error mb-4">{{ error_message }}</div>
										<InputMe
											v-model.trim="login"
											:type="'email'"
											:error="errors.login"
											:placeholder="$t('global.email')"
										/>
										<InputMe
											:type="'toggle-password'"
											v-model.trim="password"
											:error="errors.password"
											:placeholder="$t('auth.pwd')"
											class="mt-4"
										/>
										<SpinnerMe :elementId="'spinner'" />
										<input
											type="submit"
											value="Connexion"
											id="submit"
											class="button mt-4 w-100"
										/>
										<div class="mt-4 text-center">
											<router-link
												to="forgot-password"
												class="text-primary- text-4"
												>{{$t('auth.fogort_pwd')}}</router-link
											>
										</div>
									</form>
									<form
										@submit.prevent="auth2"
										class="py-4 px-4"
										v-if="step == 2"
									>
										<p class="text-center fw-600 text-5 text-three- mt-2">
											{{ message }}
										</p>
										<div class="error mb-4">{{ error_message1 }}</div>
										<InputMe
											v-model.trim="code"
											:error="errors.code"
											:placeholder="$t('auth.verification_code')"
										/>
										<SpinnerMe :elementId="'spinner1'" />
										<input
											type="submit"
											:value="$t('auth.verify_code')"
											id="submit1"
											class="button mt-4 w-100"
										/>
										<div class="mt-4 text-center">
											<router-link
												to="forgot-password"
												class="text-primary- text-4"
												>{{$t('auth.fogort_pwd')}}</router-link
											>
										</div>
									</form>
									<div class="p-3 text-center bordert-black-">
										<span class="text-black- fw-600">{{$t('auth.are_you_new')}}</span> <br />
										<div class="mt-2">
											<router-link to="register" class="button-second- w-100"
												><span class="text-white">{{ $t('auth.signup') }}</span></router-link
											>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<Footer />
	</div>
</template>

<style>
img {
	height: 170px;
}
</style>

<script>
import Footer from '@/components/Footer.vue';
import InputMe from '@/components/InputMe.vue';
import SpinnerMe from '@/components/SpinnerMe.vue';
import Helper from '@/helpers/helper';
export default {
	name: 'LoginPage',
	data() {
		return {
			login: '',
			step: 1,
			password: '',
			error_message: '',
			error_message1: '',
			message: '',
			ok: true,
			password_visible: true,
			errors: [],
		};
	},
	components: { Footer, InputMe, SpinnerMe },
	methods: {
		loginSubmit() {
			let platform = require('platform');

			if (this.beforeLogin()) {
				this.ok = true;
				this.error_message = '';
				Helper.loading('submit', 'spinner', true);
				let registerFormData = new FormData();
				registerFormData.append('login', this.login);
				registerFormData.append('password', this.password);
				registerFormData.append('browserName', platform.name);
				registerFormData.append('browserVersion', platform.version);
				registerFormData.append('os', platform.os);

				

				fetch(
					Helper.route('auth/login'),
					Helper.requestOptions('PUT', registerFormData)
				)
					.then((response) => {
						if (response.status === 401) {
							this.ok = false;
						}
						return response.json();
					})
					.then((data) => {
						Helper.loading('submit', 'spinner', false);

						if (data.problems) {
							this.errors.login = Helper.getError(data.problems, 'login');
							this.errors.password = Helper.getError(data.problems, 'password');
						} else {
							if (!this.ok) {
								this.error_message = data.message;
							} else {
								if (data.need2FAuth) {
									this.step = 2;
									this.message = data.message;
								} else {
									localStorage.setItem(Helper.CLIENT_TOKEN, data.token);
									Helper.redirect(Helper.route('home', null, false));
								}
							}
						}
					})
					.catch((error) => {
						console.log(error);
						Helper.loading('submit', 'spinner', false);
					});
			}
		},
		auth2() {
			let platform = require('platform');

			if (this.beforeAuth2()) {
				this.ok = true;
				this.error_message1 = '';
				Helper.loading('submit1', 'spinner1', true);
				let registerFormData = new FormData();
				registerFormData.append('code', this.code);
				registerFormData.append('browserName', platform.name);
				registerFormData.append('browserVersion', platform.version);
				registerFormData.append('os', platform.os);

				fetch(
					Helper.route('auth/login-2fauth'),
					Helper.requestOptions('PUT', registerFormData)
				)
					.then((response) => {
						if (response.status === 401) {
							this.ok = false;
						}
						return response.json();
					})
					.then((data) => {
						Helper.loading('submit1', 'spinner1', false);

						if (data.problems) {
							this.errors.code = Helper.getError(data.problems, 'code');
						} else {
							if (!this.ok) {
								this.error_message1 = data.message;
							} else {
								Helper.setLocalstorage(Helper.CLIENT_TOKEN, data.token);
								Helper.redirect(Helper.route('home', null, false));
							}
						}
					})
					.catch((error) => {
						console.log(error);
						Helper.loading('submit1', 'spinner1', false);
					});
			}
		},
		beforeLogin() {
			if (Helper.required(this.login) === '') {
				delete this.errors.login;
			} else {
				this.errors.login = Helper.required(this.login);
			}
			if (Helper.required(this.password) === '') {
				delete this.errors.password;
			} else {
				this.errors.password = Helper.required(this.password);
			}
			return Helper.objectNumberKey(this.errors) == 0 ? true : false;
		},
		beforeAuth2() {
			if (Helper.required(this.code) === '') {
				delete this.errors.code;
			} else {
				this.errors.code = Helper.required(this.code);
			}
			return Helper.objectNumberKey(this.errors) == 0 ? true : false;
		},
	},
	watch: {
		login: function () {
			if (Helper.required(this.login) === '') {
				delete this.errors.login;
			} else {
				this.errors.login = Helper.required(this.login);
			}
		},
		password: function () {
			if (Helper.required(this.password) === '') {
				delete this.errors.password;
			} else {
				this.errors.password = Helper.required(this.password);
			}
		},
	},
};
</script>
