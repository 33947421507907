<!-- eslint-disable no-mixed-spaces-and-tabs -->
<template>
	<div>
		<Template title="null" sidebarMenu="employees">
			<section class="row px-4 m-0 g-4 mt-1">
				<Back />

				<!-- Modal -->
				<div
					class="modal fade"
					id="deleteModal"
					data-mdb-backdrop="static"
					data-mdb-keyboard="false"
					tabindex="-1"
					aria-labelledby="deleteModalLabel"
					aria-hidden="true"
				>
					<div class="modal-dialog modal-dialog-centered">
						<div class="modal-content rounded-0">
							<div class="modal-body">
								<h6 class="text-center text-red-">
									{{$t('global.deletion_warning')}}
								</h6>
								<hr />
								<p class="text-5" v-show="!deleted">
									{{$t('section.deletion_text')}}
								</p>
								<p class="text-5 text-center" v-show="deleted">
									{{$t('global.deleted_successfully')}}
									<i class="uil uil-check-circle fs-4 text-primary-"></i>
								</p>
								<hr />
								<div class="row">
									<div class="col text-center">
										<button
											type="button"
											class="border bg-light px-3 text-5 rounded py-1"
											data-mdb-dismiss="modal"
										>
											{{$t('global.close')}}
										</button>
									</div>
									<div class="col text-center" v-show="!deleted">
										<button
											type="button"
											class="bg-red- text-white- px-3 rounded py-1 text-5 border-0 set-opacity"
											data-mdb-dismiss="modal"
											@click="deleteSection()"
										>
											{{$t('global.delete')}}
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="col-xl-9">
					<div
						class="bg-second- shadow-sm text-uppercase text-center rounded-pill text-5 text-white px-4 p-1"
					>
						{{$t('global.preview')}}
					</div>
					<LoadingMain :state="loading" />
					<div
						v-if="section == null && !loading"
						class="p-5 text-red- text-5 text-center"
					>
						{{$t('section.section_cant_find')}}
					</div>
					<div class="row mt-1 g-4" v-if="section !== null">
						<div class="col-xl-12">
							<div class="border">
								<div
									class="text-red- text-uppercase text-5 bg-light-hight- text-center p-1"
								>
									{{$t('section.about_section')}}
								</div>
								<div class="text-center m-2">
									<div class="row py-2">
										<div
											class="col-4 text-center text-black- text-primary- fw-1000"
										>
											<h6 class="mb-2 text-6">{{$t('section.section_name')}}</h6>
											{{ section.name }}
										</div>
										<div class="col-4 text-center text-black-">
											<h6 class="mb-2 text-6">{{$t('section.section_code')}}</h6>
											{{ section.code }}
										</div>
										<div class="col-4 text-center text-black- ms-auto">
											<h6 class="mb-2 text-6">{{$t('section.section_manager')}}</h6>
											{{
												section.responsible !== null
													? section.responsible.lastname +
													  ' ' +
													  section.responsible.firstname
													: ''
											}}
										</div>
									</div>
								</div>
								<div>
									<div
										class="text-red- text-uppercase text-5 bg-light-hight- text-center p-1"
									>
										{{$t('global.description')}}
									</div>
									<div class="p-4 lh-lg text-black- mb-0 text-justify text-5">
										{{ section.description }}
									</div>
								</div>
							</div>
						</div>

						<div class="mt-2">
							<span
								class="button bg-red- p-1 text-6 px-3"
								data-mdb-toggle="modal"
								data-mdb-target="#deleteModal"
								role="button"
								@click="deleted = false"
								>{{$t('global.delete')}}</span
							>
						</div>

						<div class="col-12">
							<div class="border">
								<div
									class="text-red- text-uppercase text-5 bg-light-hight- text-center p-1"
								>
									{{$t('section.section_employees')}}
								</div>
								<div
									class="table-responsive mt-0"
									v-if="section.employees.length > 0"
								>
									<table
										class="table employees align-middle table-hover table-striped mb-0"
										style="width: 100% !important"
									>
										<thead class="bg-primary-">
											<tr class="text-6 text-white">
												<th>{{$t('resources.fullname')}}</th>
												<th>{{$t('resources.registration_number')}}</th>
												<th>{{$t('resources.grade')}}</th>
												<th>{{$t('global.state')}}</th>
											</tr>
										</thead>
										<tbody class="bg-alt-">
											<tr
												v-for="employee in section.employees"
												:key="employee.id"
												class="text-6 text-black"
											>
												<td>
													<router-link
														:to="{
															name: 'employee',
															params: { id: employee.id, slug: employee.slug },
														}"
													>
														{{ employee.lastname + ' ' + employee.firstname }}
													</router-link>
												</td>
												<!-- <td>{{ getJobs(employee.jobs) }}</td> -->
												<td>{{ employee.matricule }}</td>
												<td>{{ employee.grade }}</td>
												<td>{{ employee.state }}</td>
											</tr>
										</tbody>
									</table>
								</div>
								<div v-else class="p-3 text-black- text-5 text-center">
									{{$t('section.no_employee_find')}}
								</div>
							</div>
						</div>

						<div class="col-12 mb-4">
							<div class="border">
								<div
									class="text-red- text-uppercase text-5 bg-light-hight- text-center p-1"
								>
									{{$t('section.section_postes')}}
								</div>
								<div v-if="section.jobs?.length != 0">
									<div class="table-responsive mt-0">
										<table
											class="table jobs align-middle table-hover table-striped mb-0"
										>
											<thead class="bg-primary-">
												<tr class="text-6 text-white">
													<th></th>
													<th>{{$t('poste.designaton')}}</th>
													<th>{{$t('global.description')}}</th>
												</tr>
											</thead>
											<tbody class="bg-alt-">
												<tr
													v-for="(job, index) in section.jobs"
													:key="job.id"
													class="text-6 text-black"
												>
													<td>{{ index + 1 }}</td>
													<td>
														<router-link
															:to="{
																name: 'postes-show',
																params: { id: job.id, slug: job.slug },
															}"
														>
															{{ job.designation }}
														</router-link>
													</td>
													<td>{{ job.description }}</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
								<div
									class="py-4 text-center text-5 text-black-"
									v-if="!loading && section.jobs?.length == 0"
								>
									{{$t('section.poste_no_find')}}
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="col-xl-3">
					<div class="rounded bg-second- text-white text-4 text-center p-1">
						{{$t('global.advice')}}
					</div>
					<div
						class="mt-1 bg-light-hight- text-alt- rounded text-7 text-center p-2"
					>
						{{$t('section.show_advice_text')}}
					</div>
				</div>
			</section>
		</Template>
	</div>
</template>
<script>
import Template from '@/components/Template.vue';
import Back from '@/components/Back.vue';
import Helper from '@/helpers/helper';
import LoadingMain from '@/components/LoadingMain.vue';
export default {
	name: 'ShowSectionPage',
	components: { Template, Back, LoadingMain },
	data() {
		return {
			id: '',
			loading: false,
			ok: true,
			section: null,
		};
	},
	methods: {
		getJobs(jobs) {
			return Helper.jobs(jobs);
		},
		getSection() {
			this.loading = true;
			fetch(Helper.route('admin/section/' + this.id), Helper.requestOptions())
				.then((response) => {
					Helper.tokenChecker(response.status);
					if (response.status != 200) {
						this.ok = false;
					}
					return response.json();
				})
				.then((data) => {
					this.loading = false;
					if (data.data) {
						this.section = data.data;
						console.log(this.section);
					}
				})
				.catch((error) => {
					this.loading = false;
					console.log(error);
				});
		},
		deleteSection() {
			this.loading = true;

			fetch(
				Helper.route('admin/section/delete/' + this.id),
				Helper.requestOptions('DELETE')
			)
				.then((response) => {
					Helper.tokenChecker(response.status);
					if (response.status != 200) {
						this.ok = false;
					}
					return response.json();
				})
				.then((data) => {
					this.loading = false;

					if (data.problems) {
						Helper.notification('error', data.message);
					} else {
						Helper.notification('success', data.message);
					}
				})
				.catch((error) => {
					console.log(error);
					this.loading = false;
				});
		},
	},
	created() {
		this.id = this.$route.params.id;
		this.getSection();
	},
};
</script>
