<template>
	<div>
		<Template title="null" sidebarMenu="employees">
			<div class="bg-light-hight- hstack py-3 ps-2 pb-3">
				<router-link
					to="/employees"
					class="bg-light-hight- ms-3 text-6 top-item"
					>{{ $t('resources.employees') }}</router-link
				>
				<router-link
					to="/sections"
					class="bg-light-hight- ms-4 text-6 top-item"
					>{{ $t('resources.sections') }}</router-link
				>
				<router-link
					to="/postes"
					class="bg-light-hight- ms-4 text-6 top-item"
					>{{ $t('resources.postes') }}</router-link
				>
				<router-link
					to="/contrats"
					class="bg-light-hight- ms-4 text-6 top-item"
					>{{ $t('resources.contracts') }}</router-link
				>
				<router-link
					to="/formations"
					class="bg-light-hight- ms-4 text-6 top-item"
					>{{ $t('resources.training') }}</router-link
				>
			</div>

			<section class="row px-4 m-0 g-4 mt-1">
				<!-- Modal -->
				<div
					class="modal fade"
					id="deleteModal"
					data-mdb-backdrop="static"
					data-mdb-keyboard="false"
					tabindex="-1"
					aria-labelledby="deleteModalLabel"
					aria-hidden="true"
				>
					<div class="modal-dialog modal-dialog-centered">
						<div class="modal-content rounded-0">
							<div class="modal-body">
								<h6 class="text-center text-red-">
									{{ $t('global.deletion_warning') }}
								</h6>
								<hr />
								<p class="text-5" v-show="!deleted" v-html="$t('training.training_deletion_text')">
								
								</p>
								<p class="text-5 text-center" v-show="deleted">
									{{ $t('global.deleted_successfully') }}
									<i class="uil uil-check-circle fs-4 text-primary-"></i>
								</p>
								<hr />
								<div class="row">
									<div class="col text-center">
										<button
											type="button"
											class="border bg-light px-3 text-5 rounded py-1"
											data-mdb-dismiss="modal"
										>
											{{ $t('global.close') }}
										</button>
									</div>
									<div
										class="col text-center"
										v-show="!deleted && selectedList.length > 0"
									>
										<button
											type="button"
											class="bg-red- text-white- px-3 rounded py-1 text-5 border-0 set-opacity"
											@click="deletes()"
										>
											{{ $t('global.delete') }}
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="col-xl-9 mb-5">
					<div v-if="!loading && bests.length != 0">
						<div
							class="bg-second- shadow-sm text-uppercase text-center rounded text-5 text-white px-4 p-1"
						>
							{{ $t('training.recommendations') }}
						</div>
						<div class="row mt-1 g-2 mb-4">
							<!-- <div class="col-lg-3" v-for="(recommandation, index) in bests" :key="index">
                                <div class="rate_item bg-light-hight-1 rounded">
                                    <div class="hstack fit-content- mx-auto p-3">
                                        <img src="@/assets/images/user.png" class="rounded-circle" alt="">
                                        <div class="ms-2 vstack">
                                            <span class="text-5">{{ recommandation.rate }}</span>
                                            <i class="uil uil-favorite text-orange- fs-5"></i>
                                        </div>
                                    </div>
                                    <div class="bg-second- text-center text-white rounded p-2 text-5">
                                        <router-link :to="{ name: 'employee', params: {id: recommandation.id, slug: recommandation.slug}}">
                                            {{ recommandation.lastname+" "+recommandation.firstname }}
                                        </router-link>
                                    </div>
                                </div>
                            </div> -->

							<div
								class="col-lg-3"
								v-for="(training, index) in bests"
								:key="index"
							>
								<div class="rate_item bg-light-hight-1 rounded">
									<div class="fit-content- text-center mx-auto p-1 py-2">
										<i class="uil uil-star text-orange- fs-2 mb-2"></i>
										<div class="ms-2 vstack">
											<span class="text-4 text-black">
												<router-link
													:to="{
														name: 'formation',
														params: { id: training.id, slug: training.slug },
													}"
												>
													{{ $filters.truncate(training.name, 25) }}
												</router-link>
											</span>
										</div>
									</div>
									<div class="bg-second- text-white rounded p-2 text-5">
										<div class="hstack">
											<span class="text-6 text-white">{{
												$filters.convertDate(training.startDate, false)
											}}</span>
											<span class="ms-auto text-6">{{
												training.startTime
											}}</span>
										</div>
										<div class="mt-1">
											<span class="text-6 text-white">{{
												training.nature == 1
													? $t('training.online')
													: $t('training.in_person')
											}}</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="hstack">
						<router-link
							to="/formations/create"
							class="bg-second- rounded ms-auto px-3 p-1"
						>
							<i class="uil uil-plus text-white"></i>
							<span class="ms-2 text-6 text-white">{{
								$t('training.add_training')
							}}</span>
						</router-link>
					</div>

					<div class="hstack mt-4 p-2 px-4 rounded shadow-1 bg-white">
						<input
							type="checkbox"
							:checked="allIsCheck"
							@click="check(0, true)"
						/>
						<i
							class="uil uil-trash fs-6 ms-3"
							type="button"
							data-mdb-toggle="modal"
							data-mdb-target="#deleteModal"
							@click="deleted = false"
						></i>

						<div class="hstack ms-auto">
							<label for="" class="text-5 me-2">{{
								$t('global.filter')
							}}</label>
							<select class="form-control text-6" v-model="orderBy">
								<option value="id">{{ $t('global.order_of_addition') }}</option>
								<option value="name">{{ $t('training.alphabetical') }}</option>
								<option value="nature">{{ $t('training.nature') }}</option>
								<option value="startDate">
									{{ $t('training.start_date') }}
								</option>
								<option value="endDate">{{ $t('training.end_date') }}</option>
							</select>

							<label for="" class="text-5 ms-2 me-2">{{
								$t('global.order')
							}}</label>
							<select class="form-control text-6" v-model="order">
								<option value="ASC">Asc</option>
								<option value="DESC">Desc</option>
							</select>
						</div>

						<div class="ms-auto hstack">
							<i
								class="uil uil-arrow-circle-left fs-5"
								type="button"
								@click="previous"
							></i>
							<small class="ms-3"
								>{{ Math.ceil(formations.length / 25) > 0 ? page : 0 }} /
								{{ Math.ceil(formations.length / 25) }}</small
							>
							<i
								class="uil uil-arrow-circle-right fs-5 ms-3"
								type="button"
								@click="next"
							></i>
							<div class="hstack ms-5">
								<i
									class="uil uil-apps fs-6 p-1 px-3 rounded"
									:class="
										store.disposition == 'grid'
											? 'bg-black- text-white-'
											: 'bg-white- text-black-'
									"
									@click="store.setDisposition('grid')"
									type="button"
								></i>
								<i
									class="uil uil-list-ul fs-6 ms-1 p-1 px-3 rounded"
									:class="
										store.disposition == 'list'
											? 'bg-black- text-white-'
											: 'bg-white- text-black-'
									"
									@click="store.setDisposition('list')"
									type="button"
								></i>
							</div>
						</div>
					</div>

					<LoadingMain :state="loading" />

					<div v-if="!loading && formations.length != 0">
						<div
							class="table-responsive mt-0"
							v-if="store.disposition == 'list'"
						>
							<table
								class="table formations align-middle table-hover table-striped mb-0"
							>
								<thead class="bg-primary-">
									<tr class="text-6 text-white">
										<th></th>
										<th>{{ $t('training.event') }}</th>
										<th>{{ $t('training.section') }}</th>
										<th>{{ $t('training.start_date') }}</th>
										<th>{{ $t('training.end_date') }}</th>
										<th>{{ $t('training.nature') }}</th>
										<th>{{ $t('training.place_or_link') }}</th>
									</tr>
								</thead>
								<tbody class="bg-alt-">
									<tr
										v-for="(formation, index) in formations"
										:key="formation.id"
										v-show="show(index)"
										class="text-6 text-black"
									>
										<td>
											<input
												type="checkbox"
												:checked="isSelected(formation.id)"
												@click="check(formation.id)"
											/>
										</td>
										<td>
											<router-link
												:to="{
													name: 'formation',
													params: { id: formation.id, slug: formation.slug },
												}"
											>
												{{ $filters.truncate(formation.name, 25) }}
											</router-link>
										</td>
										<td>
											{{
												$filters.truncate(getSections(formation.sections), 25)
											}}
										</td>
										<td>
											{{ $filters.convertDate(formation.startDate, true) }}
										</td>
										<td>{{ $filters.convertDate(formation.endDate, true) }}</td>
										<td>
											{{
												formation.nature == 1
													? $t('training.online')
													: $t('training.in_person')
											}}
										</td>
										<td>{{ formation.linkOrPlace }}</td>
									</tr>
								</tbody>
							</table>
						</div>

						<div v-if="store.disposition == 'grid'">
							<div class="row g-2 mt-0">
								<div
									class="col-lg-3"
									v-for="(formation, index) in formations"
									:key="index"
								>
									<div class="rate_item bg-light-hight-1 rounded">
										<div class="fit-content- text-center mx-auto p-1 py-2">
											<i class="uil uil-star text-orange- fs-2 mb-2"></i>
											<div class="ms-2 vstack">
												<span class="text-4 text-black">
													<router-link
														:to="{
															name: 'formation',
															params: {
																id: formation.id,
																slug: formation.slug,
															},
														}"
													>
														{{ $filters.truncate(formation.name, 25) }}
													</router-link>
												</span>
											</div>
										</div>
										<div class="bg-second- text-white rounded p-2 text-5">
											<div class="hstack">
												<span class="text-6 text-white">{{
													$filters.convertDate(formation.startDate, false)
												}}</span>
											</div>
											<div class="hstack mt-1">
												<div class="">
													<span class="text-6 text-white">{{
														formation.nature == 1
															? $t('training.online')
															: $t('training.in_person')
													}}</span>
												</div>
												<div class="ms-auto">
													<input
														type="checkbox"
														:checked="isSelected(formation.id)"
														@click="check(formation.id)"
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div
						class="py-4 text-center text-black-"
						v-if="!loading && formations.length == 0"
					>
						{{ $t('global.none_element') }}
					</div>
				</div>

				<div class="col-xl-3">
					<div class="rounded bg-second- text-white text-4 text-center p-1">
						{{ $t('global.advice') }}
					</div>
					<div
						class="mt-1 bg-light-hight- text-alt- rounded text-7 text-center p-2"
						v-html="$t('training.index_advice_text')"
					></div>
					<router-link to="/formations/create"
						><button class="button bg-alt- btn-sm w-100 mt-1 p-1">
							{{ $t('training.post_job_now') }}
						</button>
					</router-link>

					<div class="" style="margin-top: 75px">
						<div class="rounded bg-second- text-white text-4 text-center p-1">
							{{ $t('global.today_summary') }}
						</div>
						<div
							class="mt-1 bg-light-hight- text-alt- rounded text-7 text-center p-2"
						>
							<div class="row px-3">
								<div class="col-6 py-1 text-start">
									{{ $t('training.all_online') }}:
								</div>
								<div class="col-6 py-1 text-alt- text-end">
									{{ $filters.formatAmount(stats.totalOnline, false) }}
								</div>
								<div class="col-6 py-1 text-start">
									{{ $t('training.total_present') }}:
								</div>
								<div class="col-6 py-1 text-alt- text-end">
									{{ $filters.formatAmount(stats.totalPresential, false) }}
								</div>
								<div class="col-6 py-1 text-start">
									{{ $t('training.total_suspended') }}:
								</div>
								<div class="col-6 py-1 text-alt- text-end">
									{{ $filters.formatAmount(stats.totalSuspended, false) }}
								</div>
								<div class="col-6 py-1 text-start">
									{{ $t('training.total_training') }}:
								</div>
								<div class="col-6 py-1 text-alt- text-end">
									{{ $filters.formatAmount(stats.totalTrainings, false) }}
								</div>
							</div>
						</div>
						<div class="bg-alt- mt-1 p-1 rounded text-center">
							<span class="text-6 text-white-">TAGS</span>
							<p class="text-6 p-0 m-0 text-yellow-">
								#Ponctualité #Dynamisme #Sérieux #Rigueur
							</p>
						</div>
					</div>
				</div>
			</section>

			<section>
				<div class="bg-white-"></div>
			</section>
		</Template>
	</div>
</template>
<script>
import Template from '@/components/Template.vue';
import Helper from '@/helpers/helper';
import LoadingMain from '@/components/LoadingMain.vue';
import { useDispositionStore } from '@/store/disposition';

export default {
	setup() {
		const store = useDispositionStore();
		return { store };
	},
	name: 'FormationsPage',
	components: { Template, LoadingMain },
	data() {
		return {
			deleted: false,
			archived: false,
			loading: true,
			formations: [],
			bests: [],
			errors: [],
			disposition: this.store.disposition,
			page: 1,
			perPage: 10,
			orderBy: 'id',
			order: 'DESC',
			name: '',
			code: '',
			message_section_add: '',
			description: '',
			selectedList: [],
			stats: {
				totalOnline: 0,
				totalPresential: 0,
				totalSuspended: 0,
				totalTrainings: 0,
			},
		};
	},
	methods: {
		check(id, all = false) {
			if (all) {
				if (this.selectedList.length !== this.formations.length) {
					this.selectedList.length = 0;
					this.formations.forEach((formation) => {
						this.selectedList.push({ id: formation.id });
					});
				} else {
					this.selectedList.length = 0;
				}
			} else {
				let found = false;
				this.selectedList.forEach((element) => {
					if (element.id == id) {
						found = true;
					}
				});
				if (found) {
					this.selectedList.filter((value, index) => {
						if (value.id == id) {
							this.selectedList.splice(index, 1);
						}
					});
				} else {
					this.selectedList.push({ id: id });
				}
			}
			if (this.selectedList.length === this.formations.length) {
				this.allIsCheck = true;
			} else {
				this.allIsCheck = false;
			}
		},
		show(index) {
			return (
				index < this.page * this.perPage &&
				index + 1 > (this.page - 1) * this.perPage
			);
		},
		next() {
			if (this.page * this.perPage < this.formations.length) {
				this.page++;
			}
		},
		previous() {
			if (this.page >= 2) {
				this.page--;
			}
		},
		isSelected(id) {
			let found = false;
			this.selectedList.forEach((element) => {
				if (element.id == id) {
					found = true;
				}
			});
			return found;
		},
		loadList() {
			this.loading = true;
			fetch(
				Helper.route(
					'admin/training/all?sortBy=' + this.orderBy + '&order=' + this.order
				),
				Helper.requestOptions()
			)
				.then((response) => {
					Helper.tokenChecker(response.status);
					return response.json();
				})
				.then((data) => {
					this.loading = false;

					if (data.data) {
						this.formations = data.data;
						this.bests = data.relevants;
					}
				})
				.catch((error) => {
					console.log(error);
				});
		},
		loadStats() {
			this.loading = true;
			fetch(Helper.route('admin/training/stats'), Helper.requestOptions())
				.then((response) => {
					Helper.tokenChecker(response.status);
					return response.json();
				})
				.then((data) => {
					this.loading = false;
					console.log(data);
					this.stats = data.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		deletes() {
			this.$isLoading(true);
			let body = {
				ids: this.selectedList.map((element) => element.id),
			};
			fetch(
				Helper.route('admin/training/delete'),
				Helper.requestOptionsJSON('DELETE', body)
			)
				.then((response) => {
					Helper.tokenChecker(response.status);
					return response.json();
				})
				.then((data) => {
					this.$isLoading(false);
					console.log(data);
					this.deleted = true;
					for (let i = 0; i < this.selectedList.length; i++) {
						const element = this.selectedList[i];
						this.formations = this.formations.filter(
							(i) => i.id !== element.id
						);
						this.bests = this.bests.filter((i) => i.id !== element.id);
					}
					this.selectedList = [];
					// Helper.notification("success", data.message)
				})
				.catch((error) => {
					this.$isLoading(false);
					console.log(error);
				});
		},
		getSections(sections) {
			return Helper.sections(sections);
		},
	},
	created() {
		this.loadList();
		this.loadStats();
		// fetch(Helper.route('admin/training/revelant'), Helper.requestOptions())
		// .then(response => response.json())
		// .then(data => {
		//     if (data.data) {
		//         this.bests = data.data
		//     }
		// })
		// .catch(error => {
		//     console.log(error)
		// });
	},
	watch: {
		orderBy: function () {
			this.loadList();
		},
		order: function () {
			this.loadList();
		},
		code: function () {
			if (Helper.required(this.code) === '') {
				delete this.errors.code;
			} else {
				this.errors.code = Helper.required(this.code);
			}
		},
		description: function () {
			if (Helper.required(this.description) === '') {
				delete this.errors.description;
			} else {
				this.errors.description = Helper.required(this.description);
			}
		},
		name: function () {
			if (Helper.required(this.name) === '') {
				delete this.errors.name;
			} else {
				this.errors.name = Helper.required(this.name);
			}
		},
	},
};
</script>
<style>
.rate_item img {
	height: 70px;
	width: 70px;
	object-fit: cover;
}
.formations th,
.formations td {
	color: white !important;
}
.employe_item img {
	height: 100px;
	width: 100px;
}
</style>
