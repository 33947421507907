<template>
    <div class="bg-second- p-4">
        <div class="col-11 mx-auto">
            <div class="hstack footer">
                <a :href="SITE_URL+'about'" target="_blank">À propos</a>
                <a :href="SITE_URL+'legal-notice'" target="_blank" class="ms-auto">Mentions légales</a>
                <a :href="SITE_URL+'terms'" target="_blank" class="ms-auto">Conditions et Règles d'utilisation</a>
                <a :href="SITE_URL" target="_blank" class="ms-auto">Contact</a>
                <a :href="SITE_URL+'faq'" target="_blank" class="ms-auto">FAQ</a>
                <a :href="SITE_URL+'support-us'" target="_blank" class="ms-auto">Nous Soutenir</a>
                <a :href="SITE_URL+'fonctionalities'" target="_blank" class="ms-auto">Fonctionnalités</a>
            </div>
            <div class="mt-2 text-1 text-center text-white- fw-bold">
                © PGS {{ new Date().getFullYear() }}
            </div>
        </div>
    </div>
</template>


<style>
.footer a {
    font-size: 14px;
    color: var(--white) !important;
}
</style>

<script>
import Helper from '@/helpers/helper';
export default {
    name: "FooterComponent",
    data() {
        return {
            SITE_URL: "",
        }
    }, 
    created() {        
        this.SITE_URL = Helper.SITE_URL
    }
}
</script>