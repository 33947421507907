<template>
	<div>
		<Template :title="$t('mails.visualize_mail')" sidebarMenu="mails">
			<section class="p-5 py-4">
				<Back />

				<div
					class="modal fade"
					id="deleteModal"
					data-mdb-backdrop="static"
					data-mdb-keyboard="false"
					tabindex="-1"
					aria-labelledby="deleteModalLabel"
					aria-hidden="true"
				>
					<div class="modal-dialog modal-dialog-centered">
						<div class="modal-content rounded-0">
							<div class="modal-body">
								<h6 class="text-center text-alt-">
									{{ $t('mails.mail_proccessing') }}
								</h6>
								<hr />
								<div>
									<InputFC
										:type="'textarea'"
										class="mb-2"
										v-model="message"
										:error="errors.message"
										:placeholder="'Message'"
									/>

									<div class="col-lg-12 mt-lg-0 mt-4 mb-2">
										<label for="file_">{{ $t('mails.attach') }}</label>
										<input
											type="file"
											required
											id="file_"
											class="form-control"
										/>
									</div>
								</div>
								<p class="text-5 text-center" v-show="deleted">
									{{ $t('mails.mail_proccessing') }}
									<i class="uil uil-check-circle fs-4 text-primary-"></i>
								</p>
								<hr />
								<div class="row mb-2">
									<div class="col text-center">
										<button
											type="button"
											class="border bg-light mt-4 px-3 text-5 rounded py-1"
											data-mdb-dismiss="modal"
										>
											{{ $t('global.close') }}
										</button>
									</div>
									<div class="col text-center">
										<SpinnerMe :elementId="'processSpinner'" />
										<button
											type="button"
											id="processBtn"
											data-mdb-dismiss="modal"
											class="bg-success text-white- mt-4 px-3 rounded py-1 text-5 border-default- set-opacity"
											@click="processMail()"
										>
											{{ $t('global.validate') }}
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="row">
					<div class="col-xl-9">
						<div
							class="bg-second- fw-1000 shadow-sm text-uppercase text-center rounded-pill text-5 text-white px-4 py-1 mt-4"
						>
							{{ $t('mails.view_mail') }}
						</div>

						<div class="bg-light-hight- rounded py-5 mt-3" v-if="!loading">
							<form
								@submit.prevent="updateMail"
								class="col-lg-10 col-md-11 mx-auto"
							>
								<div class="hstack">
									<button
										v-if="!display_processings"
										class="btn btn-primary-"
										@click.prevent="toggleEdit"
									>
										<span v-if="reading_mode" class="uil uil-pen"></span>
										<span v-else class="uil uil-times"></span>
										{{
											reading_mode
												? $t('global.edit_mode')
												: $t('global.leave_edit_mode')
										}}
									</button>

									<div class="ms-auto" v-if="mail.processings?.length > 0">
										<button
											class="btn btn-primary"
											@click.prevent="displayProcessings"
										>
											{{
												display_processings
													? $t('mails.hide_list')
													: $t('mails.show_list')
											}}
										</button>
									</div>
								</div>

								<hr />

								<div v-if="!display_processings">
									<InputFC
										:type="'text'"
										class="mb-4"
										v-model="reference"
										:error="errors.reference"
										:placeholder="$t('global.reference')"
										:disabled="true"
									/>

									<InputFC
										:type="'text'"
										class="mb-4"
										v-model="subject"
										:error="errors.subject"
										:placeholder="$t('global.object')"
										:disabled="reading_mode"
									/>

									<div class="row border border-default- pt-2 mb-4">
										<div class="text-center mb-2">{{ $t('mails.issuer') }}</div>
										<hr />

										<InputFC
											:type="'text'"
											class="col-6 mb-4"
											v-model="entity"
											:error="errors.entity"
											:placeholder="$t('mails.institution')"
											:disabled="reading_mode"
										/>

										<InputFC
											:type="'text'"
											class="col-6 mb-4"
											v-model="name"
											:error="errors.name"
											:placeholder="$t('mails.fullname')"
											:disabled="reading_mode"
										/>

										<InputFC
											:type="'email'"
											class="col-6 mb-4"
											v-model="email"
											:error="errors.email"
											:placeholder="$t('global.email')"
											:disabled="reading_mode"
										/>

										<InputFC
											:type="'tel'"
											class="col-6 mb-4"
											v-model="phone"
											:error="errors.phone"
											:placeholder="$t('global.phone')"
											:disabled="reading_mode"
										/>
									</div>

									<InputFC
										:type="'textarea'"
										class="mb-4"
										v-model="description"
										:error="errors.description"
										:placeholder="$t('mails.description')"
										:disabled="reading_mode"
									/>

									<InputFC
										:type="'select'"
										class="mb-4"
										:options="priority_list"
										v-model="priority"
										:error="errors.priority"
										:placeholder="$t('mails.priority')"
										:disabled="reading_mode"
									/>

									<InputFC
										:type="'select'"
										class="mb-4"
										:options="mail_processing_status"
										v-model="status"
										:error="errors.status"
										:placeholder="$t('global.status')"
										:disabled="reading_mode"
									/>

									<div
										v-if="status === 'saved'"
										class="row border border-default- pt-2 mb-4"
									>
										<div class="hstack text-center mb-2">
											{{ $t('mails.processing_steps') }}
											<div role="button" class="ms-auto" title="Ajouter">
												<button
													class="btn button-default"
													:disabled="reading_mode"
													@click.prevent="addStep"
												>
													<span class="uil uil-plus text-6"></span>
												</button>
											</div>
										</div>
										<span
											v-show="errors.steps != ''"
											v-text="errors.steps"
											class="error"
										></span>
										<hr />

										<div v-for="(step, i) of processing_steps" :key="i">
											<div class="hstack text-red- text-italic- text-4">
												{{ $t('mails.step') }} n° {{ i + 1 }}

												<div
													role="button"
													class="ms-auto"
													@click="removeStep(i)"
												>
													<span class="uil uil-trash text-red-"></span>
												</div>
											</div>
											<div class="mt-1">
												<label for="">{{ $t('mails.assigned_to') }}</label>
												<v-select
													class="bg-white mt-1"
													v-model="step.admin"
													:options="coadmins"
													:reduce="(coadmin) => coadmin.id"
													label="name"
													required
													:disabled="reading_mode"
												></v-select>
											</div>

											<InputFC
												:type="'textarea'"
												class="mb-4 mt-1"
												v-model="step.instruction"
												:placeholder="$t('mails.instructions')"
												required
												:disabled="reading_mode"
											/>

											<div v-if="i !== processing_steps.length - 1"><hr /></div>
										</div>
									</div>

									<div class="hstack">
										<div class="text-center">
											<SpinnerMe :elementId="'editSpinner'" />
											<input
												:disabled="reading_mode"
												type="submit"
												id="editBtn"
												class="button px-4 py-1 mt-4"
												:value="Modifier"
											/>
										</div>
										<div class="ms-3">
											<button
												class="button-default bg-light- text-color- fw-500 shadow-sm px-4 py-1 mt-4"
												@click.prevent="$router.go(-1)"
											>
												{{ $t('global.cancel') }}
											</button>
										</div>
										<div class="ms-3" v-if="mail.status !== 'processed'">
											<button
												data-mdb-toggle="modal"
												data-mdb-target="#deleteModal"
												class="button-default bg-success fw-500 shadow-sm px-4 py-1 mt-4"
												@click.prevent="null"
											>
												{{ $t('mails.to_treat') }}
											</button>
										</div>
									</div>
								</div>

								<div v-else>
									<div
										v-for="(processing, index) in mail.processings"
										:key="index"
									>
										<div class="hstack mb-2">
											<img
												:src="processing.createdBy.photo.url"
												class="rounded-circle user_image mt-1 mb-1"
												alt=""
											/>
											<div class="row mb-1">
												<div class="col-12 ms-2 text-black- fw-900">
													{{ processing.createdBy.lastname }}
													{{ processing.createdBy.firstname }}
												</div>
												<div class="col-12 ms-2 text-6">
													{{ processing.createdBy.emailAddress }}
												</div>
											</div>
											<div class="ms-auto" role="button" v-if="processing.file">
												<router-link
													:to="{
														name: 'documents-show',
														params: {
															id: processing.file.reference,
															slug: processing.file.slug,
														},
													}"
												>
													<span class="uil uil-file text-1 text-red-"></span>
												</router-link>
											</div>
										</div>
										<div class="text-5 text-justify">
											{{ processing.message }}
										</div>
										<div class="hstack">
											<div class="text-7 mt-2 text-alt-">
												{{ $filters.convertDate(processing.createdAt) }}
											</div>
											<div class="ms-auto">
												<span class="uil uil-check-circle text-success"></span>
											</div>
										</div>

										<hr />
									</div>
								</div>
							</form>
						</div>

						<LoadingMain :state="loading" />

						<div class="col-xl-3"></div>
					</div>
				</div>
			</section>
		</Template>
	</div>
</template>
<script>
import Template from '@/components/Template.vue';
import Back from '@/components/Back.vue';
import SpinnerMe from '@/components/SpinnerMe.vue';
import Helper from '@/helpers/helper';
import InputFC from '@/components/InputFC.vue';
import LoadingMain from '@/components/LoadingMain.vue';
import { useUserStore } from '@/store/user';

export default {
	name: 'CreateDocument',
	components: { Template, Back, InputFC, SpinnerMe, LoadingMain },
	setup() {
		const store = useUserStore();
		return {
			store,
		};
	},
	data() {
		return {
			loading: true,
			id: '',
			user: this.store.user,
			reading_mode: true,
			message: '',
			reference: '',
			entity: '',
			email: '',
			name: '',
			phone: '',
			subject: '',
			description: '',
			priority: '',
			status: '',
			priority_list: [],
			mail_processing_status: [],
			coadmins: [],
			errors: [],
			step: {
				admin: 1,
				instruction: '',
			},
			processing_steps: [],
			mail: {},
			mail_processed: '',
			display_processings: false,
		};
	},
	methods: {
		downloadFile(l, n) {
			Helper.downloadFile(l, n, this);
		},
		toggleEdit() {
			this.reading_mode = !this.reading_mode;
		},
		displayProcessings() {
			this.display_processings = !this.display_processings;
		},
		processMail() {
			Helper.loading('processBtn', 'processSpinner', true);

			this.errors.message = '';

			let registerFormData = new FormData();

			registerFormData.append('mail', this.mail.id);
			registerFormData.append('message', this.message);
			registerFormData.append(
				'file',
				document.getElementById('file_').files[0]
			);

			console.log('registerFormData', registerFormData);

			fetch(
				Helper.route('admin/mail/process'),
				Helper.requestOptions('POST', registerFormData)
			)
				.then((response) => {
					Helper.tokenChecker(response.status);
					return response.json();
				})
				.then((data) => {
					Helper.loading('processBtn', 'processSpinner', false);
					console.log(data);

					if (data.problems) {
						this.errors.message = Helper.getError(data.problems, 'message');
						this.errors.file = Helper.getError(data.problems, 'file_');
						if (data.problems[0]) {
							Helper.notification(
								'error',
								data.problems[0]['admin'] ??
									data.problems[0]['mail'] ??
									data.message
							);
						} else {
							Helper.notification('error', data.message);
						}
					} else {
						this.mail_processed = data.message;
						this.getMail();
						Helper.notification('success', data.message);
					}
				})
				.catch((error) => {
					console.log(error);
					Helper.loading('processBtn', 'processSpinner', false);
				});
		},
		updateMail() {
			this.ok = true;
			this.error_message = '';
			Helper.loading('editBtn', 'editSpinner', true);

			let body = {
				id: this.id,
				reference: this.reference,
				subject: this.subject,
				description: this.description,
				sender: {
					entity: this.entity,
					name: this.name,
					email: this.email,
					phone: this.phone,
				},
				priority: this.priority,
				status: this.status,
				steps: this.status === 'saved' ? this.processing_steps : [],
			};

			fetch(
				Helper.route('admin/mail/update'),
				Helper.requestOptionsJSON('PUT', body)
			)
				.then((response) => {
					Helper.tokenChecker(response.status);
					return response.json();
				})
				.then((data) => {
					console.log(data);
					Helper.loading('edit-btn', 'edit-spinner', true);

					if (data.problems) {
						this.errors.reference = Helper.getError(data.problems, 'reference');
						this.errors.subject = Helper.getError(data.problems, 'subject');
						this.errors.description = Helper.getError(
							data.problems,
							'description'
						);
						this.errors.sender = Helper.getError(data.problems, 'sender');
						this.errors.priority = Helper.getError(data.problems, 'priority');
						this.errors.status = Helper.getError(data.problems, 'status');
						this.errors.steps = Helper.getError(data.problems, 'steps');

						Helper.notification('error', data.message);
					} else {
						if (!this.ok) {
							this.error_message = data.message;
							Helper.notification('error', data.message);
						} else {
							if (data.status) {
								Helper.notification('success', data.message);
								this.$router.go(-1);
							} else {
								Helper.notification('error', data.message);
							}
						}
					}
				})
				.catch((error) => {
					console.log(error);
					Helper.loading('edit-btn', 'edit-spinner', true);
				});
		},
		addStep() {
			this.processing_steps.push({
				admin: null,
				instruction: '',
			});
		},
		removeStep(i) {
			if (!this.reading_mode) {
				this.processing_steps.splice(i, 1);
			}
		},
		getCoadmins() {
			this.coadmins.push({
				id: this.store.user.id,
				name: this.$t('global.myself'),
			});

			fetch(
				Helper.route('admin/management/coadmin/all'),
				Helper.requestOptions()
			)
				.then((response) => response.json())
				.then((data) => {
					console.log(data);
					if (data.data) {
						data.data.forEach((coadmin) => {
							this.coadmins.push({
								id: coadmin.id,
								name: coadmin.lastname + ' ' + coadmin.firstname,
							});
						});
					}
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getMail() {
			this.loading = true;
			fetch(Helper.route('admin/mail/' + this.id), Helper.requestOptions())
				.then((response) => response.json())
				.then((data) => {
					this.loading = false;
					if (data.data) {
						this.mail = data.data;

						for (const step of this.mail.steps) {
							this.processing_steps.push({
								admin: step.admin.id,
								instruction: step.instruction,
							});
						}

						this.reference = this.mail.reference;
						this.subject = this.mail.subject;
						this.description = this.mail.description;
						this.entity = this.mail.sender.entity;
						this.name = this.mail.sender.name;
						this.email = this.mail.sender.email;
						this.phone = this.mail.sender.phone;
						this.priority = this.mail.priority;
						this.status = this.mail.status;
					}
				})
				.catch((error) => {
					console.log(error);
				});
		},
	},
	created() {
		this.id = this.$route.params.id;
		this.getMail();
		this.getCoadmins();
		this.payment_methods = Helper.payment_methods;
		this.priority_list = Helper.priority_list;
		this.mail_processing_status = Helper.mail_processing_status;
	},
};
</script>
<style>
.user_image {
	height: 45px !important;
	width: 45px;
	object-fit: cover;
}
.text-sm {
	font-size: 5px !important;
}
</style>
