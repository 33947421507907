<template>
	<div>
		<Template :title="'null'">
			<div class="bg-light-hight- hstack py-3 ps-2 pb-3">
				<router-link to="/admin" class="bg-light-hight- ms-4 text-6 top-item">{{
					$t('topbar.co_admin')
				}}</router-link>
				<router-link
					to="/admin/invitation"
					class="bg-light-hight- ms-4 text-6 top-item"
					>{{ $t('admin.invitations') }}</router-link
				>
			</div>

			<div class="row m-0 px-4">
				<div class="col-lg-9">
					<div class="hstack mt-4">
						<router-link
							to="/admin/create"
							class="bg-second- rounded ms-auto px-3 p-1"
						>
							<i class="uil uil-plus text-white"></i>
							<span class="ms-2 text-6 text-white">{{
								$t('admin.invite_to_admin')
							}}</span>
						</router-link>
					</div>
					<div class="table table-responsive mt-3">
						<table
							class="table align-middle mb-0"
							style="width: 100% !important"
						>
							<thead class="bg-primary-">
								<tr class="text-5 text-white">
									<th class="fw-800 text-white-">
										{{ $t('global.lastname') }}
									</th>
									<th class="fw-800 text-white-">
										{{ $t('global.firstname') }}
									</th>
									<th class="fw-800 text-white-">{{ $t('global.email') }}</th>
									<th class="fw-800 text-white-">
										{{ $t('global.date_add') }}
									</th>
									<th class="fw-800 text-white- text-center">{{$t('global.action')}}</th>
								</tr>
							</thead>
							<tbody v-if="!loading && admins.length != 0">
								<tr
									class="text-5 text-white"
									v-for="admin in admins"
									:key="admin.id"
								>
									<td>{{ admin.lastname }}</td>
									<td>{{ admin.firstname }}</td>
									<td>{{ admin.emailAddress }}</td>
									<td>{{ $filters.convertDate(admin.createdAt) }}</td>
									<td class="text-center">
										<button @click="goto_grant(admin)" class="button me-2">
											{{ $t('Gest. Droits') }}
										</button>
										<button
											@click="toggle_status(admin)"
											:class="
												admin.accountStatus == 'activated'
													? 'bg-primary-'
													: 'bg-red-'
											"
											class="button"
										>
											{{
												admin.accountStatus == 'activated'
													? $t('admin.suspend')
													: $t('admin.reactivate')
											}}
										</button>
									</td>
								</tr>
							</tbody>
							<tbody v-if="!loading && admins.length == 0">
								<tr>
									<td colspan="5" class="text-center">
										{{ $t('none_element') }}
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="col-xl-3 mt-4">
					<div class="rounded bg-second- text-white text-4 text-center p-1">
						{{ $t('global.advice') }}
					</div>
					<div
						class="mt-1 bg-light-hight- text-alt- rounded text-7 text-center p-2"
						v-html="$t('global.advice_action')"
					></div>
				</div>
			</div>
		</Template>
	</div>
</template>
<script>
import Template from '@/components/Template.vue';
import Helper from '@/helpers/helper';

export default {
	name: 'IndexPage',
	components: { Template },
	data() {
		return {
			loading: true,
			admins: [],
		};
	},
	methods: {
		loadList() {
			this.$isLoading(true);
			this.loading = true;
			fetch(
				Helper.route('admin/management/coadmin/all'),
				Helper.requestOptions()
			)
				.then((response) => response.json())
				.then((data) => {
					this.loading = false;
					this.$isLoading(false);
					if (data.data) {
						this.admins = data.data;
					}
					console.log(data);
				})
				.catch((error) => {
					this.loading = false;
					this.$isLoading(false);
					console.log(error);
				});
		},
		goto_grant(admin) {
			this.$router.push({
				name: 'grant-right',
				params: { id: admin.id, slug: admin.slug },
			});
		},
		toggle_status(admin) {
			this.$isLoading(true);
			let formData = new FormData();
			formData.append('id', admin.id);

			fetch(
				Helper.route('admin/management/coadmin/toggle-suspend'),
				Helper.requestOptions('PUT', formData)
			)
				.then((response) => {
					Helper.tokenChecker(response.status);
					if (response.status != 200) {
						this.ok = false;
					}
					return response.json();
				})
				.then((data) => {
					console.log(data);
					this.$isLoading(false);
					console.log(admin);
					admin.accountStatus =
						admin.accountStatus == 'suspended' ? 'activated' : 'suspended';
					console.log(admin);
					this.admins = this.admins.map((i) => (i.id !== admin.id ? i : admin));
					Helper.notification('success', data.message);
				})
				.catch((error) => {
					this.$isLoading(false);
					console.log(error);
				});
		},
	},
	created() {
		this.loadList();
	},
};
</script>
<style></style>
