<template>
    <div>
        <div class="py-3 px-5 fs-4 fw-800 bg-white- text-primary-">
            PRO GESTION SOFT
        </div>
        <hr class="m-0">
        <div class="bg-light- py-5" style="height: calc(100vh - 12vh)">
            <div class="container h-100">
                <div class="row h-100">
                    <div class="my-auto">
                        <div class="row">
                            <div class="col-md-6 text-center">
                                <img src="@/assets/images/SANS_FOND_SIGNATURE.png" alt="">
                                <p class="text-center fw-600 text-4 text-three- mt-2">{{ $t('auth.fp_first_title') }}</p>
                                <h2 class="text-primary- text-center fs-6 lh-lg mt-4">{{ $t('auth.fp_second_title') }}</h2>
                            </div>
                            <div class="col-md-6">
                                <h5 class="text-center text-primary- text-4 text-uppercase mb-4">{{$t('auth.request_new_pwd')}}</h5>
                                <form @submit.prevent="sendRecoveryMail" v-if="step == 1" class="col-lg-8 rounded bg-primary-light- mx-auto border-black- shadow">
                                    <div class="py-4 px-4">
                                        <p class="text-black- text-center text-5">{{$t('auth.inter_ifu')}}</p>
                                        <div class="error mb-4">{{ errors.error_step_1 }}</div>
                                        <InputMe v-model.trim="login" :type="'email'" :error="errors.login" :placeholder="$t('global.email')" />
                                    </div>
                                    <div class="p-3 text-center bordert-black-">
                                        <div class="row">
                                            <div class="col mt-2">
                                                <router-link to="login" class="button-second- w-100"><span class="text-white"><i class="uil uil-arrow-left fs-6 me-2 text-white"></i> Retour</span></router-link>
                                            </div>
                                            <div class="col mt-2">
                                                <div class="text-center d-none" id="spinner1">
                                                    <div class="spinner-border text-primary-" role="status">
                                                        <span class="visually-hidden">Loading...</span>
                                                    </div> 
                                                </div> 
                                                <input type="submit" id="submit1" class="button w-100" :value="$t('global.ask')">
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <form @submit.prevent="verifyCode"  v-if="step == 2" class="col-lg-8 rounded bg-primary-light- mx-auto border-black- shadow">
                                    <div class="py-4 px-4">
                                        <p class="text-black- text-center text-5">{{ message }}</p>
                                        <div class="error mb-4">{{ errors.error_step_2 }}</div>
                                        <InputMe v-model.trim="code" :error="errors.code" :placeholder="$t('auth.verification_code')" />
                                    </div>
                                    <div class="p-3 text-center bordert-black-">
                                        <div class="row">
                                            <div class="col mt-2">
                                                <router-link to="login" class="button-second- w-100"><span class="text-white"><i class="uil uil-arrow-left fs-6 me-2 text-white"></i> {{$t('global.back')}}</span></router-link>
                                            </div>
                                            <div class="col mt-2">
                                                <div class="text-center d-none" id="spinner2">
                                                    <div class="spinner-border text-primary-" role="status">
                                                        <span class="visually-hidden">Loading...</span>
                                                    </div> 
                                                </div> 
                                                <input type="submit" id="submit2" class="button w-100" :value="$t('auth.verify_code')">
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <form @submit.prevent="updatePasswordAndLogin" v-show="step == 3" class="col-lg-8 rounded bg-primary-light- mx-auto border-black- shadow">
                                    <div class="py-4 px-4">
                                        <p class="text-black- text-center text-5">{{$t('auth.create_new_pwd')}} </p>
                                        <div class="error mb-4">{{ errors.error_step_3 }}</div>
                                        <InputMe :type="'password'" v-model.trim="newPassword" :error="errors.newPassword" :placeholder="$t('auth.new_pwd')" />
                                        <InputMe :type="'password'" v-model.trim="confirmPassword" class="mt-4" :error="errors.confirmPassword" :placeholder="$t('auth.conf_new_pwd')" />
                                    </div>
                                    <div class="p-3 text-center bordert-black-">
                                        <div class="row">
                                            <div class="col mt-2">
                                                <router-link to="login" class="button-second- w-100"><span class="text-white"><i class="uil uil-arrow-left fs-6 me-2 text-white"></i> {{$t('global.back')}}</span></router-link>
                                            </div>
                                            <div class="col mt-2">
                                                <div class="text-center d-none" id="spinner3">
                                                    <div class="spinner-border text-primary-" role="status">
                                                        <span class="visually-hidden">Loading...</span>
                                                    </div> 
                                                </div> 
                                                <input type="submit" id="submit3" class="button w-100" :value="$t('auth.to_login')">
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Footer />
    </div>
</template>

<style>
img {
    height: 170px;
}
</style>

<script>
import Footer from '@/components/Footer.vue'
import InputMe from '@/components/InputMe.vue';
import Helper from '@/helpers/helper';
export default {
    name: "ForgotPasswordPage",
    data() {
        return {
            errors: [],
            message: "",
            step: 1,
            login: "",
            newPassword: "",
            confirmPassword: "",
            code: ""
        };
    },
    components: { Footer, InputMe },
    methods: {
        sendRecoveryMail() {         
            if (this.beforeSendRecoveryMail()) {
                this.ok = true
                delete this.errors.error_step_1
                Helper.loading("submit1", "spinner1", true)
                let registerFormData = new FormData()
                registerFormData.append('login', this.login)

                fetch(Helper.route('auth/send-password-recovery-email'), Helper.requestOptions("POST", registerFormData))
                .then(response => {
                    if (response.status === 401) {
                        this.ok = false
                    } 
                    return response.json()
                })
                .then(data => {
                    Helper.loading("submit1", "spinner1", false) 

                    if (data.problems) {
                        this.errors.login = Helper.getError(data.problems, "login")
                    } else {
                        if(!this.ok) {
                            this.errors.error_step_1 = data.message       
                        } else {
                            this.step = 2
                            this.message = data.message
                        }
                    }
                    
                })
                .catch(error => {
                    console.log(error)
                    Helper.loading("submit1", "spinner1", false)
                });
            }
        },
        verifyCode() {       
            if (this.beforeVerifyCode()) {
                this.ok = true
                delete this.errors.error_step_2
                Helper.loading("submit2", "spinner2", true)
                let registerFormData = new FormData()
                registerFormData.append('code', this.code)

                fetch(Helper.route('auth/verify-recovery-code'), Helper.requestOptions("POST", registerFormData))
                .then(response => {
                    if (response.status === 401) {
                        this.ok = false
                    } 
                    return response.json()
                })
                .then(data => {
                    Helper.loading("submit2", "spinner2", false) 

                    console.log(data);

                    if (data.problems) {
                        this.errors.code = Helper.getError(data.problems, "code")
                    } else {
                        if(!this.ok) {
                            this.errors.error_step_2 = data.message       
                        } else {
                            this.step = 3
                        }
                    }
                    
                })
                .catch(error => {
                    console.log(error)
                    Helper.loading("submit2", "spinner2", false)
                });
            }
        },
        updatePasswordAndLogin() {
            if (this.beforeUpdatePassword()) {
                this.ok = true
                delete this.errors.error_step_3
                Helper.loading("submit3", "spinner3", true)
                let registerFormData = new FormData()
                registerFormData.append('code', this.code)
                registerFormData.append('password', this.newPassword)

                fetch(Helper.route('auth/update-password-and-login'), Helper.requestOptions("POST", registerFormData))
                .then(response => {
                    if (response.status === 401) {
                        this.ok = false
                    } 
                    return response.json()
                })
                .then(data => {
                    Helper.loading("submit3", "spinner3", false) 

                    if (data.problems) {
                        this.errors.password = Helper.getError(data.problems, "password")
                    } else {
                        if(!this.ok) {
                            this.erros.error_step_1 = data.message       
                        } else {
                            Helper.setLocalstorage(Helper.CLIENT_TOKEN, data.token)
                            Helper.redirect(Helper.route('home', null, false))
                        }
                    }
                    
                })
                .catch(error => {
                    console.log(error)
                    Helper.loading("submit3", "spinner3", false)
                });
            }
        },
        beforeSendRecoveryMail() {
            if (Helper.required(this.login) === "") {
                delete this.errors.login
            } else {
                this.errors.login = Helper.required(this.login)
            }
            return Helper.objectNumberKey(this.errors) == 0 ? true : false
        },
        beforeVerifyCode() {
            if (Helper.required(this.code) === "") {
                delete this.errors.code
            } else {
                this.errors.code = Helper.required(this.code)
            }
            return Helper.objectNumberKey(this.errors) == 0 ? true : false
        },
        beforeUpdatePassword() {
            if (Helper.required(this.newPassword) === "") {
                delete this.errors.newPassword
            } else {
                this.errors.newPassword = Helper.required(this.newPassword)
            }

            if (Helper.checkPassword(this.password) === '') {
				delete this.errors.password;
			} else {
				this.errors.password = Helper.checkPassword(this.password);
			}

            
            if (Helper.required(this.confirmPassword) === "") {
                if (this.newPassword == this.confirmPassword) {
                    delete this.errors.confirmPassword
                } else {
                    this.errors.confirmPassword = "Les deux mots de passe ne sont pas identiques"
                }
            } else {
                this.errors.confirmPassword = Helper.required(this.confirmPassword)
            }
            return Helper.objectNumberKey(this.errors) == 0 ? true : false
        },
    },
    watch: {
        login: function() {
            if (Helper.required(this.login) === "") {
                delete this.errors.login
            } else {
                this.errors.login = Helper.required(this.login)
            }
        },
        code: function() {
            if (Helper.required(this.code) === "") {
                delete this.errors.code
            } else {
                this.errors.code = Helper.required(this.code)
            }
        },
        newPassword: function() {
            if (Helper.required(this.newPassword) === "") {
                delete this.errors.newPassword
                if (this.newPassword == this.confirmPassword) {
                    delete this.errors.confirmPassword
                }
            } else {
                this.errors.newPassword = Helper.required(this.newPassword)
            }
        },
        confirmPassword: function() {
            if (Helper.required(this.confirmPassword) === "") {
                if (this.newPassword == this.confirmPassword) {
                    delete this.errors.confirmPassword
                } else {
                    this.errors.confirmPassword = "Les deux mots de passe ne sont pas identiques"
                }
            } else {
                this.errors.confirmPassword = Helper.required(this.confirmPassword)
            }
        },
    },
    beforeRouteEnter() {
        
    }
}
</script>