<template>
    <FormationPopup v-show="showPopup" :formations="allTraining" :popupDate="trainingData" @close-modal="showPopup = false"></FormationPopup>

    <div v-if="allTraining.length !== 0" class="hstack cursor-pointer mt-1" type="button" @click="showPopup = true">
      <div class="bg-red- me-2" style="width: 10px; height: 30px !important"></div>
      <div>
        <div class="hstack w-100 fw-500 text-6">
          <div class="me-1">Formations à faire: {{ allTraining.length }}</div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import Helper from '@/helpers/helper';
  import FormationPopup from '@/components/FormationPopup.vue'

  export default {
    name: "FormationTag",
    components: {FormationPopup},
    props: {
      trainingData: {
        type: Date,
        required: true
      }
    },

    data() {
		return {
			allTraining: [],

            showPopup: false,
		};
	},

    methods:{
        showOneFormation(showPopup){
            this.showPopup = !showPopup;
        },

        formatDate(dateString) {
			const date = new Date(dateString);
			return date.toISOString().split('T')[0];
		},

      loadDayTraining(date) {
			const formattedDate = this.formatDate(date);
			this.loading = true;
			fetch(Helper.route('admin/training/all?sortBy=id&order=DESC&date='+formattedDate), Helper.requestOptions())
				.then((response) => response.json())
				.then((data) => {
					this.loading = false;
					
					if (data.data) {
						this.allTraining = data.data;
						console.log('day Training List:'+formattedDate, data.data);
					}
				})
				.catch((error) => {
					console.log(error);
				});
		},
    },

    created() {
        this.loadDayTraining(this.trainingData);
    }
  }
  </script>
  