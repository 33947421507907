<template>
	<div>
		<Template title="null" sidebarMenu="documents">
			<section class="row px-4 m-0 g-4 mt-1">
				<Back />

				<div class="col-xl-9">
					<div
						class="bg-second- shadow-sm text-uppercase text-center rounded-pill text-5 text-white px-4 p-1"
					>
						{{ $t('global.overview') }}
					</div>

					<LoadingMain :state="loading" />

					<div
						v-if="document == null && !loading"
						class="p-5 text-red- text-5 text-center"
					>
						{{ $t('documents.file_no_found') }}
					</div>
					<div class="row mt-1 mb-4 g-4" v-if="document !== null">
						<div class="col-xl-12">
							<div class="border">
								<div
									class="text-red- text-uppercase text-5 bg-light-hight- text-center p-1"
								>
									{{ document.name }}
								</div>
								<div class="text-center m-2">
									<div class="row py-2">
										<div
											class="col-12 text-center text-black- text-primary- fw-1000"
										>
											<audio
												v-if="document.type === 'audio'"
												:src="document.url"
												controls
											></audio>

											<img
												role="button"
												v-else-if="document.type === 'image'"
												@click="$filters.viewer([document.url], this)"
												class="img-display"
												:src="document.url"
											/>

											<video
												v-else-if="document.type === 'video'"
												:src="document.url"
												controls
											></video>

											<iframe
												v-else-if="document.type === 'word'"
												class="doc-display"
												:src="docViewerSrc(document.url)"
												frameborder="0"
											></iframe>

											<iframe
												v-else-if="document.type === 'excel'"
												class="doc-display"
												:src="docViewerSrc(document.url)"
												frameborder="0"
											></iframe>

											<iframe
												v-else-if="document.type === 'powerpoint'"
												class="doc-display"
												:src="docViewerSrc(document.url)"
												frameborder="0"
											></iframe>

											<iframe
												v-else
												frameborder="0"
												:name="document.name"
												:src="document.url"
												class="doc-display"
											></iframe>
										</div>
									</div>
								</div>
								<div class="mt-2">
									<span
										class="button p-1 text-6 px-3 me-2"
										role="button"
										@click="downloadFile(document.url, document.name)"
										>{{ $t('global.download') }}</span
									>

									<span
										class="button p-1 text-6 px-3 mx-2"
										role="button"
										@click="archives()"
										>{{
											document.status != 'archived'
												? $t('global.archive')
												: $t('global.unarchive')
										}}</span
									>

									<span
										class="button bg-red- p-1 text-6 px-3 mx-2"
										role="button"
										@click="deletes()"
										>{{ $t('global.delete') }}</span
									>

									<span
										class="button bg-red- p-1 text-6 px-3 mx-2"
										role="button"
										@click="favourite()"
										>{{
											document.isFavourite
												? $t('documents.remove_favorites')
												: $t('documents.add_favorites')
										}}</span
									>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-xl-3" v-if="document !== null">
					<div class="rounded bg-second- text-white text-4 text-center p-1">
						{{ $t('documents.file_property') }}
					</div>
					<div class="mt-1 bg-light-hight- text-alt- rounded text-5 p-2">
						<div class="row text-left">
							<div class="col-xl-3 text-left">{{ $t('global.title') }}</div>
							<div class="col-xl-1">
								<span class="text-center">:</span>
							</div>
							<div class="col-xl-8 pb-2 text-alt-" :title="document.name">
								{{ $filters.truncate(document.name, 20) }}
							</div>

							<div class="col-xl-3">{{ $t('global.type') }}</div>
							<div class="col-xl-1">
								<span class="text-center">:</span>
							</div>
							<div class="col-xl-8 pb-2 text-alt-">
								{{ document.type }} ({{
									$filters.truncate(document.file.uploadMime, 15)
								}})
							</div>

							<div class="col-xl-3">{{ $t('global.size') }}</div>
							<div class="col-xl-1">
								<span class="text-center">:</span>
							</div>
							<div class="col-xl-8 pb-2 text-alt-">
								{{ sizeConverter(document.file.size) }}
							</div>

							<div class="col-xl-3">{{ $t('global.folder') }}</div>
							<div class="col-xl-1">
								<span class="text-center">:</span>
							</div>
							<div class="col-xl-8 pb-2 text-alt-">
								{{ document.folder.name }}
							</div>

							<div class="col-xl-3">{{ $t('global.date') }}</div>
							<div class="col-xl-1">
								<span class="text-center">:</span>
							</div>
							<div class="col-xl-8 pb-2 text-alt-">
								{{ $filters.convertDate(document.createdAt) }}
							</div>

							<div class="col-xl-3">{{ $t('documents.favorites') }}</div>
							<div class="col-xl-1">
								<span class="text-center">:</span>
							</div>
							<div class="col-xl-8 pb-2 text-alt-">
								{{ document.isFavourite ? 'Oui' : 'Non' }}
							</div>
						</div>
					</div>
				</div>
			</section>
		</Template>
	</div>
</template>
<script>
import Template from '@/components/Template.vue';
import Back from '@/components/Back.vue';
import Helper from '@/helpers/helper';
import LoadingMain from '@/components/LoadingMain.vue';
export default {
	name: 'ShowSectionPage',
	components: { Template, Back, LoadingMain },
	data() {
		return {
			id: '',
			loading: false,
			ok: true,
			document: null,
			archived: false,
		};
	},
	methods: {
		docViewerSrc(src) {
			return 'https://view.officeapps.live.com/op/embed.aspx?src=' + src;
		},
		downloadFile(l, n) {
			Helper.downloadFile(l, n, this);
		},
		sizeConverter(bytes) {
			return Helper.sizeConverter(bytes);
		},
		getDocument() {
			this.loading = true;
			fetch(Helper.route('admin/document/' + this.id), Helper.requestOptions())
				.then((response) => {
					Helper.tokenChecker(response.status);
					if (response.status != 200) {
						this.ok = false;
					}
					return response.json();
				})
				.then((data) => {
					this.loading = false;
					if (data.data) {
						this.document = data.data;
						console.log(this.document);
					}
				})
				.catch((error) => {
					this.loading = false;
					console.log(error);
				});
		},
		favourite() {
			this.$isLoading(true);
			let registerFormData = new FormData();

			registerFormData.append('reference', this.document.reference);

			fetch(
				Helper.route('admin/document/favourite'),
				Helper.requestOptions('POST', registerFormData)
			)
				.then((response) => response.json())
				.then((data) => {
					this.$isLoading(false);
					console.log(data);
					this.document.isFavourite = !this.document.isFavourite;
					Helper.notification('success', data.message);
				})
				.catch((error) => {
					this.$isLoading(false);
					console.log(error);
				});
		},
		archives() {
			this.$isLoading(true);
			let body = {
				ids: [this.document.id],
			};
			fetch(
				Helper.route('admin/document/archive'),
				Helper.requestOptionsJSON('PUT', body)
			)
				.then((response) => response.json())
				.then((data) => {
					this.$isLoading(false);
					console.log(data);
					this.document = data.data[0];
					Helper.notification('success', data.message);
				})
				.catch((error) => {
					this.$isLoading(false);
					console.log(error);
				});
		},
		deletes() {
			let body = {
				ids: [this.document.id],
			};
			this.$swal({
				title: `${this.$t('global.attention')}!`,
				html: `
				<p>Si vous souhaitez confirmer la suppression de ce document, cliquez sur "Oui, Supprimer".</p>
				`,
				customClass: { htmlContainer: 'htmlContainer' },
				icon: 'error',
				showCancelButton: true,
				cancelButtonText: 'Annuler',
				confirmButtonText: 'Oui, Supprimer',
			}).then((result) => {
				if (result.isConfirmed) {
					this.$isLoading(true);
					fetch(
						Helper.route('admin/document/delete'),
						Helper.requestOptionsJSON('PUT', body)
					)
						.then((response) => response.json())
						.then((data) => {
							this.$isLoading(false);
							console.log(data);
							Helper.notification('success', data.message);
							this.$router.go(-1);
						})
						.catch((error) => {
							this.$isLoading(false);
							console.log(error);
						});
				}
			});
		},
	},

	created() {
		this.id = this.$route.params.id;
		this.getDocument();
	},
};
</script>
<style>
.img-display {
	width: auto;
	height: auto;
}
.doc-display {
	width: 100%;
	min-height: 60vh;
}
</style>
