<template>
	<div>
		<Template :title="$t('candidate.offers.new_offer')" :sidebarMenu="'offers'">
			<section class="p-5 py-4">
				<Back />

				<div
					class="bg-second- shadow-sm text-uppercase text-center rounded-pill text-5 text-white px-4 py-1 mt-4"
				>
					{{ $t('candidate.offers.post_new_offer') }}
				</div>

				<div class="bg-light-hight- rounded py-5 pt-3">
					<form @submit.prevent="offerNew" class="col-lg-11 col-md-11 mx-auto">
						<div class="row">
							<div class="col-lg-6 mt-4">
								<InputFC
									:error="errors.post"
									:type="'text'"
									v-model="post"
									:placeholder="$t('candidate.offers.profession')"
								/>
							</div>
							<div class="col-lg-6 mt-4">
								<InputFC
									:error="errors.location"
									:type="'text'"
									v-model="location"
									:placeholder="$t('candidate.offers.work_location')"
								/>
							</div>
							<div class="col-lg-6 mt-4">
								<InputFC
									:error="errors.contact"
									:type="'tel'"
									v-model="contact"
									:placeholder="$t('candidate.offers.contact')"
								/>
							</div>
							<div class="col-lg-6 mt-4">
								<InputFC
									:error="errors.email"
									:type="'email'"
									v-model="email"
									:placeholder="$t('global.email')"
								/>
							</div>
						</div>
						<div class="row">
							<div class="col-lg-6 mt-4">
								<InputFC
									:error="errors.profile"
									:type="'text'"
									v-model="profile"
									:placeholder="$t('global.profile_search')"
								/>
							</div>
							<div class="col-lg-6 mt-4">
								<InputFC
									:error="errors.contract"
									:options="contract_types"
									:type="'select'"
									v-model="contract"
									:placeholder="$t('candidate.offers.contract_type')"
								/>
							</div>
						</div>

						<div class="row">
							<div class="col-lg-6 mt-4">
								<InputFC
									:error="errors.placeNumber"
									:type="'number'"
									v-model="placeNumber"
									:placeholder="$t('candidate.offers.available_place')"
								/>
							</div>
							<div class="col-lg-6 mt-4">
								<InputFC
									:error="errors.closingDate"
									:type="'datetime-local'"
									v-model="closingDate"
									:placeholder="$t('candidate.offers.closing_date')"
								/>
							</div>
						</div>

						<div class="row">
							<div class="col-lg-12 mt-4">
								<label for="">{{$t('candidate.offers.task_to_complete')}}</label>
								<QuillEditor
									v-model:content="tasks"
									toolbar="essential"
									contentType="html"
									class="bg-white-"
									theme="snow"
								/>
								<span
									v-show="errors.tasks != ''"
									v-text="errors.tasks"
									class="error"
								></span>
							</div>
							<div class="col-lg-12 mt-5">
								<label for="" class="mt-5">{{$t('candidate.offers.required_skills')}}</label>
								<QuillEditor
									v-model:content="skills"
									toolbar="essential"
									contentType="html"
									class="bg-white-"
									theme="snow"
								/>
								<span
									v-show="errors.skills != ''"
									v-text="errors.skills"
									class="error"
								></span>
							</div>
						</div>

						<div class="row" style="margin-top: 120px">
							<div class="col-lg-12 mt-3">
								<label for="">{{$t('candidate.offers.job_summary')}}</label>
								<QuillEditor
									v-model:content="summary"
									toolbar="essential"
									contentType="html"
									class="bg-white-"
									theme="snow"
								/>
								<span
									v-show="errors.summary != ''"
									v-text="errors.summary"
									class="error"
								></span>
							</div>
							<div class="col-lg-12 mt-5">
								<label for="" class="mt-5">{{$t('candidate.offers.required_docs')}}</label>
								<QuillEditor
									v-model:content="requiredDocument"
									toolbar="essential"
									contentType="html"
									class="bg-white-"
									theme="snow"
								/>
								<span
									v-show="errors.requiredDocument != ''"
									v-text="errors.requiredDocument"
									class="error"
								></span>
							</div>
							<div class="col-lg-12" style="margin-top: 150px">
								<label for="">{{$t('candidate.offers.abilities')}}</label>
								<QuillEditor
									v-model:content="aptitudes"
									toolbar="essential"
									contentType="html"
									class="bg-white-"
									theme="snow"
								/>
								<span
									v-show="errors.aptitudes != ''"
									v-text="errors.aptitudes"
									class="error"
								></span>
							</div>
						</div>

						<div class="hstack" style="margin-top: 70px">
							<div class="">
								<SpinnerMe :elementId="'spinner'" />
								<button id="submit" class="button px-4 py-1 mt-4">
									{{ $t('global.send') }}
								</button>
							</div>
							<div class="ms-3">
								<button
									class="button-default bg-light- text-color- fw-500 shadow-sm px-4 py-1 mt-4"
									@click="$router.go(-1)"
								>
									{{ $t('global.cancel') }}
								</button>
							</div>
						</div>
					</form>
				</div>
			</section>
		</Template>
	</div>
</template>
<script>
import Template from '@/components/Template.vue';
import Back from '@/components/Back.vue';
import Helper from '@/helpers/helper';
import InputFC from '@/components/InputFC.vue';
import SpinnerMe from '@/components/SpinnerMe.vue';
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import { useUserStore } from '@/store/user';

export default {
	name: 'OfferNew',
	components: { Template, Back, InputFC, QuillEditor, SpinnerMe },
	setup() {
		const store = useUserStore();
		return { 
			store,
		};
	},
	data() {
		return {
			errors: [],
			post: '',
			profile: '',
			location: '',
			contract: '',
			placeNumber: '',
			closingDate: '',
			tasks: '',
			summary: '',
			skills: '',
			aptitudes: '',
			requiredDocument: '',
			contact: this.store.user.company.phoneNumber ?? '',
			email: this.store.user.company.emailAddress,
			check_form: false,
		};
	},
	methods: {
		offerNew() {
			this.check_form = true;
			if (this.beforeCreateItem()) {
				this.ok = true;
				this.error_message = '';
				Helper.loading('submit', 'spinner', true);
				let registerFormData = new FormData();
				registerFormData.append('post', this.post);
				registerFormData.append('profile', this.profile);
				registerFormData.append('location', this.location);
				registerFormData.append('contract', this.contract);
				registerFormData.append('placeNumber', this.placeNumber);
				registerFormData.append(
					'closingDate',
					new Date(this.closingDate).toISOString()
				);
				registerFormData.append('tasks', this.tasks);
				registerFormData.append('summary', this.summary);
				registerFormData.append('skills', this.skills);
				registerFormData.append('aptitudes', this.aptitudes);
				registerFormData.append('requiredDocument', this.requiredDocument);
				registerFormData.append('contact', this.contact);
				registerFormData.append('email', this.email);

				fetch(
					Helper.route('admin/joboffer/add'),
					Helper.requestOptions('POST', registerFormData)
				)
					.then((response) => {
						Helper.tokenChecker(response.status);
						if (response.status != 200) {
							this.ok = false;
						}
						return response.json();
					})
					.then((data) => {
						this.check_form = false;
						Helper.loading('submit', 'spinner', false);

						console.log('🚀 ~ file: Create.vue:263 ~ .then ~ data:', data);

						if (data.problems) {
							this.errors.post = Helper.getError(data.problems, 'post');
							this.errors.closingDate = Helper.getError(data.problems, 'date');
							this.errors.contact = Helper.getError(data.problems, 'contact');
							this.errors.contract = Helper.getError(data.problems, 'contract');
							this.errors.placeNumber = Helper.getError(
								data.problems,
								'placeNumber'
							);
							this.errors.skills = Helper.getError(data.problems, 'skills');
							this.errors.aptitudes = Helper.getError(
								data.problems,
								'aptitudes'
							);
							this.errors.requiredDocument = Helper.getError(
								data.problems,
								'requiredDocument'
							);
							this.errors.summary = Helper.getError(data.problems, 'summary');
							this.errors.profile = Helper.getError(data.problems, 'profile');
							this.errors.email = Helper.getError(data.problems, 'email');

							Helper.notification('error', data.message);
						} else {
							if (!this.ok) {
								this.error_message = data.message;
								Helper.notification('error', this.error_message);
							} else {
								if (data.status) {
									Helper.notification('success', data.message);
									this.message = data.message;
									this.$router.go(-1);
								}
							}
						}
					})
					.catch((error) => {
						console.log(error);
						Helper.loading('submit', 'spinner', false);
					});
			}
		},
		beforeCreateItem() {
			if (Helper.required(this.post) !== '') {
				this.errors.post = Helper.required(this.post);
			}
			if (Helper.required(this.profile) !== '') {
				this.errors.profile = Helper.required(this.profile);
			}
			if (Helper.required(this.location) !== '') {
				this.errors.location = Helper.required(this.location);
			}
			if (Helper.required(this.contract) !== '') {
				this.errors.contract = Helper.required(this.contract);
			}
			if (Helper.required(this.contact) !== '') {
				this.errors.contact = Helper.required(this.contact);
			}
			if (Helper.required(this.email) !== '') {
				this.errors.email = Helper.required(this.email);
			}
			if (Helper.required(this.placeNumber) !== '') {
				this.errors.placeNumber = Helper.required(this.placeNumber);
			}
			if (Helper.required(this.closingDate) !== '') {
				this.errors.closingDate = Helper.required(this.closingDate);
			}
			if (Helper.required(this.tasks) !== '') {
				this.errors.tasks = Helper.required(this.tasks);
			}
			if (Helper.required(this.summary) !== '') {
				this.errors.summary = Helper.required(this.summary);
			}
			if (Helper.required(this.skills) !== '') {
				this.errors.skills = Helper.required(this.skills);
			}
			if (Helper.required(this.aptitudes) !== '') {
				this.errors.aptitudes = Helper.required(this.aptitudes);
			}
			if (Helper.required(this.requiredDocument) !== '') {
				this.errors.requiredDocument = Helper.required(this.requiredDocument);
			}
			return Helper.objectNumberKey(this.errors) == 0 ? true : false;
		},
	},
	created() {
		this.contract_types = Helper.contract_types;
	},
	watch: {
		post: function () {
			if (this.check_form) {
				if (Helper.required(this.post) === '') {
					delete this.errors.post;
				} else {
					this.errors.post = Helper.required(this.post);
				}
			}
		},
		profile: function () {
			if (this.check_form) {
				if (Helper.required(this.profile) === '') {
					delete this.errors.profile;
				} else {
					this.errors.profile = Helper.required(this.profile);
				}
			}
		},
		location: function () {
			if (this.check_form) {
				if (Helper.required(this.location) === '') {
					delete this.errors.location;
				} else {
					this.errors.location = Helper.required(this.location);
				}
			}
		},
		contract: function () {
			if (this.check_form) {
				if (Helper.required(this.contract) === '') {
					delete this.errors.contract;
				} else {
					this.errors.contract = Helper.required(this.contract);
				}
			}
		},
		contact: function () {
			if (this.check_form) {
				if (Helper.required(this.contact) === '') {
					delete this.errors.contact;
				} else {
					this.errors.contact = Helper.required(this.contact);
				}
			}
		},
		email: function () {
			if (this.check_form) {
				if (Helper.required(this.email) === '') {
					delete this.errors.email;
				} else {
					this.errors.email = Helper.required(this.email);
				}
			}
		},
		placeNumber: function () {
			if (this.check_form) {
				if (Helper.required(this.placeNumber) === '') {
					delete this.errors.placeNumber;
				} else {
					this.errors.placeNumber = Helper.required(this.placeNumber);
				}
			}
		},
		closingDate: function () {
			if (this.check_form) {
				if (Helper.required(this.closingDate) === '') {
					delete this.errors.closingDate;
				} else {
					this.errors.closingDate = Helper.required(this.closingDate);
				}
			}
		},
		tasks: function () {
			if (this.check_form) {
				if (Helper.required(this.tasks) === '') {
					delete this.errors.tasks;
				} else {
					this.errors.tasks = Helper.required(this.tasks);
				}
			}
		},
		summary: function () {
			if (this.check_form) {
				if (Helper.required(this.summary) === '') {
					delete this.errors.summary;
				} else {
					this.errors.summary = Helper.required(this.summary);
				}
			}
		},
		skills: function () {
			if (this.check_form) {
				if (Helper.required(this.skills) === '') {
					delete this.errors.skills;
				} else {
					this.errors.skills = Helper.required(this.skills);
				}
			}
		},
		aptitudes: function () {
			if (this.check_form) {
				if (Helper.required(this.aptitudes) === '') {
					delete this.errors.aptitudes;
				} else {
					this.errors.aptitudes = Helper.required(this.aptitudes);
				}
			}
		},
		requiredDocument: function () {
			if (this.check_form) {
				if (Helper.required(this.requiredDocument) === '') {
					delete this.errors.requiredDocument;
				} else {
					this.errors.requiredDocument = Helper.required(this.requiredDocument);
				}
			}
		},
	},
};
</script>
<style></style>
