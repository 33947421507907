<template>
	<div>
		<Template>
			<section class="row g-3 m-0 p-3 gx-5">
				<div class="col-xxl-4 col-xl-6 col-lg-6 col-md-8 col-sm-10 mx-auto">
					<div class="mt-2">
						<div class="row bg-dark text-white text-5 px-2 py-1">
							{{ $t('home.courriels') }}
						</div>
						<routerLink
							to="/mail/add"
							class="row bg-primary- h120px home-menu-item py-4 mt-1"
						>
							<div class="col move text-center">
								<i class="uil uil-envelope-edit text-white fs-1"></i>
							</div>
							<div class="col text-6 text-white">
								{{ $t('home.courriel_action') }}
							</div>
						</routerLink>
						<routerLink
							to="/mail/add"
							class="row bg-alt- text-white text-6 px-2 py-1"
						>
							{{ $t('home.courriel_new') }}
						</routerLink>
						<div class="row mt-1">
							<routerLink
								to="/mail"
								class="col home-menu-item bg-lighther- p-0"
							>
								<div class="text-center move pt-4 h120px">
									<i class="uil uil-envelope-check text-white fs-1"></i>
								</div>
								<div class="bg-alt- px-2 text-6 text-white py-1">
									{{ $t('home.courriel_send') }}
								</div>
							</routerLink>
							<routerLink
								to="/mail"
								class="col home-menu-item bg-lighther- p-0 ms-1"
							>
								<div class="text-center move pt-4 h120px">
									<i class="uil uil-envelope-download text-white fs-1"></i>
								</div>
								<div class="bg-alt- px-2 text-6 text-white py-1">
									{{ $t('home.courriel_receive') }}
								</div>
							</routerLink>
						</div>
					</div>
				</div>

				<div class="col-xxl-4 col-xl-6 col-lg-6 col-md-8 col-sm-10 mx-auto">
					<div class="mt-2">
						<div class="row bg-dark text-white text-5 px-2 py-1">
							{{ $t('home.human_ressource_management') }}
						</div>

						<div class="row mt-1">
							<routerLink
								to="/employees"
								class="col home-menu-item bg-primary- p-0"
							>
								<div class="text-center move h120px pt-4">
									<i class="uil uil-users-alt text-white fs-1"></i>
								</div>
								<div class="bg-alt- px-2 text-6 text-white py-1">
									{{ $t('home.employee_list') }}
								</div>
							</routerLink>
							<routerLink
								to="/formations"
								class="col home-menu-item bg-primary- p-0 ms-1"
							>
								<div class="text-center move h120px pt-4">
									<i class="uil uil-notebooks fs-1 text-white"></i>
								</div>
								<div class="bg-alt- px-2 text-6 text-white py-1">
									{{ $t('home.see_tranings') }}
								</div>
							</routerLink>
						</div>
						<div class="row mt-1">
							<routerLink
								to="/employees/create"
								class="col home-menu-item bg-lighther- p-0"
							>
								<div class="text-center move h120px pt-4">
									<i class="uil uil-user-plus fs-1 text-white"></i>
								</div>
								<div class="bg-alt- px-2 text-6 text-white py-1">
									{{ $t('home.add_employee') }}
								</div>
							</routerLink>
							<routerLink
								to="/formations/create"
								class="col home-menu-item bg-lighther- p-0 ms-1"
							>
								<div class="text-center move h120px pt-4">
									<i class="uil uil-book-medical text-white fs-1"></i>
								</div>
								<div class="bg-alt- px-2 text-6 text-white py-1">
									{{ $t('home.add_training') }}
								</div>
							</routerLink>
						</div>
					</div>
				</div>

				<div class="col-xxl-4 col-xl-6 col-lg-6 col-md-8 col-sm-10 mx-auto">
					<div class="mt-2">
						<div class="row bg-dark text-white text-5 px-2 py-1">
							{{ $t('home.documents') }}
						</div>
						<routerLink
							to="/documents/create"
							class="row home-menu-item h120px bg-primary- py-4 mt-1"
						>
							<div class="col move text-center">
								<i class="uil uil-cloud text-white fs-1"></i>
							</div>
							<div class="col text-6 text-white">
								{{ $t('home.document_action') }}
							</div>
						</routerLink>
						<routerLink
							to="/documents/create"
							class="row bg-alt- text-white text-6 px-2 py-1"
						>
							{{ $t('home.import_file') }}
						</routerLink>
						<div class="row mt-1">
							<routerLink
								to="/documents"
								class="col home-menu-item bg-lighther- p-0"
							>
								<div class="text-center move h120px pt-4">
									<i class="uil uil-books text-white fs-1"></i>
								</div>
								<div class="bg-alt- px-2 text-6 text-white py-1">
									{{ $t('home.consult_library') }}
								</div>
							</routerLink>
							<routerLink
								to="/documents-favorites"
								class="col home-menu-item bg-lighther- p-0 ms-1"
							>
								<div class="text-center move h120px pt-4">
									<i class="uil uil-folder-heart text-white fs-1"></i>
								</div>
								<div class="bg-alt- px-2 text-6 text-white py-1">
									{{ $t('home.see_favorites') }}
								</div>
							</routerLink>
						</div>
					</div>
				</div>

				<div class="col-xxl-4 col-xl-6 col-lg-6 col-md-8 col-sm-10 mx-auto">
					<div class="mt-2">
						<div class="row bg-dark text-white text-5 px-2 py-1">
							{{ $t('home.candidate') }}
						</div>
						<div class="row mt-1">
							<routerLink
								to="/offers"
								class="col home-menu-item bg-primary- p-0"
							>
								<div class="text-center move h120px pt-4">
									<i class="uil uil-file-search-alt text-white fs-1"></i>
								</div>
								<div class="bg-alt- px-2 text-6 text-white py-1">
									{{ $t('home.offer_list') }}
								</div>
							</routerLink>
							<routerLink
								to="/candidates-profiles"
								class="col home-menu-item bg-primary- p-0 ms-1"
							>
								<div class="text-center move h120px pt-4">
									<i class="uil uil-file-question-alt text-white fs-1"></i>
								</div>
								<div class="bg-alt- px-2 text-6 text-white py-1">
									{{ $t('home.candidat_list') }}
								</div>
							</routerLink>
						</div>
						<routerLink
							to="/offer/create"
							class="row bg-lighther- home-menu-item h120px pt-3 mt-1"
						>
							<div class="col move text-center">
								<i class="uil uil-file-question text-white fs-1"></i>
							</div>
							<div class="col text-6 text-white">
								{{ $t('home.candidate_test_action') }}
							</div>
						</routerLink>
						<routerLink
							to="/offer/create"
							class="row bg-alt- text-white text-6 px-2 py-1"
						>
							{{ $t('home.post_offer') }}
						</routerLink>
					</div>
				</div>

				<div class="col-xxl-4 col-xl-6 col-lg-6 col-md-8 col-sm-10 mx-auto">
					<div class="mt-2">
						<div class="row bg-dark text-white text-5 px-2 py-1">
							{{ $t('home.tools') }}
						</div>
						<div class="row mt-1">
							<routerLink
								to="/tools"
								class="col home-menu-item bg-primary- p-0"
							>
								<div class="text-center move h120px pt-4">
									<i class="uil uil-calender text-white fs-1"></i>
								</div>
								<div class="bg-alt- px-2 text-6 text-white py-1">
									{{ $t('home.go_to_calendar') }}
								</div>
							</routerLink>
							<routerLink
								to="/calendar-new"
								class="col home-menu-item bg-primary- p-0 ms-1"
							>
								<div class="text-center move h120px pt-4">
									<i class="uil uil-create-dashboard text-white fs-1"></i>
								</div>
								<div class="bg-alt- px-2 text-6 text-white py-1">
									{{ $t('home.add_task') }}
								</div>
							</routerLink>
						</div>
						<routerLink
							to="/candidates-profiles"
							class="row bg-lighther- home-menu-item h120px pt-3 mt-1"
						>
							<div class="col-6 move text-center">
								<i class="uil uil-file-alt text-white fs-1"></i>
							</div>
							<div class="col-6 text-6 text-white">
								{{ $t('home.offer_action') }}
							</div>
						</routerLink>
						<routerLink
							to="/candidates-profiles"
							class="row bg-alt- text-white text-6 px-2 py-1"
						>
							{{ $t('home.offer_forms') }}
						</routerLink>
					</div>
				</div>

				<div class="col-xxl-4 col-xl-6 col-lg-6 col-md-8 col-sm-10 mx-auto">
					<div class="mt-2">
						<div class="row bg-dark text-white text-5 px-2 py-1">
							{{ $t('home.integreted_management_program') }}
						</div>
						<div class="row mt-1">
							<routerLink
								to="/program-managements/sale"
								class="col home-menu-item bg-primary- p-0"
							>
								<div class="text-center move h120px pt-4">
									<i class="uil uil-euro-circle text-white fs-1"></i>
								</div>
								<div class="bg-alt- px-2 text-6 text-white py-1">
									{{ $t('home.consult_sell') }}
								</div>
							</routerLink>
							<routerLink
								to="/program-managements/buying"
								class="col home-menu-item bg-primary- p-0 ms-1"
							>
								<div class="text-center move pt-4 h120px">
									<i class="uil uil-bill text-white fs-1"></i>
								</div>
								<div class="bg-alt- px-2 text-6 text-white py-1">
									{{ $t('home.view_purchases') }}
								</div>
							</routerLink>
						</div>
						<div class="row mt-1">
							<routerLink
								to="/program-managements/sale/create"
								class="col home-menu-item bg-lighther- p-0"
							>
								<div class="text-center move h120px pt-4">
									<i class="uil uil-calculator text-white fs-1"></i>
								</div>
								<div class="bg-alt- px-2 text-6 text-white py-1">
									{{ $t('home.issue_invoice') }}
								</div>
							</routerLink>
							<routerLink
								to="/program-managements/buying/create"
								class="col home-menu-item bg-lighther- p-0 ms-1"
							>
								<div class="text-center move h120px pt-4">
									<i class="uil uil-money-withdraw text-white fs-1"></i>
								</div>
								<div class="bg-alt- px-2 text-6 text-white py-1">
									{{ $t('home.save_invoice') }}
								</div>
							</routerLink>
						</div>
					</div>
				</div>
			</section>
		</Template>
	</div>
</template>
<script>
import Template from '@/components/Template.vue';
export default {
	name: 'HomePage',
	components: { Template },
};
</script>
<style>
.home-menu-item:hover div.move {
	color: aqua !important;
	transform: rotate(15deg) !important;
	transition: all ease-in-out 0.3s;
}
.h120px {
	height: 120px !important;
}
.h240px {
	height: 272px !important;
}
</style>
