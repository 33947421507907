<!-- eslint-disable no-mixed-spaces-and-tabs -->
<template>
	<div>
		<Template title="null" sidebarMenu="employees">
			<div class="bg-light-hight- hstack py-3 ps-2 pb-3">
				<router-link
					to="/employees"
					class="bg-light-hight- ms-3 text-6 top-item"
					>{{ $t('resources.employees') }}</router-link
				>
				<router-link
					to="/sections"
					class="bg-light-hight- ms-4 text-6 top-item"
					>{{ $t('resources.sections') }}</router-link
				>
				<router-link
					to="/postes"
					class="bg-light-hight- ms-4 text-6 top-item"
					>{{ $t('resources.postes') }}</router-link
				>
				<router-link
					to="/contrats"
					class="bg-light-hight- ms-4 text-6 top-item"
					>{{ $t('resources.contracts') }}</router-link
				>
				<router-link
					to="/formations"
					class="bg-light-hight- ms-4 text-6 top-item"
					>{{ $t('resources.training') }}</router-link
				>
			</div>

			<section class="row px-4 m-0 g-4 mt-1">
				<!-- Modal -->
				<div
					class="modal fade"
					id="createSection"
					data-mdb-backdrop="static"
					data-mdb-keyboard="false"
					tabindex="-1"
					aria-labelledby="createSectionLabel"
					aria-hidden="true"
				>
					<div
						class="modal-dialog modal-dialog-scrollable modal-dialog-centered"
					>
						<div class="modal-content rounded-0">
							<form @submit.prevent="addSection" class="modal-body">
								<h6 class="text-center text-alt-">
									{{
										section_id == ''
											? $t('section.create_section')
											: $t('section.update_section')
									}}
								</h6>
								<hr />
								<div
									class="mb-2 text-primary-"
									v-show="message_section_add != ''"
								>
									{{ message_section_add }}
								</div>
								<InputFC
									:error="errors.name"
									:type="'text'"
									v-model="name"
									:required="true"
									:placeholder="$t('section.section_name')"
								/>
								<InputFC
									:error="errors.code"
									:type="'text'"
									class="mt-4"
									:required="true"
									v-model="code"
									:placeholder="$t('section.section_code')"
								/>
								<InputFC
									:error="errors.responsible"
									:type="'select'"
									class="mt-4"
									:required="true"
									:options="employees"
									v-model="responsible"
									:placeholder="$t('section.responsible')"
								/>
								<div class="mt-4">
									<label for="name">{{ $t('resources.postes') }}</label>
									<v-select
										multiple
										class="bg-white mt-1"
										v-model="model_job"
										:options="jobs"
										:reduce="(job) => job.id"
										label="name"
									></v-select>
									<span
										v-show="errors.job != ''"
										v-text="errors.job"
										class="error"
									></span>
								</div>
								<div class="mt-4">
									<label for="">{{ $t('resources.employees') }}</label>
									<v-select
										multiple
										class="bg-white mt-1"
										v-model="model_employee"
										:options="employees"
										:reduce="(employee) => employee.id"
										label="name"
									></v-select>
									<span
										v-show="errors.employee != ''"
										v-text="errors.employee"
										class="error"
									></span>
								</div>
								<InputFC
									:error="errors.description"
									:type="'textarea'"
									:required="true"
									class="mt-4"
									v-model="description"
									:placeholder="$t('global.description')"
								/>
								<hr />
								<div class="row">
									<div class="col mt-4 text-center">
										<span
											type="button"
											class="border bg-light px-3 text-5 rounded py-1"
											data-mdb-dismiss="modal"
											>{{ $t('global.close') }}</span
										>
									</div>
									<div class="col text-center">
										<SpinnerMe :elementId="'spinner'" />
										<input
											type="submit"
											:value="$t('global.save')"
											id="submit"
											class="bg-alt- mt-4 text-white- px-3 rounded py-1 text-5 border-0 set-opacity"
										/>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>

				<div
					class="modal fade"
					id="deleteSection"
					data-mdb-backdrop="static"
					data-mdb-keyboard="false"
					tabindex="-1"
					aria-labelledby="deleteSectionLabel"
					aria-hidden="true"
				>
					<div
						class="modal-dialog modal-dialog-scrollable modal-dialog-centered"
					>
						<div class="modal-content rounded-0">
							<form @submit.prevent="deleteSection" class="modal-body">
								<h6 class="text-center text-alt-">
									{{ $t('section.delete_section') }}
								</h6>
								<hr />
								<div class="mb-2 text-red-">
									{{ $t('section.deletion_text') }}
								</div>

								<hr />
								<div class="row">
									<div class="col mt-4 text-center">
										<span
											type="button"
											class="border bg-light px-3 text-5 rounded py-1"
											data-mdb-dismiss="modal"
											>{{ $t('global.close') }}</span
										>
									</div>
									<div class="col text-center">
										<SpinnerMe :elementId="'spinner'" />
										<input
											type="submit"
											:value="$t('global.delete')"
											id="submit"
											class="bg-red- mt-4 text-white- px-3 rounded py-1 text-5 border-0 set-opacity"
										/>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>

				<div class="col-xl-9">
					<div class="mb-5">
						<div class="hstack">
							<button
								class="bg-second- rounded ms-auto px-3 p-1"
								type="button"
								@click="uneditInit"
								data-mdb-toggle="modal"
								data-mdb-target="#createSection"
							>
								<i class="uil uil-plus text-white"></i>
								<span class="ms-2 text-6 text-white">{{
									$t('section.create_section')
								}}</span>
							</button>
						</div>

						<div class="hstack mt-4 p-2 px-4 rounded shadow-1 bg-white">
							<input
								type="checkbox"
								:checked="allIsCheck"
								@click="check(0, true)"
							/>

							<div class="hstack ms-auto">
								<label for="" class="text-5 me-2">{{
									$t('global.filter')
								}}</label>
								<select class="form-control text-6" v-model="orderBy">
									<option value="id">
										{{ $t('global.order_of_addition') }}
									</option>
									<option value="code">Code</option>
									<option value="name">{{ $t('global.lastname') }}</option>
									<option value="description">
										{{ $t('global.description') }}
									</option>
									<option value="updatedAt">
										{{ $t('section.update_at') }}
									</option>
								</select>

								<label for="" class="text-5 ms-2 me-2">{{
									$t('global.order')
								}}</label>
								<select class="form-control text-6" v-model="order">
									<option value="ASC">Asc</option>
									<option value="DESC">Desc</option>
								</select>
							</div>

							<div class="ms-auto hstack">
								<i
									class="uil uil-arrow-circle-left fs-5"
									type="button"
									@click="previous"
								></i>
								<small class="ms-3"
									>{{ Math.ceil(sections.length / 25) > 0 ? page : 0 }} /
									{{ Math.ceil(sections.length / 25) }}</small
								>
								<i
									class="uil uil-arrow-circle-right fs-5 ms-3"
									type="button"
									@click="next"
								></i>
								<div class="hstack ms-5">
									<i
										class="uil uil-list-ul fs-6 ms-1 p-1 px-3 rounded"
										:class="
											disposition == 'list'
												? 'bg-black- text-white-'
												: 'bg-white- text-black-'
										"
										@click="disposition = 'list'"
										type="button"
									></i>
								</div>
							</div>
						</div>

						<LoadingMain :state="loading" />

						<div v-if="!loading && sections.length != 0">
							<div class="table-responsive mt-0">
								<table
									class="table sections align-middle table-hover table-striped mb-0"
									style="width: 100% !important"
								>
									<thead class="bg-primary-">
										<tr class="text-6 text-white">
											<th></th>
											<th>Section</th>
											<th>Code</th>
											<th>{{ $t('section.responsible') }}</th>
											<th class="text-center">{{ $t('global.action') }}</th>
										</tr>
									</thead>
									<tbody class="bg-alt-">
										<tr
											v-for="(section, index) in sections"
											:key="section.id"
											v-show="show(index)"
											class="text-6 text-black"
										>
											<td>
												<input
													type="checkbox"
													:checked="isSelected(section.id)"
													@click="check(section.id)"
												/>
											</td>
											<td>
												<router-link
													:to="{
														name: 'sections-show',
														params: { id: section.id, slug: section.slug },
													}"
												>
													{{ section.name }}
												</router-link>
											</td>
											<td>{{ section.code }}</td>
											<td>
												{{
													section.responsible != null
														? section.responsible.lastname +
														  ' ' +
														  section.responsible.firstname
														: ''
												}}
											</td>
											<td class="text-center">
												<i
													:title="$t('global.update')"
													class="uil uil-pen fs-6 text-white"
													type="button"
													@click="editInit(section)"
													data-mdb-toggle="modal"
													data-mdb-target="#createSection"
												></i>
												<i
													:title="$t('global.delete')"
													class="uil uil-trash fs-6 text-white px-2"
													type="button"
													@click="editInit(section)"
													data-mdb-toggle="modal"
													data-mdb-target="#deleteSection"
												></i>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
						<div
							class="py-4 text-center text-black-"
							v-if="!loading && sections.length == 0"
						>
							{{ $t('section.no_employee_find') }}
						</div>
					</div>
				</div>

				<div class="col-xl-3">
					<div class="rounded bg-second- text-white text-4 text-center p-1">
						{{ $t('global.advice') }}
					</div>
					<div
						class="mt-1 bg-light-hight- text-alt- rounded text-7 text-center p-2"
						v-html="$t('section.section_advice_text')"
					></div>
				</div>
			</section>
		</Template>
	</div>
</template>

<script>
import Template from '@/components/Template.vue';
import Helper from '@/helpers/helper';
import LoadingMain from '@/components/LoadingMain.vue';
import InputFC from '@/components/InputFC.vue';
import SpinnerMe from '@/components/SpinnerMe.vue';
export default {
	name: 'SectionIndex',
	components: { Template, LoadingMain, InputFC, SpinnerMe },
	data() {
		return {
			deleted: false,
			archived: false,
			loading: true,
			selectedList: [],
			allIsCheck: false,
			page: 1,
			order: 'DESC',
			perPage: 25,
			orderBy: 'id',
			disposition: 'list',
			sections: [],
			errors: [],
			bests: [],
			salaryCharge: 0,
			name: '',
			code: '#',
			check_form: false,
			message_section_add: '',
			description: '',
			responsible: '',
			model_employee: [],
			employees: [],
			section_id: '',
			model_job: [],
			jobs: [],
		};
	},
	methods: {
		check(id, all = false) {
			if (all) {
				if (this.selectedList.length !== this.sections.length) {
					this.selectedList.length = 0;
					this.sections.forEach((section) => {
						this.selectedList.push({ id: section.id });
					});
				} else {
					this.selectedList.length = 0;
				}
			} else {
				let found = false;
				this.selectedList.forEach((element) => {
					if (element.id == id) {
						found = true;
					}
				});
				if (found) {
					this.selectedList.filter((value, index) => {
						if (value.id == id) {
							this.selectedList.splice(index, 1);
						}
					});
				} else {
					this.selectedList.push({ id: id });
				}
			}
			if (this.selectedList.length === this.sections.length) {
				this.allIsCheck = true;
			} else {
				this.allIsCheck = false;
			}
		},
		show(index) {
			return (
				index < this.page * this.perPage &&
				index + 1 > (this.page - 1) * this.perPage
			);
		},
		next() {
			if (this.page * this.perPage < this.sections.length) {
				this.page++;
			}
		},
		previous() {
			if (this.page >= 2) {
				this.page--;
			}
		},
		isSelected(id) {
			let found = false;
			this.selectedList.forEach((element) => {
				if (element.id == id) {
					found = true;
				}
			});
			return found;
		},
		editInit(section) {
			this.section_id = section.id;
			this.name = section.name;
			this.code = section.code;
			this.responsible =
				section.responsible != null ? section.responsible.id : '';
			this.description = section.description;
			this.model_employee = section.employees.map((e) => {
				return { id: e.id, name: e.lastname + '' + e.firstname };
			});
			this.model_job = section.jobs.map((j) => {
				return { id: j.id, name: j.designation };
			});
		},
		uneditInit() {
			this.section_id = '';
			this.name = '';
			this.code = '';
			this.responsible = '';
			this.description = '';
		},
		addSection() {
			console.log('JBSSS', this.model_job);
			console.log('EMPLL', this.model_employee);

			if (this.section_id == '') {
				this.ok = true;
				Helper.loading('submit', 'spinner', true);

				fetch(
					Helper.route('admin/section/add'),
					Helper.requestOptionsJSON('POST', {
						code: this.code,
						description: this.description,
						name: this.name,
						responsible: this.responsible,
						employees: this.model_employee.map((e) => (e.id ? e.id : e)),
						jobs: this.model_job.map((j) => (j.id ? j.id : j)),
					})
				)
					.then((response) => {
						Helper.tokenChecker(response.status);
						if (response.status != 200) {
							this.ok = false;
						}
						return response.json();
					})
					.then((data) => {
						Helper.loading('submit', 'spinner', false);
						console.log(data);

						if (data.problems) {
							this.errors.name = Helper.getError(data.problems, 'name');
							this.errors.code = Helper.getError(data.problems, 'code');
							this.errors.description = Helper.getError(
								data.problems,
								'description'
							);
							this.errors.responsible = Helper.getError(
								data.problems,
								'responsible'
							);

							Helper.notification('error', data.message);
						} else {
							this.message_section_add = data.message;
							this.sections.unshift(data.data);
							this.name = '';
							this.code = '';
							this.responsible = '';
							this.description = '';
							this.model_employee = [];
							this.model_job = [];

							Helper.notification('success', data.message);
						}
					})
					.catch((error) => {
						console.log(error);
						Helper.loading('submit', 'spinner', false);
					});
			} else {
				this.ok = true;
				Helper.loading('submit', 'spinner', true);

				fetch(
					Helper.route('admin/section/update'),
					Helper.requestOptionsJSON('PUT', {
						id: this.section_id,
						code: this.code,
						description: this.description,
						name: this.name,
						responsible: this.responsible,
						employees: this.model_employee.map((e) => (e.id ? e.id : e)),
						jobs: this.model_job.map((j) => (j.id ? j.id : j)),
					})
				)
					.then((response) => {
						Helper.tokenChecker(response.status);
						if (response.status != 200) {
							this.ok = false;
						}
						return response.json();
					})
					.then((data) => {
						Helper.loading('submit', 'spinner', false);
						console.log(data);

						if (data.problems) {
							this.errors.name = Helper.getError(data.problems, 'name');
							this.errors.code = Helper.getError(data.problems, 'code');
							this.errors.description = Helper.getError(
								data.problems,
								'description'
							);
							this.errors.responsible = Helper.getError(
								data.problems,
								'responsible'
							);
						} else {
							this.sections = this.sections.map((i) =>
								i.id !== this.section_id ? i : data.data
							);
							this.message_section_add = data.message;

							Helper.notification('success', data.message);
						}
					})
					.catch((error) => {
						console.log(error);
						Helper.loading('submit', 'spinner', false);
					});
			}
		},
		loadList() {
			this.loading = true;
			fetch(
				Helper.route(
					'admin/section/all?sortBy=' + this.orderBy + '&order=' + this.order
				),
				Helper.requestOptions()
			)
				.then((response) => {
					Helper.tokenChecker(response.status);
					return response.json();
				})
				.then((data) => {
					console.log(data);
					this.loading = false;
					if (data.data) {
						this.sections = data.data;
					}
				})
				.catch((error) => {
					console.log(error);
				});
		},
		deleteSection() {
			this.ok = true;
			Helper.loading('submit', 'spinner', true);

			fetch(
				Helper.route('admin/section/delete/' + this.section_id),
				Helper.requestOptions('DELETE')
			)
				.then((response) => {
					Helper.tokenChecker(response.status);
					if (response.status != 200) {
						this.ok = false;
					}
					return response.json();
				})
				.then((data) => {
					Helper.loading('submit', 'spinner', false);
					console.log(data);

					if (data.problems) {
						Helper.notification('error', data.message);
					} else {
						Helper.notification('success', data.message);
					}
				})
				.catch((error) => {
					console.log(error);
					Helper.loading('submit', 'spinner', false);
				});
		},
	},
	watch: {
		orderBy: function () {
			this.loadList();
		},
		order: function () {
			this.loadList();
		},
	},
	created() {
		this.loadList();

		fetch(Helper.route('admin/employee/all'), Helper.requestOptions())
			.then((response) => response.json())
			.then((data) => {
				if (data.data) {
					data.data.forEach((employee_item) => {
						this.employees.push({
							id: employee_item.id,
							name: employee_item.lastname + ' ' + employee_item.firstname,
						});
					});
				}
			})
			.catch((error) => {
				console.log(error);
			});

		fetch(Helper.route('admin/job/all'), Helper.requestOptions())
			.then((response) => response.json())
			.then((data) => {
				if (data.data) {
					data.data.forEach((job_item) => {
						this.jobs.push({
							id: job_item.id,
							name: job_item.designation,
						});
					});
				}
			})
			.catch((error) => {
				console.log(error);
			});
	},
};
</script>

<style>
.sections th,
.sections td {
	color: white !important;
}
</style>
