<template>
	<div>
		<Template sidebarMenu="offers">
			<section class="row px-4 m-0 g-4 mt-1">
				<Back />

				<!-- Suppression -->
				<div
					class="modal fade"
					id="deleteModal"
					data-mdb-backdrop="static"
					data-mdb-keyboard="false"
					tabindex="-1"
					aria-labelledby="deleteModalLabel"
					aria-hidden="true"
				>
					<div class="modal-dialog modal-dialog-centered">
						<div class="modal-content rounded-0">
							<div class="modal-body">
								<h6 class="text-center text-red-">
									{{$t('global.deletion_warning')}}
								</h6>
								<hr />
								<p class="text-5" v-show="!deleted" v-html="$t('candidate.offers.quiz_deletion_text')">
								
								</p>
								<p class="text-5 text-center" v-show="deleted">
									{{$t('global.deleted_successfully')}}
									<i class="uil uil-check-circle fs-4 text-primary-"></i>
								</p>
								<hr />
								<div class="row">
									<div class="col text-center">
										<button
											type="button"
											class="border bg-light px-3 text-5 rounded py-1"
											data-mdb-dismiss="modal"
										>
											{{$t('global.close')}}
										</button>
									</div>
									<div class="col text-center" v-show="!deleted">
										<button
											type="button"
											class="bg-red- text-white- px-3 rounded py-1 text-5 border-0 set-opacity"
											data-mdb-dismiss="modal"
											@click="deleteQuiz()"
										>
											{{$t('global.delete')}}
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="col-xl-9">
					<LoadingMain :state="loading" />

					<div
						v-if="offer == null && !loading"
						class="p-5 text-red- text-5 text-center"
					>
						{{$t('candidate.offers.offer_no_found')}}
					</div>

					<div class="my-4" v-if="offer !== null && quizzes.length == 0">
						<div class="bg-light- border p-0">
							<h3
								class="bg-light-1 text-center m-0 text-red- text-uppercase py-3 text-4"
							>
								{{
									IS_UPDATE_CTX
										? `${$t('candidate.offers.edit_quiz')} n°${UPDATE_Q_ID + 1} ${$t('candidate.offers.of_quiz')}`
										: $t('candidate.offers.add_quiz')
								}}
							</h3>
							<form
								@submit.prevent="addQuiz"
								class="p-3 bg-light-hight- fw-700 m-0 text-primary-"
							>
								<div class="row">
									<InputFC
										:error="errors.question"
										:required="true"
										class="col-sm-8"
										:type="'text'"
										v-model="question"
										:placeholder="$t('candidate.offers.question_wording')"
									/>
									<div class="col-sm-4">
										<label for="">{{$t('candidate.offers.points_awarded')}}</label>
										<input
											type="number"
											class="form-control"
											min="1"
											:max="MAX_TOTAL_POINTS - points"
											v-model="point"
											:placeholder="$t('candidate.offers.point_number')"
										/>
									</div>
								</div>
								<InputFC
									class="mt-3"
									:type="'select'"
									:options="is_strict"
									v-model="isStrict"
									:placeholder="$t('candidate.offers.awarding_method')"
								/>
								<hr />
								<div
									v-for="(response, index) in responses"
									:key="index"
									class="border p-3"
								>
									<InputFC
										:type="'text'"
										v-model="response.statement"
										:placeholder="$t('candidate.offers.suggested_response')+' n°' + (index + 1)"
									/>
									<InputFC
										class="mt-3"
										:type="'select'"
										:options="right_false"
										v-model="response.isCorrect"
										:placeholder="$t('candidate.offers.is_correct_proposiion')"
									/>
									<span
										class="button bg-red- p-1 px-2 text-6 mt-3"
										type="button"
										@click="deleteResponse(index)"
										v-if="index > 1"
										>{{$t('candidate.offers.delete_proposition')}}</span
									>
								</div>
								<span
									class="button text-6 p-1 mt-3 px-2"
									type="button"
									@click="addResponse"
									>{{$t('candidate.offers.add_suggestion')}}</span
								>
							</form>

							<div class="hstack mb-3">
								<span
									class="button bg-alt- px-3 py-2 text-5 ms-auto mx-4 mt-3 mb-0"
									type="button"
									@click="saveQuiz()"
									>{{
										IS_UPDATE_CTX ? $t('candidate.offers.edit_quiz') : $t('candidate.offers.add_question')
									}}</span
								>
							</div>
						</div>

						<div v-if="data.length > 0" class="bg-light- pb-2">
							<h3
								class="bg-light-1 text-center m-0 text-red- text-uppercase py-3 text-4"
							>
								{{$t('candidate.offers.quiz_overview')}}
							</h3>

							<div class="px-3 pt-3 text-6 mb-2">
								{{$t('candidate.offers.points_can_awarded')}}: <b>{{ MAX_TOTAL_POINTS }}</b> |
								{{$t('candidate.offers.all_points_awarded')}}: <b>{{ points }}</b> | {{$t('candidate.offers.all_points')}}
								{{$t('remaining')}}:
								<b>{{ MAX_TOTAL_POINTS - points }}</b>
							</div>

							<div class="p-3 pb-0" v-for="(quiz, i) in data" :key="i">
								<h6 class="text-black- mb-2">
									Q{{ i + 1 }} - {{ quiz.question }}
									<span class="text-5 text-primary-"
										>({{
											quiz.points + ' point' + (quiz.points > 1 ? 's' : '')
										}})</span
									>
									<kbd class="text-warning bg-white text-6 rounded-9 mx-2"
										>{{$t('candidate.offers.rating')}}:{{ quiz.isStrict ? 'stricte' : 'flexible' }}</kbd
									>
									<span
										role="button"
										class="text-red- uil uil-trash p-1"
										:title="$t('global.withdraw')"
										@click="removeQuestion(i)"
									>
									</span>
									<span
										role="button"
										class="text-dark uil uil-pen p-1"
										:title="$t('global.edit')"
										@click="updateQuestion(i)"
									>
									</span>
								</h6>
								<div class="ms-2">
									<ul>
										<li v-for="(answer, index) in quiz.answers" :key="index">
											<span class="text-5 text-black-">{{ index + 1 }} - </span>
											<span class="text-5 me-2 0">{{ answer.statement }}</span>
											<i
												v-if="
													answer.isCorrect == true || answer.isCorrect == 'true'
												"
												class="text-4 uil uil-check-circle text-green-"
											></i>
											<i
												v-else
												class="text-4 uil uil-times-circle text-red-"
											></i>
										</li>
									</ul>
								</div>
							</div>
							<hr />
							<div class="hstack my-3 me-3" v-if="points == MAX_TOTAL_POINTS">
								<SpinnerMe :elementId="'spinner'" class="ms-auto" />
								<button
									class="button bg-success px-3 py-2 text-5 ms-auto"
									@click="saveQuizzes()"
								>
									{{$t('candidate.offers.save_quiz')}}
								</button>
							</div>
						</div>
					</div>

					<div v-else>
						<div class="bg-light-hight- mt-4 mb-5" v-if="quizzes.length > 0">
							<div class="hstack bg-light-1">
								<h3
									class="text-center m-0 text-red- text-uppercase px-3 py-3 text-4"
								>
									{{$t('candidate.offers.questionnaire')}}
								</h3>
								<div class="ms-auto">
									<span
										role="button"
										class="mx-3 uil uil-trash-alt text-2 text-red-"
										title="Supprimer"
										data-mdb-toggle="modal"
										data-mdb-target="#deleteModal"
										@click="deleted = false"
									></span>
								</div>
							</div>

							<div class="p-3 pb-3" v-for="(quiz, i) in quizzes" :key="quiz.id">
								<h6 class="text-black- mb-2">
									Q{{ i + 1 }} - {{ quiz.statement }}
									<span class="text-5 text-primary-"
										>({{
											quiz.points + ' point' + (quiz.points > 1 ? 's' : '')
										}})</span
									>
									<kbd class="text-warning bg-white text-6 rounded-9 mx-2"
										>notation:{{ quiz.isStrict ? 'stricte' : 'flexible' }}</kbd
									>
								</h6>
								<div class="ms-2">
									<ul>
										<li
											v-for="(answer, index) in quiz.answers"
											:key="answer.id"
											:class="
												answer.isCorrect == true ? 'text-green-' : 'text-red-'
											"
										>
											<span class="text-5 text-black-">{{ index + 1 }} - </span>
											<span class="text-5 me-2 0">{{ answer.statement }}</span>

											<i
												v-if="
													answer.isCorrect == true || answer.isCorrect == 'true'
												"
												class="text-4 uil uil-check-circle text-green-"
											></i>
											<i
												v-else
												class="text-4 uil uil-times-circle text-red-"
											></i>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-xl-3">
					<div
						class="rounded bg-second- text-white text-4 text-center p-1 mt-4"
					>
						{{$t('candidate.offers.tips')}}
					</div>
					<div
						class="mt-1 bg-light-hight- text-justify text-alt- rounded text-7 text-center p-2"
					>
						<b class="text-5">{{$t('candidate.offers.points_awarded_method')}}</b>
						<li>
							<b class="text-5 text-red-">STRICT:</b> {{$t('candidate.offers.tips_strict')}}
						</li>
						<li>
							<b class="text-5 text-red-">FLEXIBLE:</b> {{$t('candidate.offers.tips_flexible')}}
						</li>
					</div>
				</div>
			</section>
		</Template>
	</div>
</template>
<style></style>
<script>
import Template from '@/components/Template.vue';
import Back from '@/components/Back.vue';
import Helper from '@/helpers/helper';
import LoadingMain from '@/components/LoadingMain.vue';
import InputFC from '@/components/InputFC.vue';
export default {
	name: 'OfferQuizPage',
	components: { Template, Back, LoadingMain, InputFC },
	data() {
		return {
			id: '',
			loading: true,
			deleted: false,
			offer: null,
			quizzes: [],
			data: [],
			quiz: '',
			errors: [],
			question: '',
			points: 0,
			point: 1,
			isStrict: true,
			responses: [
				{
					statement: '',
					isCorrect: false,
				},
				{
					statement: '',
					isCorrect: true,
				},
			],
			right_false: [
				{
					id: true,
					name: 'OUI',
				},
				{
					id: false,
					name: 'NON',
				},
			],
			is_strict: [
				{
					id: true,
					name: 'STRICT',
				},
				{
					id: false,
					name: 'FLEXIBLE',
				},
			],
			MAX_TOTAL_POINTS: 20,
			IS_UPDATE_CTX: false,
			UPDATE_Q_ID: 0,
		};
	},
	methods: {
		scrollToTop() {
			window.scrollTo({
				top: 0,
			});
		},
		addResponse() {
			this.responses.push({
				statement: '',
				isCorrect: this.responses.length % 2 == 1 ? true : false,
			});
		},
		deleteResponse(index) {
			this.responses.splice(index, 1);
		},
		removeQuestion(i) {
			this.points -= this.data[i].points;
			this.data.splice(i, 1);
			Helper.notification(
				'success',
				$t('candidate.offers.question_removed')
			);
		},
		updateQuestion(i) {
			const item = this.data[i];

			this.responses = item.answers;
			this.question = item.question;
			this.point = item.points;
			this.isStrict = item.isStrict;

			this.IS_UPDATE_CTX = true;
			this.UPDATE_Q_ID = i;

			Helper.notification(
				'success',
				`${$t('candidate.offers.can_edit_question')} n°${this.UPDATE_Q_ID + 1}`
			);

			this.$nextTick(() => {
				this.scrollToTop();
			});
		},
		saveQuiz() {
			if (
				this.question &&
				this.responses.find(
					(r) => r.isCorrect == true || r.isCorrect === 'true'
				) != undefined
			) {
				if (
					(this.point > 0 &&
						this.point + this.points <= this.MAX_TOTAL_POINTS) ||
					(this.IS_UPDATE_CTX &&
						this.point + (this.points - this.data[this.UPDATE_Q_ID].points) <=
							this.MAX_TOTAL_POINTS)
				) {
					let item = {};
					item['question'] = this.question;
					item['answers'] = this.responses;
					item['points'] = this.point;
					item['isStrict'] = this.isStrict == 'true';

					if (this.IS_UPDATE_CTX) {
						this.points -= this.data[this.UPDATE_Q_ID].points;
						this.points += this.point;
						delete this.data[this.UPDATE_Q_ID];
						this.data[this.UPDATE_Q_ID] = item;
					} else {
						this.points += this.point;
						this.data.push(item);
					}

					this.question = '';
					this.answers = '';
					this.point = 1;

					this.responses = [
						{
							statement: '',
							isCorrect: false,
						},
						{
							statement: '',
							isCorrect: true,
						},
					];

					if (this.IS_UPDATE_CTX) {
						Helper.notification(
							'success',
							`${$t('candidate.offers.question')} n°${this.UPDATE_Q_ID + 1} ${$t('candidate.offers.edit_success')}`
						);
					} else {
						Helper.notification(
							'success',
							$t('candidate.offers.question_added_successfully')
						);
					}

					this.IS_UPDATE_CTX = false;
				} else {
					Helper.notification(
						'error',
						`${$t('candidate.offers.cannot_be_worth_more')} ${
							this.MAX_TOTAL_POINTS - this.points
						} points`
					);
				}
			} else {
				Helper.notification(
					'error',
					$t('candidate.offers.required_question_notif')
				);
			}
		},
		saveQuizzes() {
			this.loading = true;
			this.ok = true;
			this.$isLoading(true);

			console.log(
				'🚀 ~ file: Quiz.vue:428 ~ saveQuizzes ~ this.data:',
				this.data
			);

			const body = {
				joboffer: this.id,
				data: this.data,
				totalPoints: 20,
			};

			fetch(
				Helper.route('admin/quiz/add'),
				Helper.requestOptionsJSON('POST', body)
			)
				.then((response) => {
					Helper.tokenChecker(response.status);
					if (response.status != 200) {
						this.ok = false;
					}
					return response.json();
				})
				.then((data) => {
					this.$isLoading(false);
					if (!this.ok) {
						this.$swal.fire('Oops...', data.message, 'error');
					} else {
						this.$swal.fire('Enregistrement effectué', data.message, 'success');
						this.$router.go();
					}
				})
				.catch((error) => {
					this.$isLoading(false);
					console.log(error);
				});
			this.loading = false;
		},
		getOffer() {
			this.loading = true;
			fetch(Helper.route('admin/joboffer/' + this.id), Helper.requestOptions())
				.then((response) => {
					Helper.tokenChecker(response.status);
					if (response.status != 200) {
						this.ok = false;
					}
					return response.json();
				})
				.then((data) => {
					this.loading = false;
					if (data.data) {
						this.offer = data.data;
					}
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getQuizzes() {
			this.loading = true;
			fetch(Helper.route('admin/quiz/' + this.id), Helper.requestOptions())
				.then((response) => {
					Helper.tokenChecker(response.status);
					if (response.status != 200) {
						this.ok = false;
					}
					return response.json();
				})
				.then((data) => {
					console.log(data);
					this.loading = false;
					if (data.data) {
						this.quizzes = data.data;
					}
				})
				.catch((error) => {
					console.log(error);
				});
		},
		deleteQuiz() {
			this.loading = true;
			this.$isLoading(true);
			fetch(
				Helper.route('admin/quiz/delete/' + this.id),
				Helper.requestOptions('DELETE')
			)
				.then((response) => {
					Helper.tokenChecker(response.status);
					if (response.status != 200) {
						this.ok = false;
					}
					this.$isLoading(false);
					return response.json();
				})
				.then((data) => {
					this.loading = false;
					Helper.notification('success', data.message);
					this.$router.go(-1);
				})
				.catch((error) => {
					console.log(error);
				});
			this.loading = false;
			this.$isLoading(false);
		},
	},

	created() {
		this.id = this.$route.params.id;
		this.getOffer();
		this.getQuizzes();
	},
};
</script>
<style></style>
