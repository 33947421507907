<template>
	<div>
		<Template title="null" sidebarMenu="employees">
			<div class="bg-light-hight- hstack py-3 ps-2 pb-3">
				<router-link
					to="/employees"
					class="bg-light-hight- ms-3 text-6 top-item"
					>{{ $t('resources.employees') }}</router-link
				>
				<router-link
					to="/sections"
					class="bg-light-hight- ms-4 text-6 top-item"
					>{{ $t('resources.sections') }}</router-link
				>
				<router-link
					to="/postes"
					class="bg-light-hight- ms-4 text-6 top-item"
					>{{ $t('resources.postes') }}</router-link
				>
				<router-link
					to="/contrats"
					class="bg-light-hight- ms-4 text-6 top-item"
					>{{ $t('resources.contracts') }}</router-link
				>
				<router-link
					to="/formations"
					class="bg-light-hight- ms-4 text-6 top-item"
					>{{ $t('resources.training') }}</router-link
				>
			</div>

			<div class="row px-4 m-0 g-4 mt-1">
				<!-- Modal -->
				<div
					class="modal fade"
					id="createcontract"
					data-mdb-backdrop="static"
					data-mdb-keyboard="false"
					tabindex="-1"
					aria-labelledby="createcontractLabel"
					aria-hidden="true"
				>
					<div
						class="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered"
					>
						<div class="modal-content rounded-0">
							<form @submit.prevent="addContract" class="modal-body">
								<h6 class="text-center text-alt-">
									{{
										contract_id == ''
											? $t('resources.create')
											: $t('resources.update')
									}}
									{{ $t('resources.a_contract') }}
								</h6>
								<hr />
								<div
									class="mb-2 text-primary-"
									v-show="message_contract_add != ''"
								>
									{{ message_contract_add }}
								</div>

								<div class="row">
									<div class="col-lg-6">
										<InputFC
											:error="errors.employee"
											:type="'select'"
											v-model="employee"
											:options="employees"
											:required="true"
											:placeholder="$t('resources.employee')"
										/>
									</div>
									<div class="col-lg-6 mt-lg-0 mt-4">
										<label for="file_contract">{{
											$t('resources.contract_file')
										}}</label>
										<input
											type="file"
											required
											id="file_contract"
											class="form-control"
										/>
									</div>
								</div>

								<div class="row">
									<div class="col-lg-6 mt-4">
										<InputFC
											:error="errors.startDate"
											:type="'datetime-local'"
											v-model="startDate"
											:placeholder="$t('resources.start_date')"
										/>
									</div>
									<div class="col-lg-6 mt-4">
										<InputFC
											:error="errors.endDate"
											:type="'datetime-local'"
											v-model="endDate"
											:placeholder="$t('resources.end_date')"
										/>
										<small>{{ $t('resources.leave_blank') }}</small>
									</div>
								</div>

								<div class="row">
									<div class="col-lg-6 mt-4">
										<InputFC
											:error="errors.type"
											:type="'select'"
											v-model="type"
											:options="contract_types"
											:required="true"
											:placeholder="$t('resources.contract_type')"
										/>
									</div>
									<div class="col-lg-6 mt-4">
										<InputFC
											:error="errors.remuneration"
											:type="'number'"
											v-model="remuneration"
											:required="true"
											:placeholder="$t('resources.remuneration')"
										/>
									</div>
								</div>

								<div class="row">
									<div class="col mt-4 text-center">
										<span
											type="button"
											class="border bg-light px-3 text-5 rounded py-1"
											data-mdb-dismiss="modal"
											>{{ $t('global.close') }}</span
										>
									</div>
									<div class="col text-center">
										<SpinnerMe :elementId="'spinner'" />
										<input
											type="submit"
											:value="$t('global.save')"
											id="submit"
											class="bg-alt- mt-4 text-white- px-3 rounded py-1 text-5 border-0 set-opacity"
										/>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>

				<div class="col-xl-9">
					<div class="mb-5">
						<div class="hstack">
							<button
								class="bg-second- rounded ms-auto px-3 p-1"
								type="button"
								@click="contract_id = ''"
								data-mdb-toggle="modal"
								data-mdb-target="#createcontract"
							>
								<i class="uil uil-plus text-white"></i>
								<span class="ms-2 text-6 text-white">{{
									$t('resources.add_contract')
								}}</span>
							</button>
						</div>

						<div class="hstack mt-4 p-2 px-4 rounded shadow-1 bg-white">
							<span>#</span>

							<div class="hstack ms-auto">
								<label for="" class="text-5 me-2">{{
									$t('global.filter')
								}}</label>
								<select class="form-control text-6" v-model="orderBy">
									<option value="id">
										{{ $t('global.order_of_addition') }}
									</option>
									<option value="startDate">
										{{ $t('resources.start_date') }}
									</option>
									<option value="endDate">
										{{ $t('resources.end_date') }}
									</option>
									<option value="type">{{ $t('global.type') }}</option>
									<option value="remuneration">
										{{ $t('resources.remuneration') }}
									</option>
									<option value="updatedAt">
										{{ $t('resources.update_at') }}
									</option>
								</select>

								<label for="" class="text-5 ms-2 me-2">{{
									$t('global.order')
								}}</label>
								<select class="form-control text-6" v-model="order">
									<option value="ASC">Asc</option>
									<option value="DESC">Desc</option>
								</select>
							</div>

							<div class="ms-auto hstack">
								<i
									class="uil uil-arrow-circle-left fs-5"
									type="button"
									@click="previous"
								></i>
								<small class="ms-3"
									>{{ Math.ceil(contracts.length / 25) > 0 ? page : 0 }} /
									{{ Math.ceil(contracts.length / 25) }}</small
								>
								<i
									class="uil uil-arrow-circle-right fs-5 ms-3"
									type="button"
									@click="next"
								></i>
								<div class="hstack ms-5">
									<i
										class="uil uil-list-ul fs-6 ms-1 p-1 px-3 rounded"
										:class="
											disposition == 'list'
												? 'bg-black- text-white-'
												: 'bg-white- text-black-'
										"
										@click="disposition = 'list'"
										type="button"
									></i>
								</div>
							</div>
						</div>

						<LoadingMain :state="loading" />

						<div v-if="!loading && contracts.length != 0">
							<div class="table-responsive mt-0">
								<table
									class="table contracts align-middle table-hover table-striped mb-0"
									style="width: 100% !important"
								>
									<thead class="bg-primary-">
										<tr class="text-6 text-white">
											<th></th>
											<th>{{ $t('resources.employee') }}</th>
											<th>{{ $t('global.type') }}</th>
											<th>{{ $t('resources.start_date') }}</th>
											<th>{{ $t('resources.end_date') }}</th>
											<th>{{ $t('resources.remuneration') }}</th>
											<th>{{ $t('resources.contract') }}</th>
										</tr>
									</thead>
									<tbody class="bg-alt-">
										<tr
											v-for="(contract, index) in contracts"
											:key="contract.id"
											v-show="show(index)"
											class="text-6 text-black"
										>
											<td>{{ index + 1 }}</td>
											<td class="text-nowrap">
												<router-link
													:to="{
														name: 'employee',
														params: {
															id: contract.employee.id,
															slug: contract.employee.slug,
														},
													}"
												>
													{{
														contract.employee.lastname +
														' ' +
														contract.employee.firstname
													}}
												</router-link>
											</td>
											<td>{{ contract.type }}</td>
											<td>{{ $filters.convertDate(contract.startDate) }}</td>
											<td>{{ $filters.convertDate(contract.endDate) }}</td>
											<td>
												{{ $filters.formatAmount(contract.remuneration) }}
											</td>
											<td class="text-center">
												<a
													@click="
														downloadFile(
															contract.file ? contract.file.url : '',
															'Contrat ' +
																contract.employee.lastname +
																' ' +
																contract.employee.firstname
														)
													"
												>
													<i
														class="uil uil-arrow-circle-down fs-6 text-white"
														type="button"
													></i>
												</a>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
						<div
							class="py-4 text-center text-black-"
							v-if="!loading && contracts.length == 0"
						>
							{{ $t('resources.no_contract_found') }}
						</div>
					</div>
				</div>

				<div class="col-xl-3">
					<div class="rounded bg-second- text-white text-4 text-center p-1">
						{{ $t('global.advice') }}
					</div>
					<div
						class="mt-1 bg-light-hight- text-alt- rounded text-7 text-center p-2"
						v-html="$t('resources.contract_advice_text')"
					></div>
				</div>
			</div>
		</Template>
	</div>
</template>

<script>
import Template from '@/components/Template.vue';
import Helper from '@/helpers/helper';
import LoadingMain from '@/components/LoadingMain.vue';
import InputFC from '@/components/InputFC.vue';
import SpinnerMe from '@/components/SpinnerMe.vue';
export default {
	name: 'contractIndex',
	components: { Template, LoadingMain, InputFC, SpinnerMe },
	data() {
		return {
			deleted: false,
			archived: false,
			loading: true,
			selectedList: [],
			allIsCheck: false,
			page: 1,
			perPage: 25,
			order: 'DESC',
			orderBy: 'id',
			disposition: 'list',
			contracts: [],
			employees: [],
			contract_types: [],
			errors: [],
			designation: '',
			check_form: false,
			message_contract_add: '',
			remuneration: '',
			contract_id: '',
			type: '',
			startDate: '',
			endDate: '',
			employee: '',
		};
	},
	methods: {
		downloadFile(l, n) {
			Helper.downloadFile(l, n, this);
		},
		check(id, all = false) {
			if (all) {
				if (this.selectedList.length !== this.contracts.length) {
					this.selectedList.length = 0;
					this.contracts.forEach((contract) => {
						this.selectedList.push({ id: contract.id });
					});
				} else {
					this.selectedList.length = 0;
				}
			} else {
				let found = false;
				this.selectedList.forEach((element) => {
					if (element.id == id) {
						found = true;
					}
				});
				if (found) {
					this.selectedList.filter((value, index) => {
						if (value.id == id) {
							this.selectedList.splice(index, 1);
						}
					});
				} else {
					this.selectedList.push({ id: id });
				}
			}
			if (this.selectedList.length === this.contracts.length) {
				this.allIsCheck = true;
			} else {
				this.allIsCheck = false;
			}
		},
		show(index) {
			return (
				index < this.page * this.perPage &&
				index + 1 > (this.page - 1) * this.perPage
			);
		},
		next() {
			if (this.page * this.perPage < this.contracts.length) {
				this.page++;
			}
		},
		previous() {
			if (this.page >= 2) {
				this.page--;
			}
		},
		isSelected(id) {
			let found = false;
			this.selectedList.forEach((element) => {
				if (element.id == id) {
					found = true;
				}
			});
			return found;
		},
		editInit(contract) {
			this.message_contract_add = '';
			this.contract_id = contract.id;
			this.designation = contract.designation;
			this.description = contract.description;
		},
		addContract() {
			this.message_contract_add = '';
			if (this.contract_id == '') {
				this.ok = true;
				Helper.loading('submit', 'spinner', true);
				let registerFormData = new FormData();
				registerFormData.append('employee', this.employee);
				registerFormData.append(
					'startDate',
					new Date(this.startDate).toISOString()
				);
				registerFormData.append(
					'endDate',
					this.endDate ? new Date(this.endDate).toISOString() : null
				);
				registerFormData.append('type', this.type);
				registerFormData.append('remuneration', this.remuneration);
				registerFormData.append(
					'file',
					document.getElementById('file_contract').files[0]
				);

				console.log('registerFormData', registerFormData);

				fetch(
					Helper.route('admin/contract/add'),
					Helper.requestOptions('POST', registerFormData)
				)
					.then((response) => {
						Helper.tokenChecker(response.status);
						if (response.status != 200) {
							this.ok = false;
						}
						return response.json();
					})
					.then((data) => {
						Helper.loading('submit', 'spinner', false);
						console.log(data);

						if (data.problems) {
							this.errors.employee = Helper.getError(data.problems, 'employee');
							this.errors.startDate = Helper.getError(
								data.problems,
								'startDate'
							);
							this.errors.endDate = Helper.getError(data.problems, 'endDate');
							this.errors.type = Helper.getError(data.problems, 'type');
							this.errors.remuneration = Helper.getError(
								data.problems,
								'remuneration'
							);
						} else {
							this.message_contract_add = data.message;
							this.contracts.unshift(data.data);
							this.employee = '';
							this.startDate = '';
							this.endDate = '';
							this.type = '';
							this.remuneration = '';
							Helper.notification('success', data.message);
						}
					})
					.catch((error) => {
						console.log(error);
						Helper.loading('submit', 'spinner', false);
					});
			} else {
				this.ok = true;
				Helper.loading('submit', 'spinner', true);
				let registerFormData = new FormData();
				registerFormData.append('id', this.contract_id);
				registerFormData.append('description', this.description);
				registerFormData.append('designation', this.designation);

				fetch(
					Helper.route('admin/contract/update'),
					Helper.requestOptions('PUT', registerFormData)
				)
					.then((response) => {
						Helper.tokenChecker(response.status);
						if (response.status != 200) {
							this.ok = false;
						}
						return response.json();
					})
					.then((data) => {
						Helper.loading('submit', 'spinner', false);
						console.log(data);

						if (data.problems) {
							this.errors.designation = Helper.getError(
								data.problems,
								'designation'
							);
							this.errors.description = Helper.getError(
								data.problems,
								'description'
							);
						} else {
							this.contracts = this.contracts.map((i) =>
								i.id !== this.contract_id ? i : data.data
							);
							this.message_contract_add = data.message;
						}
					})
					.catch((error) => {
						console.log(error);
						Helper.loading('submit', 'spinner', false);
					});
			}
		},
		loadList() {
			this.loading = true;
			fetch(
				Helper.route(
					'admin/contract/all/0?sortBy=' + this.orderBy + '&order=' + this.order
				),
				Helper.requestOptions()
			)
				.then((response) => {
					Helper.tokenChecker(response.status);
					return response.json();
				})
				.then((data) => {
					console.log(data);
					this.loading = false;
					if (data.data) {
						this.contracts = data.data;
					}
				})
				.catch((error) => {
					console.log(error);
				});
		},
		loademployee() {
			fetch(Helper.route('admin/employee/all'), Helper.requestOptions())
				.then((response) => {
					Helper.tokenChecker(response.status);
					return response.json();
				})
				.then((data) => {
					this.loading = false;
					if (data.data) {
						data.data.forEach((employee_item) => {
							this.employees.push({
								id: employee_item.id,
								name: employee_item.lastname + ' ' + employee_item.firstname,
							});
						});
					}
				})
				.catch((error) => {
					console.log(error);
				});
		},
	},
	watch: {
		orderBy: function () {
			this.loadList();
		},
		order: function () {
			this.loadList();
		},
	},
	created() {
		this.loadList();
		this.loademployee();
		this.contract_types = Helper.contract_types;
	},
};
</script>

<style>
.contracts th,
.contracts td {
	color: white !important;
}
</style>
