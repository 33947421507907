<template>
    <dl>
        <dt>{{ title }}</dt>
        <dd v-if="options.length == 0">{{ content }}</dd>
        <dd v-for="option in options" v-bind:value="option.id" v-show="option.id == content" :key="option.id">
            {{ option.name }}
        </dd>
    </dl>
</template>
<script>
export default {
    name: "TitleContent",
    props: {
        title: {
            default: ""
        },
        content: {
            default: ""
        },
        options: {
            default: []
        }
    }
}
</script>