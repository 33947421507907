<template>
	<div>
		<Template :title="$t('tools.edit_task')">
			<section class="p-5 py-4">
				<Back />

				<div
					class="bg-second- shadow-sm text-uppercase text-center rounded text-white px-4 p-2 mt-4"
				>
					{{ $t('tools.edit_task') }}
				</div>

				<div class="bg-light-hight- rounded mt-4 py-5">
					<form @submit.prevent="UpdateTask" class="col-lg-10 col-md-11 mx-auto">
						<div class="mb-4 text-primary-" v-if="message != ''">
							{{ message }}
						</div>
						<div class="mb-4 error" v-if="error_message != ''">
							{{ error_message }}
						</div>
						<div class="row">
							<InputFC
								:error="errors.name"
								:type="'text'"
								v-model="name"
								:placeholder="$t('tools.task_name')"
							/>
						</div>
						<hr />
						<div class="row">
							<InputFC
								:error="errors.code"
								:type="'text'"
								v-model="code"
								:placeholder="$t('tools.task_code')"
							/>
						</div>
						<div class="row">
							<!-- <div class="col-lg-6 mt-4">
                                <label for="">Sections</label>
                                <v-select multiple class="bg-white mt-1" v-model="section" :options="sections" :reduce="section => section.id" label="name"></v-select>
                                <span v-show="errors.section != ''" v-text="errors.section" class="error"></span>
                            </div> -->
							<div class="col-lg-12 mt-4">
								<label for="">{{ $t('tools.add_participants') }}</label>
								<v-select
									multiple
									class="bg-white mt-1"
									v-model="employee"
									:options="employees"
									:reduce="(employee) => employee.id"
									label="name"
								></v-select>
								<!-- <v-select multiple class="bg-white mt-1" v-model="employee" :options="employees" :reduce="employee => employee.id" label="name"></v-select> -->
								<span
									v-show="errors.employee != ''"
									v-text="errors.employee"
									class="error"
								></span>
							</div>
						</div>

						<div class="col-lg-12 mt-4">
							<label for="">{{ $t('tools.other_participants') }}</label>
							<v-select
								multiple
								taggable
								class="bg-white mt-1"
								v-model="emails"
								:options="email"
							></v-select>
							<!-- <v-select multiple class="bg-white mt-1" v-model="employee" :options="employees" :options="emails" :reduce="employee => employee.id" label="name"></v-select> -->
							<span
								v-show="errors.employee != ''"
								v-text="errors.employee"
								class="error"
							></span>
						</div>

						<hr />

						<fieldset class="p-2 border">
							<legend class="text-center text-4">
								{{ $t('tools.begining') }}
							</legend>
							<div class="row">
								<InputFC
									:error="errors.startDate"
									:type="'date'"
									class="col"
									v-model="startDate"
									:placeholder="$t('global.date')"
								/>
								<InputFC
									:error="errors.startTime"
									:type="'time'"
									class="col"
									v-model="startTime"
									:placeholder="$t('global.time')"
								/>
							</div>
						</fieldset>
						<hr />

						<fieldset class="p-2 border">
							<legend class="text-center text-4">Fin</legend>
							<div class="row">
								<InputFC
									:error="errors.endDate"
									:type="'date'"
									class="col"
									v-model="endDate"
									:placeholder="$t('global.date')"
								/>
								<InputFC
									:error="errors.endTime"
									:type="'time'"
									class="col"
									v-model="endTime"
									:placeholder="$t('global.time')"
								/>
							</div>
						</fieldset>

						<div class="row mt-4">
							<InputFC
								:error="errors.nature"
								:options="natures"
								:type="'select'"
								v-model="nature"
								:placeholder="$t('tools.nature')"
							/>
						</div>
						<div class="row mt-4">
							<InputFC
								:error="errors.linkOrPlace"
								:type="'text'"
								v-model="linkOrPlace"
								:placeholder="$t('tools.place')"
							/>
						</div>

						<InputFC
							:error="errors.description"
							:type="'textarea'"
							class="mt-4"
							v-model="description"
							:placeholder="$t('tools.description')"
						/>

						<div class="hstack">
							<div class="">
								<SpinnerMe :elementId="'spinner'" />
								<button id="submit" class="button px-4 py-1 mt-4">
									{{ $t('global.update') }}
								</button>
							</div>
							<div class="ms-3">
								<!--@click="$router.go(-1)"-->
								<button
									class="button-default bg-light- text-color- fw-500 shadow-sm px-4 py-1 mt-4"
									@click="$router.go(-1)"
								>
									{{ $t('global.cancel') }}
								</button>
							</div>
						</div>
					</form>
				</div>
			</section>
		</Template>
	</div>
</template>
<script>
import Template from '@/components/Template.vue';
import Back from '@/components/Back.vue';
import InputFC from '@/components/InputFC.vue';
import SpinnerMe from '@/components/SpinnerMe.vue';
import Helper from '@/helpers/helper';

export default {
	name: 'CandidatesPage',
	components: { Template, Back, InputFC, SpinnerMe },
	data() {
		return {
			errors: [],
			error_message: '',
			message: '',
			ok: false,
			check_form: false,
			
			id: '',
			name: '',
			code: '',
			nature: '',
			startDate: '',
			startTime: '',
			endDate: '',
			endTime: '',
			linkOrPlace: '',
			description: '',
			employees: [],
			employee: [],
			emails: [],
			email: [],
			natures: [
				{
					id: 1,
					name: this.$t('tools.online'),
				},
				{
					id: 0,
					name: this.$t('tools.in_person'),
				},
			],
			task: null,
		};
	},
	methods: {
		formatDate(dateString) {
			const date = new Date(dateString);
			return date.toISOString().split('T')[0];
		},
		UpdateTask() {
			this.check_form = true;
			console.log(this.emails);
			console.log(this.email);
			if (this.beforeUpdate()) {
				this.ok = true;
				this.error_message = '';
				this.message = '';
				Helper.loading('submit', 'spinner', true);

				const body = {
					id: this.id,
					name: this.name,
					code: this.code,
					nature: this.nature,
					startDate: this.startDate,
					startTime: this.startTime,
					endDate: this.endDate,
					endTime: this.endTime,
					linkOrPlace: this.linkOrPlace,
					description: this.description,
					employees: this.employee,
					emails: this.emails,
				};

				fetch(
					Helper.route('admin/task/update'),
					Helper.requestOptionsJSON('POST', body)
				)
					.then((response) => {
						Helper.tokenChecker(response.status);
						if (response.status != 200) {
							this.ok = false;
						}
						return response.json();
					})
					.then((data) => {
						this.check_form = false;
						Helper.loading('submit', 'spinner', false);

						if (data.problems) {
							this.errors.name = Helper.getError(data.problems, 'name');
							this.errors.code = Helper.getError(data.problems, 'code');
							this.errors.nature = Helper.getError(data.problems, 'nature');
							this.errors.startDate = Helper.getError(
								data.problems,
								'startDate'
							);
							this.errors.startTime = Helper.getError(
								data.problems,
								'startTime'
							);
							this.errors.endDate = Helper.getError(data.problems, 'endDate');
							this.errors.endTime = Helper.getError(data.problems, 'endTime');
							this.errors.linkOrPlace = Helper.getError(
								data.problems,
								'linkOrPlace'
							);
							this.errors.description = Helper.getError(
								data.problems,
								'description'
							);
							this.errors.employee = Helper.getError(
								data.problems,
								'employees'
							);
							this.error_message = Helper.getError(data.problems, 'date');
							Helper.notification('error', this.error_message);
						} else {
							if (!this.ok) {
								this.error_message = data.message;
								Helper.notification('error', this.error_message);
							} else {
								if (data.status) {
									this.message = data.message;
									Helper.notification('success', this.message);
									this.name = '';
									this.code = '';
									this.nature = '';
									this.startDate = '';
									this.startTime = '';
									this.endDate = '';
									this.endTime = '';
									this.linkOrPlace = '';
									this.description = '';
									this.employee = [];
								}
							}
						}
					})
					.catch((error) => {
						console.log(error);
						Helper.loading('submit', 'spinner', false);
					});
			}
		},
		beforeUpdate() {
			if (Helper.required(this.name) === '') {
				delete this.errors.name;
			} else {
				this.errors.name = Helper.required(this.name);
			}
			if (Helper.required(this.code) === '') {
				delete this.errors.code;
			} else {
				this.errors.code = Helper.required(this.code);
			}
			if (Helper.required(this.nature) === '') {
				delete this.errors.nature;
			} else {
				this.errors.nature = Helper.required(this.nature);
			}
			if (Helper.required(this.startDate) === '') {
				delete this.errors.startDate;
			} else {
				this.errors.startDate = Helper.required(this.startDate);
			}
			if (Helper.required(this.startTime) === '') {
				delete this.errors.startTime;
			} else {
				this.errors.startTime = Helper.required(this.startTime);
			}
			if (Helper.required(this.endDate) === '') {
				delete this.errors.endDate;
			} else {
				this.errors.endDate = Helper.required(this.endDate);
			}
			if (Helper.required(this.endTime) === '') {
				delete this.errors.endTime;
			} else {
				this.errors.endTime = Helper.required(this.endTime);
			}
			if (Helper.required(this.linkOrPlace) === '') {
				delete this.errors.linkOrPlace;
			} else {
				this.errors.linkOrPlace = Helper.required(this.linkOrPlace);
			}
			if (Helper.required(this.description) === '') {
				delete this.errors.description;
			} else {
				this.errors.description = Helper.required(this.description);
			}
			/* if (Helper.required(this.section) === "") {
                delete this.errors.section
            } else {
                this.errors.section = Helper.required(this.section)
            } */
			if (Helper.required(this.employee) === '') {
				delete this.errors.employee;
			} else {
				this.errors.employee = Helper.required(this.employee);
			}
			return Helper.objectNumberKey(this.errors) == 0 ? true : false;
		},
		getTask() {
			this.loading = true;
			fetch(Helper.route('admin/task/' + this.id), Helper.requestOptions())
				.then((response) => {
					Helper.tokenChecker(response.status);
					if (response.status != 200) {
						this.ok = false;
					}
					return response.json();
				})
				.then((data) => {
					this.loading = false;
					if (data.data) {
						// get all data in the input sections of form
						this.task = data.data;
						console.log(this.task);

						this.name = this.task.name;
						this.code = this.task.code;
						this.nature = this.task.nature;
						this.linkOrPlace = this.task.linkOrPlace;
						this.description = this.task.description;
						this.startDate = this.$filters.convertDateToForm(
							this.task.startDate
						);
						this.startTime = this.$filters
							.convertDateToForm(this.task.startDate, true)
							.split(' ')[1];
						this.endDate = this.$filters.convertDateToForm(
							this.task.endDate
						);
						this.endTime = this.$filters
							.convertDateToForm(this.task.endDate, true)
							.split(' ')[1];
						this.task.employees.forEach((emp) => {
							this.employee.push({
								id: emp.id,
								name: emp.lastname + ' ' + emp.firstname,
								email: emp.emailAddress,
							});
						});
						this.task.emails.forEach((emp) => {
							this.emails.push(emp);
						});
					}
				})
				.catch((error) => {
					this.loading = false;
					console.log(error);
				});
		},
	},
	created() {
		this.id = this.$route.params.id;
		// console.log(this.id);
		this.getTask();
		fetch(Helper.route('admin/employee/all'), Helper.requestOptions())
			.then((response) => response.json())
			.then((data) => {
				if (data.data) {
					// console.log(data.data);

					data.data.forEach((employee) => {
						this.employees.push({
							id: employee.id,
							name: employee.lastname + ' ' + employee.firstname,
							email: employee.emailAddress,
						});
					});
				}
			})
			.catch((error) => {
				console.log(error);
			});
	},
	watch: {
		name: function () {
			if (this.check_form) {
				if (Helper.required(this.name) === '') {
					delete this.errors.name;
				} else {
					this.errors.name = Helper.required(this.name);
				}
			}
		},
		code: function () {
			if (this.check_form) {
				if (Helper.required(this.code) === '') {
					delete this.errors.code;
				} else {
					this.errors.code = Helper.required(this.code);
				}
			}
		},
		startDate: function () {
			if (this.check_form) {
				if (Helper.required(this.startDate) === '') {
					delete this.errors.startDate;
				} else {
					this.errors.startDate = Helper.required(this.startDate);
				}
			}
		},
		startTime: function () {
			if (this.check_form) {
				if (Helper.required(this.startTime) === '') {
					delete this.errors.startTime;
				} else {
					this.errors.startTime = Helper.required(this.startTime);
				}
			}
		},
		endDate: function () {
			if (this.check_form) {
				if (Helper.required(this.endDate) === '') {
					delete this.errors.endDate;
				} else {
					this.errors.endDate = Helper.required(this.endDate);
				}
			}
		},
		endTime: function () {
			if (this.check_form) {
				if (Helper.required(this.endTime) === '') {
					delete this.errors.endTime;
				} else {
					this.errors.endTime = Helper.required(this.endTime);
				}
			}
		},
		nature: function () {
			if (this.check_form) {
				if (Helper.required(this.nature) === '') {
					delete this.errors.nature;
				} else {
					this.errors.nature = Helper.required(this.nature);
				}
			}
		},
		linkOrPlace: function () {
			if (this.check_form) {
				if (Helper.required(this.linkOrPlace) === '') {
					delete this.errors.linkOrPlace;
				} else {
					this.errors.linkOrPlace = Helper.required(this.linkOrPlace);
				}
			}
		},
		employee: function () {
			if (this.check_form) {
				if (Helper.required(this.employee) === '') {
					delete this.errors.employee;
				} else {
					this.errors.employee = Helper.required(this.employee);
				}
			}
		},
		description: function () {
			if (this.check_form) {
				if (Helper.required(this.description) === '') {
					delete this.errors.description;
				} else {
					this.errors.description = Helper.required(this.description);
				}
			}
		},
		/* section: function() {
            if(this.check_form) {
                if (Helper.required(this.section) === "") {
                    delete this.errors.section
                } else {
                    this.errors.section = Helper.required(this.section)
                }
            }
        }, */
	},
};
</script>
<style></style>
