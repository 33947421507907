<template>
	<div>
		<Template :title="$t('programs.buying.regist_invoice')" sidebarMenu="program-managements">
			<section class="p-5 py-4">
				<Back />

				<div class="row">
					<div class="col-xl-9">
						<div
							class="bg-second- fw-1000 shadow-sm text-uppercase text-center rounded-pill text-5 text-white px-4 py-1 mt-3"
						>
							{{$t('programs.buying.regist_invoice')}}
						</div>

						<div class="bg-light-hight- rounded py-5 mt-4">
							<form
								@submit.prevent="buyingNew"
								class="col-lg-10 col-md-11 mx-auto"
							>
								<div class="row">
									<div class="col-3">N° {{$t('programs.buying.of_invoice')}}</div>
									<div class="col-9">
										<InputFC
											:error="errors.number"
											:type="'text'"
											class="mb-4"
											v-model="number"
											:placeholder="''"
										
											
										/>
									</div>
								</div>
								<div class="row">
									<div class="col-3">{{$t('programs.buying.supplier')}}</div>
									<div class="col-9">
										<InputFC
											:error="errors.supplier"
											:type="'text'"
											class="mb-4"
											v-model="supplier"
											:placeholder="''"
										/>
									</div>
								</div>

								<hr />
								<div class="row">
									<div class="col-3">{{$t('programs.amount_excluding_tax')}}</div>
									<div class="col-9">
										<InputFC
											:error="errors.amountHT"
											:type="'number'"
											min="0"
											class="mb-4"
											v-model="amountHT"
											:placeholder="''"
										/>
									</div>
								</div>

								<div class="row">
									<div class="col-3">TVA(si facturé)</div>
									<div class="col-9">
										<InputFC
											:error="errors.tva"
											:type="'number'"
											min="0"
											class="mb-4"
											v-model="tva"
											:placeholder="''"
										/>
									</div>
								</div>

								<div class="row">
									<div class="col-3">{{$t('programs.amount_including_tax')}}</div>
									<div class="col-9">
										<InputFC
											:error="errors.amountTTC"
											:type="'number'"
											min="0"
											class="mb-4"
											v-model="amount_TTC"
											:placeholder="''"
											:readonly=true
											
										/>
									</div>
								</div>

								<hr />

								<div class="row">
									<div class="col-3">{{$t('global.date')}}</div>
									<div class="col-9">
										<InputFC
											:error="errors.date"
											:type="'date'"
											class="mb-4"
											v-model="date"
											:placeholder="''"
										/>
									</div>
								</div>

								<div class="row">
									<div class="col-3">{{$t('programs.buying.payment_method')}}</div>
									<div class="col-9">
										<InputFC
											:error="errors.paymentMean"
											:type="'select'"
											class="mb-4"
											:options="payment_methods"
											v-model="paymentMean"
											:placeholder="''"
										/>
									</div>
								</div>

								<hr />
								<div class="row">
									<div class="col-3">{{$t('programs.buying.invoice_file')}}</div>
									<div class="col-9">
										<input
											type="file"
											required
											id="buying_document"
											class="form-control"
										/>
									</div>
								</div>

								<div class="hstack">
									<div class="">
										<SpinnerMe :elementId="'spinner'" />
										<button id="submit" class="button px-4 py-1 mt-4">
											{{ $t('global.send') }}
										</button>
									</div>
									<div class="ms-3">
										<button
											class="button-default bg-light- text-color- fw-500 shadow-sm px-4 py-1 mt-4"
											@click="$router.go(-1)"
										>
											{{ $t('global.cancel') }}
										</button>
									</div>
								</div>
							</form>
						</div>
					</div>

					<div class="col-xl-3 mt-3">
						<div class="rounded bg-second- text-white text-4 text-center p-1">
							{{$t('global.advice')}}
						</div>
						<div
							class="mt-1 bg-light-hight- text-alt- rounded text-7 text-center p-2"
							v-html="$t('programs.buying.create_advice_text')"
						>
						
						</div>
						<router-link to="/documents/create"
							><button class="button bg-alt- btn-sm w-100 mt-1 p-1">
								{{$t('global.add_now')}}
							</button>
						</router-link>
					</div>
				</div>
			</section>
		</Template>
	</div>
</template>
<script>
import Template from '@/components/Template.vue';
import Back from '@/components/Back.vue';
import SpinnerMe from '@/components/SpinnerMe.vue';
import Helper from '@/helpers/helper';
import InputFC from '@/components/InputFC.vue';

export default {
	name: 'BuyingCreate',
	components: { Template, Back, InputFC, SpinnerMe },
	data() {
		return {
			message: '',
			number: '',
			supplier: '',
			amountHT: 0,
			tva: '',
			date: '',
			amountTTC: '',
			paymentMean: '',
			payment_methods: [],
			errors: [],
		};
	},
	methods: {
		buyingNew() {
			console.log(this.amountTTC)
			this.ok = true;
			this.error_message = '';
			Helper.loading('submit', 'spinner', true);

			let registerFormData = new FormData();
			registerFormData.append('number', this.number);
			registerFormData.append('supplier', this.supplier);
			registerFormData.append('amountHT', this.amountHT);
			registerFormData.append('tva', this.tva);
			registerFormData.append('amountTTC', this.amount_TTC);
			registerFormData.append('paymentMean', this.paymentMean);
			registerFormData.append('date', this.date);
			registerFormData.append(
				'document',
				document.getElementById('buying_document').files[0]
			);

			fetch(
				Helper.route('admin/buying/add'),
				Helper.requestOptions('POST', registerFormData)
			)
				.then((response) => {
					Helper.tokenChecker(response.status);
					return response.json();
				})
				.then((data) => {
					console.log(data);
					Helper.loading('submit', 'spinner', false);

					if (data.problems) {
						this.errors.number = Helper.getError(data.problems, 'number');
						this.errors.supplier = Helper.getError(data.problems, 'supplier');
						this.errors.amountHT = Helper.getError(data.problems, 'amountHT');
						this.errors.tva = Helper.getError(data.problems, 'tva');
						this.errors.amountTTC = Helper.getError(data.problems, 'amountTTC');
						this.errors.paymentMean = Helper.getError(
							data.problems,
							'paymentMean'
						);
						this.errors.date = Helper.getError(data.problems, 'date');
					} else {
						if (!this.ok) {
							this.error_message = data.message;
						} else {
							if (data.status) {
								this.$router.go(-1);
							}
						}
					}
				})
				.catch((error) => {
					console.log(error);
					Helper.loading('submit', 'spinner', false);
				});
		},
	},
	created() {
		this.url = Helper.route('admin/document/add');
		this.payment_methods = Helper.payment_methods;
	},

	computed: {
		amount_TTC() {
			return parseInt(this.amountHT) + parseInt(this.tva);
		},
	},
	
};
</script>
<style></style>
