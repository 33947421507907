<template>
	<div>
		<Template :title="'null'">
			<div class="bg-light-hight- hstack py-3 ps-2 pb-3">
				<router-link to="/admin" class="bg-light-hight- ms-4 text-6 top-item">{{
					$t('topbar.co_admin')
				}}</router-link>
				<router-link
					to="/admin/invitation"
					class="bg-light-hight- ms-4 text-6 top-item"
					>{{ $t('admin.invitations') }}</router-link
				>
			</div>

			<div class="row m-0 px-4">
				<div class="col-lg-9">
					<div class="hstack mt-4">
						<router-link
							to="/admin/create"
							class="bg-second- rounded ms-auto px-3 p-1"
						>
							<i class="uil uil-plus text-white"></i>
							<span class="ms-2 text-6 text-white">{{
								$t('admin.invite_to_admin')
							}}</span>
						</router-link>
					</div>
					<div class="table table-responsive mt-3">
						<table
							class="table align-middle table-hover table-striped mb-0"
							style="width: 100% !important"
						>
							<thead class="bg-primary-">
								<tr class="text-5 text-white">
									<th class="fw-800 text-white-">Date d'Inv.</th>
									<th class="fw-800 text-white-">
										{{ $t('global.lastname') }}
									</th>
									<th class="fw-800 text-white-">
										{{ $t('global.firstname') }}
									</th>
									<th class="fw-800 text-white-">{{ $t('global.email') }}</th>
									<th class="fw-800 text-white- text-center">
										{{ $t('global.status') }}
									</th>
									<th class="fw-800 text-white-">{{ 'Expire le' }}</th>
									<th class="fw-800 text-white-">{{$t('global.action')}}</th>
								</tr>
							</thead>
							<tbody v-if="!loading && admins.length != 0">
								<tr
									class="text-5 text-white"
									v-for="admin in admins"
									:key="admin.id"
								>
									<td>
										{{
											$filters.convertDate(new Date(admin.createdAt).toJSON())
										}}
									</td>
									<td>{{ admin.lastname }}</td>
									<td>{{ admin.firstname }}</td>
									<td>{{ admin.emailAddress }}</td>
									<td class="text-center">
										<kbd
											v-if="admin.status == 'validated'"
											class="bg-green- rounded-8"
										>
											{{ 'Acceptée' }}
										</kbd>
										<kbd
											v-if="admin.status == 'pending'"
											class="bg-warning rounded-8"
										>
											{{ $t('Encours') }}
										</kbd>
										<kbd
											v-if="admin.status == 'expired'"
											class="bg-gray rounded-8"
										>
											{{ 'Expirée' }}
										</kbd>
										<kbd
											v-if="admin.status == 'deleted'"
											class="bg-red- rounded-8"
										>
											{{ 'Annulée' }}
										</kbd>
									</td>
									<td>
										{{
											$filters.convertDate(new Date(admin.expiresAt).toJSON())
										}}
									</td>
									<td>
										<button
											v-if="admin.status == 'pending'"
											@click="delete_invitation(admin)"
											class="button bg-red-"
										>
											{{ $t('global.cancel') }}
										</button>
									</td>
								</tr>
							</tbody>
							<tbody v-if="!loading && admins.length == 0">
								<tr>
									<td colspan="5" class="text-center">
										{{ $t('global.none_element') }}
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="col-xl-3 mt-4">
					<div class="rounded bg-second- text-white text-4 text-center p-1">
						{{ $t('global.advice') }}
					</div>
					<div
						class="mt-1 bg-light-hight- text-alt- rounded text-7 text-center p-2"
						v-html="$t('global.advice_action')"
					></div>
				</div>
			</div>
		</Template>
	</div>
</template>

<script>
import Template from '@/components/Template.vue';
import Helper from '@/helpers/helper';
export default {
	// eslint-disable-next-line vue/multi-word-component-names
	name: 'Invitation',
	components: { Template },
	data() {
		return {
			loading: true,
			admins: [],
		};
	},
	methods: {
		loadList() {
			this.$isLoading(true);
			this.loading = true;
			fetch(
				Helper.route('admin/management/coadmin/invitations'),
				Helper.requestOptions()
			)
				.then((response) => {
					Helper.tokenChecker(response.status);
					if (response.status != 200) {
						this.ok = false;
					}
					return response.json();
				})
				.then((data) => {
					this.loading = false;
					this.$isLoading(false);
					if (data.data) {
						this.admins = data.data;
					}
					console.log(data);
				})
				.catch((error) => {
					this.loading = false;
					this.$isLoading(false);
					console.log(error);
				});
		},
		delete_invitation(admin) {
			this.$isLoading(true);
			let formData = new FormData();
			formData.append('id', admin.id);

			fetch(
				Helper.route('admin/management/coadmin/invitation/delete'),
				Helper.requestOptions('DELETE', formData)
			)
				.then((response) => {
					Helper.tokenChecker(response.status);
					if (response.status != 200) {
						this.ok = false;
					}
					return response.json();
				})
				.then((data) => {
					console.log(data);
					this.$isLoading(false);
					// this.admins = this.admins.filter((i) => i.id !== admin.id);
				})
				.catch((error) => {
					this.$isLoading(false);
					console.log(error);
				});
		},
	},
	created() {
		this.loadList();
	},
};
</script>
