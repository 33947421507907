<template>
    <div>
        <Template>
            
        </Template>
    </div>
</template>
<script>
import Template from '@/components/Template.vue';
export default {
    name: "HomePage",
    components: { Template }
}
</script>
<style>
    
</style>