<template>
	<div>
		<Template title="Assistance">
			<section class="row m-0 px-4 pb-3 g-4">
				<div class="col-lg-9">
					<div class="hstack">
						<router-link
							to="/ticket/create"
							class="bg-second- rounded ms-auto p-1"
						>
							<i class="uil uil-plus text-white"></i>
							<span class="ms-2 text-6 text-white">{{
								$t('assistance.create_new_ticket')
							}}</span>
						</router-link>
					</div>

					<div class="row m-0 mt-2">
						<p
							class="text-5 mb-0"
							v-if="showMessage > 0"
							v-html="$t('assistance.message')"
						></p>
						<p class="text-5 mb-0" v-if="showMessage > 0">
							<span
								class="text-red-"
								v-html="$t('assistance.message_not_send')"
							></span>
						</p>
					</div>

					<div class="">
						<div class="table-responsive-md mt-2">
							<table
								class="table table align-middle table-striped bg-white-"
								style="width: 100% !important"
							>
								<thead>
									<tr class="bg-primary- text-6">
										<th class="text-white-">REF</th>
										<th class="text-white-">{{ $t('global.date') }}</th>
										<th class="text-white-">{{ $t('global.object') }}</th>
										<th class="text-white-">{{ $t('assistance.subject') }}</th>
										<th class="text-center text-white-">
											{{ $t('global.response_s') }}
										</th>
										<th class="text-center text-white-">
											{{ $t('global.status') }}
										</th>
									</tr>
								</thead>
								<tbody class="border-top-0">
									<tr v-for="ticket in tickets" :key="ticket.id">
										<td>#{{ ticket.reference }}</td>
										<td>{{ $filters.convertDateLong(ticket.createdAt) }}</td>
										<td>{{ ticket.object }}</td>
										<td class="text-truncate">{{ ticket.subject }}</td>
										<td class="text-center">{{ ticket.responses.length }}</td>
										<td class="text-center">
											<router-link
												:to="{ name: 'ticket', params: { id: ticket.id } }"
											>
												<div
													:class="
														ticket.status === 'pending'
															? 'bg-green-'
															: 'bg-alt-'
													"
													class="rounded shadow-1 px-2 py-1 text-white- text-5"
												>
													{{
														ticket.status === 'pending'
															? $t('global.in_progress')
															: $t('global.resolved')
													}}
												</div>
											</router-link>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<div class="col-xl-3">
					<div class="rounded bg-second- text-white text-4 text-center p-1">
						{{ $t('global.advice') }}
					</div>
					<div
						class="mt-1 bg-light-hight- text-alt- rounded text-7 text-center p-2"
						v-html="$t('others.assistance_advice_text')"
					></div>
				</div>
			</section>
		</Template>
	</div>
</template>

<style></style>

<script>
import Template from '@/components/Template.vue';
import Helper from '@/helpers/helper';
import moment from 'moment';
export default {
	name: 'AssistancePage',
	components: {
		Template,
	},
	data() {
		return {
			step: 1,
			showMessage: 0,
			tickets: [],
		};
	},
	methods: {
		loadList() {
			this.$isLoading(true);
			fetch(Helper.route('admin/ticket/all'), Helper.requestOptions())
				.then((response) => {
					Helper.tokenChecker(response.status);
					return response.json();
				})
				.then((data) => {
					this.$isLoading(false);
					console.log(data);
					if (data.data) {
						this.tickets = data.data;
						this.showMessage = this.tickets.every((t) => t.status != 'pending')
							? 0
							: 18;
					}
				})
				.catch((error) => {
					this.$isLoading(false);
					console.log(error);
				});
		},
		convertDate: function (date) {
			return moment.utc(date).format('DD/MM/YYYY');
		},
	},
	created() {
		this.loadList();
	},
};
</script>
