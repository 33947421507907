<template>
	<div>
		<Template title="null" sidebarMenu="offers">
			<section class="row px-4 m-0 g-4 mt-1">
				<Back />

				<div class="col-lg-9">
					<div
						class="bg-second- shadow-sm text-uppercase text-center rounded-pill text-5 text-white px-4 p-1"
					>
						{{$t('candidate.offers.update_offer')}}
					</div>
					<LoadingMain :state="loading" />
					<div
						v-if="offer == null && !loading"
						class="p-5 text-red- text-5 text-center"
					>
						{{$t('candidate.offers.offer_no_found')}}
					</div>
					<div class="row mt-1 g-4" v-if="offer !== null">
						<div class="bg-light-hight- rounded py-5 pt-3">
							<form
								@submit.prevent="offerUpdate"
								class="col-lg-11 col-md-11 mx-auto"
							>
								<div class="row">
									<div class="col-lg-6 mt-4">
										<InputFC
											:error="errors.post"
											:type="'text'"
											v-model="post"
											:placeholder="$t('candidate.offers.profession')"
										/>
									</div>
									<div class="col-lg-6 mt-4">
										<InputFC
											:error="errors.location"
											:type="'text'"
											v-model="location"
											:placeholder="$t('candidate.offers.work_location')"
										/>
									</div>
									<div class="col-lg-6 mt-4">
										<InputFC
											:error="errors.contact"
											:type="'tel'"
											v-model="contact"
											:placeholder="$t('candidate.offers.contact')"
										/>
									</div>
									<div class="col-lg-6 mt-4">
										<InputFC
											:error="errors.email"
											:type="'email'"
											v-model="email"
											:placeholder="$t('global.email')"
										/>
									</div>
								</div>
								<div class="row">
									<div class="col-lg-6 mt-4">
										<InputFC
											:error="errors.profile"
											:type="'text'"
											v-model="profile"
											:placeholder="$t('global.profile_search')"
										/>
									</div>
									<div class="col-lg-6 mt-4">
										<InputFC
											:error="errors.contract"
											:options="contract_types"
											:type="'select'"
											v-model="contract"
											:placeholder="$t('candidate.offers.contract_type')"
										/>
									</div>
								</div>

								<div class="row">
									<div class="col-lg-6 mt-4">
										<InputFC
											:error="errors.placeNumber"
											:type="'number'"
											v-model="placeNumber"
											:placeholder="$t('candidate.offers.available_place')"
										/>
									</div>
									<div class="col-lg-6 mt-4">
										<InputFC
											:error="errors.closingDate"
											:type="'datetime-local'"
											v-model="closingDate"
											:placeholder="$t('candidate.offers.closing_date')"
										/>
									</div>
								</div>

								<div class="row">
									<div class="col-lg-12 mt-4">
										<label for="">{{$t('candidate.offers.task_to_complete')}}</label>
										<QuillEditor
											v-model:content="tasks"
											toolbar="essential"
											contentType="html"
											class="bg-white-"
											theme="snow"
										/>
										<span
											v-show="errors.tasks != ''"
											v-text="errors.tasks"
											class="error"
										></span>
									</div>
									<div class="col-lg-12 mt-5">
										<label for="" class="mt-5">{{$t('candidate.offers.required_skills')}}</label>
										<QuillEditor
											v-model:content="skills"
											toolbar="essential"
											contentType="html"
											class="bg-white-"
											theme="snow"
										/>
										<span
											v-show="errors.skills != ''"
											v-text="errors.skills"
											class="error"
										></span>
									</div>
								</div>

								<div class="row" style="margin-top: 120px">
									<div class="col-lg-12 mt-3">
										<label for="">{{$t('candidate.offers.job_summary')}}</label>
										<QuillEditor
											v-model:content="summary"
											toolbar="essential"
											contentType="html"
											class="bg-white-"
											theme="snow"
										/>
										<span
											v-show="errors.summary != ''"
											v-text="errors.summary"
											class="error"
										></span>
									</div>
									<div class="col-lg-12 mt-5">
										<label for="" class="mt-5">{{$t('candidate.offers.required_docs')}}</label>
										<QuillEditor
											v-model:content="requiredDocument"
											toolbar="essential"
											contentType="html"
											class="bg-white-"
											theme="snow"
										/>
										<span
											v-show="errors.requiredDocument != ''"
											v-text="errors.requiredDocument"
											class="error"
										></span>
									</div>
									<div class="col-lg-12" style="margin-top: 150px">
										<label for="">{{$t('candidate.offers.abilities')}}</label>
										<QuillEditor
											v-model:content="aptitudes"
											toolbar="essential"
											contentType="html"
											class="bg-white-"
											theme="snow"
										/>
										<span
											v-show="errors.aptitudes != ''"
											v-text="errors.aptitudes"
											class="error"
										></span>
									</div>
								</div>

								<div class="hstack" style="margin-top: 70px">
									<div class="">
										<SpinnerMe :elementId="'spinner'" />
										<button id="submit" class="button px-4 py-1 mt-4">
											{{ $t('global.send') }}
										</button>
									</div>
									<div class="ms-3">
										<button
											class="button-default bg-light- text-color- fw-500 shadow-sm px-4 py-1 mt-4"
											@click.prevent="$router.go(-1)"
										>
											{{ $t('global.cancel') }}
										</button>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</section>
		</Template>
	</div>
</template>
<script>
import Template from '@/components/Template.vue';
import Back from '@/components/Back.vue';
import Helper from '@/helpers/helper';
import LoadingMain from '@/components/LoadingMain.vue';
import InputFC from '@/components/InputFC.vue';
import SpinnerMe from '@/components/SpinnerMe.vue';
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
export default {
	name: 'EditOfferPage',
	components: { Template, Back, LoadingMain, SpinnerMe, QuillEditor, InputFC },
	data() {
		return {
			id: '',
			loading: true,
			offer: null,
			errors: [],
			post: '',
			profile: '',
			location: '',
			contract: '',
			placeNumber: '',
			closingDate: '',
			tasks: '',
			summary: '',
			skills: '',
			aptitudes: '',
			requiredDocument: '',
			contact: '',
			email: '',
		};
	},
	methods: {
		offerUpdate() {
			console.log('------------------------------------');
			console.log(this.closingDate);
			console.log('------------------------------------');
			this.ok = true;
			this.error_message = '';
			Helper.loading('submit', 'spinner', true);
			let registerFormData = new FormData();
			registerFormData.append('id', this.id);
			registerFormData.append('post', this.post);
			registerFormData.append('profile', this.profile);
			registerFormData.append('location', this.location);
			registerFormData.append('contract', this.contract);
			registerFormData.append('placeNumber', this.placeNumber);
			registerFormData.append(
				'closingDate',
				new Date(this.closingDate).toISOString()
			);
			registerFormData.append('tasks', this.tasks);
			registerFormData.append('summary', this.summary);
			registerFormData.append('skills', this.skills);
			registerFormData.append('aptitudes', this.aptitudes);
			registerFormData.append('requiredDocument', this.requiredDocument);
			registerFormData.append('contact', this.contact);
			registerFormData.append('email', this.email);

			fetch(
				Helper.route('admin/joboffer/update'),
				Helper.requestOptions('PUT', registerFormData)
			)
				.then((response) => {
					Helper.tokenChecker(response.status);
					if (response.status != 200) {
						this.ok = false;
					}
					return response.json();
				})
				.then((data) => {
					console.log(data);
					Helper.loading('submit', 'spinner', false);

					if (data.problems) {
						console.log(data.problems);
						this.errors.post = Helper.getError(data.problems, 'post');
						this.errors.closingDate = Helper.getError(data.problems, 'date');
						this.errors.contact = Helper.getError(data.problems, 'contact');
						this.errors.contract = Helper.getError(data.problems, 'contract');
						this.errors.placeNumber = Helper.getError(
							data.problems,
							'placeNumber'
						);
						this.errors.skills = Helper.getError(data.problems, 'skills');
						this.errors.aptitudes = Helper.getError(data.problems, 'aptitudes');
						this.errors.requiredDocument = Helper.getError(
							data.problems,
							'requiredDocument'
						);
						this.errors.summary = Helper.getError(data.problems, 'summary');
						this.errors.profile = Helper.getError(data.problems, 'profile');
						this.errors.email = Helper.getError(data.problems, 'email');
					} else {
						if (!this.ok) {
							this.error_message = data.message;
							Helper.notification('error', data.message);
						} else {
							if (data.status) {
								Helper.notification('success', data.message);
								this.message = data.message;
								this.$router.go(-1);
							}
						}
					}
				})
				.catch((error) => {
					console.log(error);
					Helper.loading('submit', 'spinner', false);
				});
		},
		getOffer() {
			this.loading = true;
			fetch(Helper.route('admin/joboffer/' + this.id), Helper.requestOptions())
				.then((response) => {
					Helper.tokenChecker(response.status);
					return response.json();
				})
				.then((data) => {
					this.loading = false;
					if (data.data) {
						this.offer = data.data;
						this.post = this.offer.post;
						this.profile = this.offer.profile;
						this.location = this.offer.location;
						this.contract = this.offer.contract;
						this.placeNumber = this.offer.placeNumber;
						this.closingDate = this.$filters.convertDateToForm(
							this.offer.closingDate,
							true
						);
						this.tasks = this.offer.tasks;
						this.summary = this.offer.summary;
						this.skills = this.offer.skills;
						this.aptitudes = this.offer.aptitudes;
						this.requiredDocument = this.offer.requiredDocument;
						this.contact = this.offer.contact;
						this.email = this.offer.email;
					}
				})
				.catch((error) => {
					this.loading = false;
					console.log(error);
				});
		},
		set_status() {
			fetch(Helper.route('admin/joboffer/' + this.id), Helper.requestOptions())
				.then((response) => {
					Helper.tokenChecker(response.status);
					return response.json();
				})
				.then((data) => {
					this.loading = false;
					if (data.data) {
						this.offer = data.data;
						this.post = this.offer.post;
						this.profile = this.offer.profile;
						this.location = this.offer.location;
						this.contract = this.offer.contract;
						this.placeNumber = this.offer.placeNumber;
						this.closingDate = this.$filters.convertDate(
							new Date(this.offer.closingDate).toJSON()
						);
						this.tasks = this.offer.tasks;
						this.summary = this.offer.summary;
						this.skills = this.offer.skills;
						this.aptitudes = this.offer.aptitudes;
						this.requiredDocument = Helper.html_entity_decode(
							this.offer.requiredDocument
						);
						this.contact = this.offer.contact;
						this.email = this.offer.email;
						console.log(this.closingDate);
					}
				})
				.catch((error) => {
					this.loading = false;
					console.log(error);
				});
		},
	},
	created() {
		this.id = this.$route.params.id;
		this.contract_types = Helper.contract_types;
		this.getOffer();
	},
};
</script>
<style></style>
