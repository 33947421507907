<template>
	<div class="sidebar hstack">
		<div class="bg-primary- left-bar"></div>
		<div class="right-bar vstack">
			<router-link to="/home" class="nav-item">
				<i class="uil uil-estate"></i>
				<span>{{ $t('sidebar.home') }} </span>
			</router-link>
			<routerLink to="/dashboard" class="nav-item">
				<i class="uil uil-tachometer-fast-alt"></i>
				<span>{{ $t('sidebar.dashboard') }}</span>
			</routerLink>
			<router-link to="/mail" class="nav-item" :class="active_menu('mails')">
				<i class="uil uil-envelope"></i>
				<span>{{ $t('sidebar.courriels') }}</span>
			</router-link>
			<router-link
				to="/employees"
				:title="$t('sidebar.ressource_management')"
				class="nav-item"
				:class="active_menu('employees')"
			>
				<i class="uil uil-user-check"></i>
				<span>{{ $t('sidebar.ressource_management___') }}</span>
			</router-link>
			<router-link
				to="/documents"
				class="nav-item"
				:class="active_menu('documents')"
			>
				<i class="uil uil-document-info"></i>
				<span>{{ $t('sidebar.documents') }}</span>
			</router-link>
			<router-link to="/offers" class="nav-item" :class="active_menu('offers')">
				<i class="uil uil-clipboard-alt"></i>
				<span>{{ $t('sidebar.candidate') }} </span>
			</router-link>
			<router-link to="/tools" class="nav-item" :class="active_menu('tools')">
				<i class="uil uil-jackhammer"></i>
				<span>{{ $t('sidebar.tools') }}</span>
			</router-link>
			<router-link
				to="/program-managements/sale"
				:title="$t('sidebar.program_managements')"
				:class="active_menu('program-managements')"
				class="nav-item"
			>
				<i class="uil uil-chart-line"></i>
				<span>{{ $t('sidebar.program_managements___') }}</span>
			</router-link>
		</div>
	</div>
</template>

<style>
.sidebar {
	height: 100vh;
	background-color: var(--first-color-second);
	width: 325px;
	overflow: auto;
}
.left-bar {
	width: 45px;
	height: 100vh;
}
.right-bar {
	height: 100vh;
	padding-top: 20vh;
}
.nav-item {
	margin-left: -35px;
	color: whitesmoke;
	margin-top: 15px;
	border-radius: 20px 0px 0px 20px;
	text-transform: uppercase;
}
.nav-item span {
	color: white;
	margin-left: 10px;
}
.nav-item i {
	font-size: 1.2em;
	color: white;
	border-radius: 20px 0px 0px 20px;
	padding: 8px;
	margin-right: 10px;
}
.nav-item.router-link-exact-active {
	background: whitesmoke;
	padding: 5px 0px;
}
.nav-item.router-link-exact-active span {
	color: #222;
	font-weight: 600;
}
.nav-item.router-link-exact-active i {
	color: white;
	background-color: #222;
}

.top-item.router-link-exact-active {
	color: blue !important;
}
</style>

<script>
export default {
	name: 'SidebarComponent',
	props: {
		sidebarMenu: {
			default: '',
		},
	},
	methods: {
		active_menu(menu) {
			if (this.sidebarMenu == menu) {
				return 'router-link-exact-active';
			}
		},
	},
};
</script>
