import 'sweetalert2/dist/sweetalert2.min.css';
import 'vue-select/dist/vue-select.css';
import 'viewerjs/dist/viewer.css';
import '@trevoreyre/autocomplete-vue/dist/style.css';

import App from './App.vue';
import Autocomplete from '@trevoreyre/autocomplete-vue';
import Loading from 'vue3-loading-screen';
import VueSweetalert2 from 'vue-sweetalert2';
import VueViewer from 'v-viewer';
import { createApp } from 'vue';
import { createI18n } from 'vue-i18n';
import { createPinia } from 'pinia';
import filters from './helpers/filters';
import messages from './helpers/i18n';
import router from './helpers/router';
import vSelect from 'vue-select';

const pinia = createPinia();
const app = createApp(App);

app.config.globalProperties.$filters = filters;

const sweet_options = {
	confirmButtonColor: '#0077ff',
	cancelButtonColor: '#E40613',
};

const i18n = new createI18n({
	locale:
		localStorage.getItem('language') != null
			? localStorage.getItem('language')
			: 'fr',
	messages,
});

app
	.use(i18n)
	.use(pinia)
	.use(Autocomplete)
	.component('v-select', vSelect)
	.use(VueSweetalert2, sweet_options)
	.use(Loading, {
		bg: 'rgba(0,0,0,0.4)',
		slot: `
        <div class="px-5 py-3 bg-gray-800 rounded">
        <h3 class="text-2 text-white"><i class="uil uil-spinner fs-2 text-white"></i> Chargement en cours...</h3>
        </div>
    `,
	})
	.use(VueViewer)
	.use(router)
	.mount('#app');
