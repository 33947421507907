<template>
	<div>
		<!-- <label for="" v-if="type == 'date' || type == 'time'">{{ placeholder }}</label> -->
		<label class="label-" for="" v-if="placeholder != ''">{{
			placeholder
		}}</label>

		<input
			disabled
			autocomplete="off"
			type="text"
			:value="modelValue"
			class="input-fcv form-control"
		/>
	</div>
</template>

<script>
export default {
	name: 'InputFCV',
	props: {
		placeholder: {
			default: '',
		},
		modelValue: {
			default: '',
		},
		value: {
			default: 'Valider',
		},
	},
	methods: {
		updateValue(event) {
			this.$emit('update:modelValue', event.target.value);
		},
	},
};
</script>
<style>
.input-fcv:disabled {
	background-color: transparent !important;
	color: #000;
	border: 1px solid transparent;
	padding: 0;
	cursor: text;
	font-weight: bold;
}
.label- {
	text-decoration: none;
}
</style>
