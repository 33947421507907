<template>
	<div>
		<Template :title="$t('resources.new_employee')" sidebarMenu="employees">
			<section class="p-5 py-4">
				<Back />

				<div class="row">
					<div class="col-xl-9">
						<div
							@click="testt"
							class="bg-second- shadow-sm text-uppercase text-center rounded-pill text-5 text-white px-4 py-1 mt-4"
						>
							{{ $t('resources.add_new_employee') }}
						</div>
						<div class="bg-light-hight- rounded my-4 py-5">
							<form
								@submit.prevent="employeeNew"
								class="col-lg-10 col-md-11 mx-auto"
							>
								<div class="mb-4 text-primary-" v-if="message != ''">
									{{ message }}
								</div>
								<div class="row">
									<div class="col-3 vstack">
										<input
											type="file"
											class="d-none"
											@change="onFileChange"
											id="photo"
											ref="photo"
											name="photo"
										/>
										<img
											:src="url"
											class="setting_user_image bg-white- border2"
											alt=""
										/>
										<i
											class="uil uil-camera text-1 text-primary-"
											@click="$refs.photo.click()"
											type="button"
											style="margin-top: -40px; margin-left: 92px"
										></i>
									</div>
									<div class="col-9">
										<div class="row">
											<div class="col">
												<InputFC
													:error="errors.lastname"
													:type="'text'"
													v-model="lastname"
													:placeholder="$t('global.firstname')"
												/>
											</div>
											<div class="col">
												<InputFC
													:error="errors.firstname"
													:type="'text'"
													v-model="firstname"
													:placeholder="$t('global.lastname')"
												/>
											</div>
										</div>
										<div class="row mt-4">
											<div class="col">
												<InputFC
													:error="errors.gender"
													:options="genders"
													:type="'select'"
													v-model="gender"
													:placeholder="$t('global.sex')"
												/>
											</div>
										</div>
									</div>
								</div>
								<hr />

								<div class="row">
									<div class="col-lg-6 mt-4">
										<InputFC
											:error="errors.birthDate"
											:type="'date'"
											v-model="birthDate"
											:placeholder="$t('global.burn_date')"
										/>
									</div>
									<div class="col-lg-6 mt-4">
										<InputFC
											:error="errors.birthPlace"
											:type="'text'"
											v-model="birthPlace"
											:placeholder="$t('global.birth_place')"
										/>
									</div>
								</div>

								<div class="row">
									<div class="col-lg-6 mt-4">
										<InputFC
											:error="errors.maritalStatus"
											:options="matrimonialStutuses"
											:type="'select'"
											v-model="maritalStatus"
											:placeholder="$t('global.marital_status')"
										/>
									</div>
									<div class="col-lg-6 mt-4">
										<InputFC
											:error="errors.childrenNumber"
											:type="'number'"
											v-model="childrenNumber"
											:placeholder="$t('global.child_number')"
										/>
									</div>
								</div>
								<hr />

								<div class="row">
									<div class="col-lg-6 mt-4">
										<InputFC
											:error="errors.address"
											:type="'text'"
											v-model="address"
											:placeholder="$t('global.address')"
										/>
									</div>
									<div class="col-lg-6 mt-4">
										<InputFC
											:error="errors.state"
											:type="'select'"
											:options="states"
											v-model="state"
											:placeholder="$t('global.state')"
										/>
									</div>
								</div>

								<div class="row">
									<div class="col-lg-6 mt-4">
										<InputFC
											:error="errors.telNumber"
											:type="'tel'"
											v-model="telNumber"
											:placeholder="$t('global.tel')"
										/>
									</div>
									<div class="col-lg-6 mt-4">
										<InputFC
											:error="errors.emailAddress"
											:type="'email'"
											v-model="emailAddress"
											:placeholder="$t('global.email')"
										/>
									</div>
								</div>
								<hr />

								<div class="row">
									<div class="col-lg-6 mt-4">
										<label for="">{{ $t('resources.poste') }}</label>
										<v-select
											multiple
											class="bg-white mt-1"
											v-model="job"
											:options="jobs"
											:reduce="(job) => job.id"
											label="name"
										></v-select>
										<span
											v-show="errors.job != ''"
											v-text="errors.job"
											class="error"
										></span>
									</div>
									<div class="col-lg-6 mt-4">
										<label for="">{{ $t('resources.sections') }}</label>
										<v-select
											multiple
											class="bg-white mt-1"
											v-model="section"
											:options="sections"
											:reduce="(section) => section.id"
											label="name"
										></v-select>
										<span
											v-show="errors.section != ''"
											v-text="errors.section"
											class="error"
										></span>
									</div>
								</div>
								<div class="row">
									<div class="col-lg-6 mt-4">
										<InputFC
											:error="errors.matricule"
											:type="'text'"
											v-model="matricule"
											:placeholder="$t('resources.registration_number')"
										/>
									</div>
									<div class="col-lg-6 mt-4">
										<InputFC
											:error="errors.grade"
											:type="'text'"
											v-model="grade"
											:placeholder="$t('resources.grade')"
										/>
									</div>
								</div>
								<hr />

								<div class="row">
									<div class="col-lg-6 mt-4">
										<InputFC
											:error="errors.startDate"
											:type="'date'"
											v-model="startDate"
											:placeholder="$t('resources.intry_into_service')"
										/>
									</div>
									<div class="col-lg-6 mt-4">
										<InputFC
											:error="errors.firstPaymentDate"
											:type="'date'"
											v-model="firstPaymentDate"
											:placeholder="$t('resources.date_first_payment')"
										/>
									</div>
								</div>
								<hr />

								<div class="row">
									<div class="col-lg-6 mt-4">
										<InputFC
											:error="errors.rate"
											:options="rates"
											:type="'select'"
											v-model="rate"
											:placeholder="$t('resources.star')"
										/>
									</div>
									<div class="col-lg-6 mt-4">
										<InputFC
											:error="errors.commitmentLevel"
											:type="'number'"
											:min="0"
											:max="100"
											v-model="commitmentLevel"
											:placeholder="$t('resources.commitment')"
										/>
									</div>
								</div>

								<div class="row">
									<div class="col-lg-6 mt-4">
										<InputFC
											:error="errors.qualities"
											:type="'text'"
											v-model="qualities"
											:placeholder="$t('resources.qualities')"
										/>
									</div>

									<div class="col-lg-6 mt-4">
										<InputFC
											:error="errors.experenceYears"
											:type="'number'"
											:min="0"
											:max="150"
											v-model="experenceYears"
											:placeholder="$t('resources.experience_years')"
										/>
									</div>
								</div>

								<hr />

								<InputFC
									:error="errors.observation"
									:type="'textarea'"
									v-model="observation"
									:placeholder="$t('resources.special_observations')"
								/>

								<div class="hstack">
									<div class="">
										<SpinnerMe :elementId="'spinner'" />
										<button id="submit" class="button px-4 py-1 mt-4">
											{{ $t('global.send') }}
										</button>
									</div>
									<div class="ms-3">
										<button
											class="button-default bg-light- text-color- fw-500 shadow-sm px-4 py-1 mt-4"
											@click="$router.go(-1)"
										>
											{{ $t('global.cancel') }}
										</button>
									</div>
								</div>
							</form>
						</div>
					</div>
					<div class="col-xl-3 mt-4">
						<div class="rounded bg-second- text-white text-4 text-center p-1">
							{{ $t('global.advice') }}
						</div>
						<div
							class="mt-1 bg-light-hight- text-alt- rounded text-7 text-center p-2"
							v-html="$t('resources.employee_advice_text')"
						></div>
						<router-link to="/offer/create"
							><button class="button bg-alt- btn-sm w-100 mt-1 p-1">
								{{ $t('resources.post_job_now') }}
							</button>
						</router-link>
					</div>
				</div>
			</section>
		</Template>
	</div>
</template>
<script>
import Template from '@/components/Template.vue';
import Back from '@/components/Back.vue';
import InputFC from '@/components/InputFC.vue';
import SpinnerMe from '@/components/SpinnerMe.vue';
import Helper from '@/helpers/helper';

export default {
	name: 'CreateEmployeePage',
	components: { Template, Back, InputFC, SpinnerMe },
	data() {
		return {
			errors: [],
			lastname: '',
			firstname: '',
			gender: '',
			birthDate: '',
			childrenNumber: '',
			state: '',
			grade: '',
			telNumber: '',
			startDate: '',
			birthPlace: '',
			emailAddress: '',
			maritalStatus: '',
			firstPaymentDate: '',
			observation: '',
			job: '',
			photo: null,
			url: '',
			section: [],
			matricule: '',
			rate: '',
			address: '',
			message: '',
			ok: false,
			check_form: false,
			sections: [],
			jobs: [],
			originalJobs: [],
			genders: [],
			states: [],
			matrimonialStutuses: [],
			rates: [],
			qualities: '',
			commitmentLevel: 0,
			experenceYears: 0,
			selected: null,
		};
	},
	methods: {
		onFileChange(e) {
			this.photo = e.target.files[0];
			this.url = URL.createObjectURL(this.photo);
		},
		employeeNew() {
			this.errors = [];
			this.check_form = true;

			if (this.beforeCreateItem()) {
				this.ok = true;
				this.error_message = '';
				Helper.loading('submit', 'spinner', true);
				let registerFormData = new FormData();
				registerFormData.append('lastname', this.lastname);
				registerFormData.append('firstname', this.firstname);
				registerFormData.append('birthPlace', this.birthPlace);
				registerFormData.append('telNumber', this.telNumber);
				registerFormData.append('emailAddress', this.emailAddress);
				registerFormData.append('gender', this.gender);
				registerFormData.append('matricule', this.matricule);
				registerFormData.append('maritalStatus', this.maritalStatus);
				registerFormData.append('childrenNumber', this.childrenNumber);
				registerFormData.append('jobs', JSON.stringify(this.job));
				registerFormData.append('grade', this.grade);
				registerFormData.append('state', this.state);
				registerFormData.append('sections', JSON.stringify(this.section));
				registerFormData.append('address', this.address);
				registerFormData.append(
					'birthDate',
					new Date(this.birthDate).toISOString()
				);
				registerFormData.append(
					'startDate',
					new Date(this.startDate).toISOString()
				);
				registerFormData.append(
					'firstPaymentDate',
					new Date(this.firstPaymentDate).toISOString()
				);
				registerFormData.append('qualities', this.qualities);
				registerFormData.append('commitmentLevel', this.commitmentLevel);
				registerFormData.append('experienceYears', this.experenceYears);
				registerFormData.append('observation', this.observation);
				registerFormData.append('rate', this.rate);

				if (this.photo != null) {
					console.log(this.photo);
					registerFormData.append('photo', this.photo);
				}

				fetch(
					Helper.route('admin/employee/add'),
					Helper.requestOptions('POST', registerFormData)
				)
					.then((response) => {
						Helper.tokenChecker(response.status);
						if (response.status != 200) {
							this.ok = false;
						}
						return response.json();
					})
					.then((data) => {
						this.check_form = false;
						Helper.loading('submit', 'spinner', false);

						console.log('data ', data);

						if (data.problems) {
							this.errors.lastname = Helper.getError(data.problems, 'lastname');
							this.errors.firstname = Helper.getError(
								data.problems,
								'firstname'
							);
							this.errors.birthPlace = Helper.getError(
								data.problems,
								'birthPlace'
							);
							this.errors.emailAddress = Helper.getError(
								data.problems,
								'emailAddress'
							);
							this.errors.gender = Helper.getError(data.problems, 'gender');
							this.errors.address = Helper.getError(data.problems, 'address');
							this.errors.matricule = Helper.getError(
								data.problems,
								'matricule'
							);
							this.errors.maritalStatus = Helper.getError(
								data.problems,
								'maritalStatus'
							);
							this.errors.childrenNumber = Helper.getError(
								data.problems,
								'childrenNumber'
							);
							this.errors.job = Helper.getError(data.problems, 'jobs');
							this.errors.grade = Helper.getError(data.problems, 'grade');
							this.errors.state = Helper.getError(data.problems, 'state');
							this.errors.section = Helper.getError(data.problems, 'sections');
							this.errors.birthDate = Helper.getError(
								data.problems,
								'birthDate'
							);
							this.errors.startDate = Helper.getError(data.problems, 'date');
							this.errors.firstPaymentDate = Helper.getError(
								data.problems,
								'firstPaymentDate'
							);
							this.errors.observation = Helper.getError(
								data.problems,
								'observation'
							);
							this.errors.rate = Helper.getError(data.problems, 'rate');
							this.errors.commitmentLevel = Helper.getError(
								data.problems,
								'commitmentLevel'
							);
							this.errors.experenceYears = Helper.getError(
								data.problems,
								'experienceYears'
							);
							this.errors.telNumber = Helper.getError(
								data.problems,
								'telNumber'
							);
						} else {
							if (!this.ok) {
								this.error_message = data.message;
								Helper.notification('error', this.error_message);
							} else {
								if (data.status) {
									Helper.notification('success', data.message);
									this.message = data.message;
									//this.$router.go(-1)
									this.lastname = '';
									this.firstname = '';
									this.birthDate = '';
									this.birthPlace = '';
									this.emailAddress = '';
									this.gender = '';
									this.matricule = '';
									this.maritalStatus = '';
									this.childrenNumber = '';
									this.job = '';
									this.grade = '';
									this.address = '';
									this.state = '';
									this.startDate = '';
									this.firstPaymentDate = '';
									this.observation = '';
									this.rate = '';
									this.telNumber = '';
									this.qualities = '';
									this.commitmentLevel = 0;
									this.experenceYears = 0;
									this.url = Helper.DEFAULT_IMAGE;
									this.photo = null;
								}
							}
						}
					})
					.catch((error) => {
						console.log(error);
						this.check_form = false;
						Helper.notification('error', error.toString());
						Helper.loading('submit', 'spinner', false);
					});
			}
		},
		beforeCreateItem() {
			if (Helper.required(this.lastname) !== '') {
				this.errors.lastname = Helper.required(this.lastname);
			}
			if (Helper.required(this.firstname) !== '') {
				this.errors.firstname = Helper.required(this.firstname);
			}
			if (Helper.required(this.matricule) !== '') {
				this.errors.matricule = Helper.required(this.matricule);
			}
			if (Helper.required(this.telNumber) !== '') {
				this.errors.telNumber = Helper.required(this.telNumber);
			}
			// if (Helper.required(this.emailAddress) !== '') {
			// 	this.errors.emailAddress = Helper.required(this.emailAddress);
			// }
			if (Helper.required(this.gender) !== '') {
				this.errors.gender = Helper.required(this.gender);
			}
			if (Helper.required(this.maritalStatus) !== '') {
				this.errors.maritalStatus = Helper.required(this.maritalStatus);
			}
			if (Helper.required(this.job) !== '') {
				this.errors.job = Helper.required(this.job);
			}
			if (Helper.required(this.state) !== '') {
				this.errors.state = Helper.required(this.state);
			}
			if (Helper.required(this.address) !== '') {
				this.errors.address = Helper.required(this.address);
			}
			if (Helper.required(this.birthPlace) != '') {
				this.errors.birthPlace = Helper.required(this.birthPlace);
			}
			if (Helper.required(this.birthDate) != '') {
				this.errors.birthDate = Helper.required(this.birthDate);
			}
			if (Helper.required(this.startDate) !== '') {
				this.errors.startDate = Helper.required(this.startDate);
			}
			if (Helper.required(this.firstPaymentDate) !== '') {
				this.errors.firstPaymentDate = Helper.required(this.firstPaymentDate);
			}
			if (Helper.required(this.rate) !== '') {
				this.errors.rate = Helper.required(this.rate);
			}
			return Helper.objectNumberKey(this.errors) == 0 ? true : false;
		},
		reloadState() {
			this.section = this.section.map((e) => e);
		},
		autofillSection(v) {
			const lastSelected = this.originalJobs.find(
				(j) => j.id == v[v.length - 1]
			);

			if (lastSelected) {
				for (const s of lastSelected.sections) {
					if (
						!this.section.find((i) => i.id == s.id) &&
						!this.section.find((i) => i == s.id)
					) {
						this.section.push({ id: s.id, name: s.name });
					}
				}
			}

			this.reloadState();
		},
	},

	created() {
		this.url = Helper.DEFAULT_IMAGE;
		this.rates = Helper.rates;
		this.genders = Helper.genders;
		this.matrimonialStutuses = Helper.matrimonialStutuses;
		this.states = Helper.states;

		fetch(Helper.route('admin/section/all'), Helper.requestOptions())
			.then((response) => response.json())
			.then((data) => {
				if (data.data) {
					data.data.forEach((section_item) => {
						this.sections.push({
							id: section_item.id,
							name: section_item.name,
						});
					});
				}
			})
			.catch((error) => {
				console.log(error);
			});

		fetch(Helper.route('admin/job/all'), Helper.requestOptions())
			.then((response) => response.json())
			.then((data) => {
				if (data.data) {
					this.originalJobs = data.data;
					data.data.forEach((job_item) => {
						this.jobs.push({
							id: job_item.id,
							name: job_item.designation,
						});
					});
				}
			})
			.catch((error) => {
				console.log(error);
			});
	},
	watch: {
		lastname: function () {
			if (this.check_form) {
				if (Helper.required(this.lastname) === '') {
					delete this.errors.lastname;
				} else {
					this.errors.lastname = Helper.required(this.lastname);
				}
			}
		},
		firstname: function () {
			if (this.check_form) {
				if (Helper.required(this.firstname) === '') {
					delete this.errors.firstname;
				} else {
					this.errors.firstname = Helper.required(this.firstname);
				}
			}
		},
		emailAddress: function () {
			if (this.check_form) {
				if (Helper.checkEmail(this.emailAddress) === '') {
					delete this.errors.emailAddress;
				} else {
					this.errors.emailAddress = Helper.checkEmail(this.emailAddress);
				}
			}
		},
		gender: function () {
			if (this.check_form) {
				if (Helper.required(this.gender) === '') {
					delete this.errors.gender;
				} else {
					this.errors.gender = Helper.required(this.gender);
				}
			}
		},
		matricule: function () {
			if (this.check_form) {
				if (Helper.required(this.matricule) === '') {
					delete this.errors.matricule;
				} else {
					this.errors.matricule = Helper.required(this.matricule);
				}
			}
		},
		maritalStatus: function () {
			if (this.check_form) {
				if (Helper.required(this.maritalStatus) === '') {
					if (
						this.maritalStatus === 'Célibataire' ||
						this.maritalStatus === 'Marié sans enfant'
					) {
						this.childrenNumber = 0;
					}
					delete this.errors.maritalStatus;
				} else {
					this.errors.maritalStatus = Helper.required(this.maritalStatus);
				}
			}
		},
		childrenNumber: function () {
			if (this.check_form) {
				if (Helper.required(this.childrenNumber) === '') {
					if (
						this.maritalStatus === 'Célibataire' ||
						this.maritalStatus === 'Marié sans enfant'
					) {
						this.childrenNumber = 0;
					} else if (this.childrenNumber == 0) {
						this.errors.childrenNumber = 'Vous devez avoir au moins un enfant';
					} else {
						delete this.errors.childrenNumber;
					}
				} else {
					this.errors.childrenNumber = Helper.required(this.childrenNumber);
				}
			}
		},
		job: function (newV, oldV) {
			if (oldV.length < newV.length) {
				this.autofillSection(newV);
			}

			if (this.check_form) {
				if (Helper.required(this.job) === '') {
					delete this.errors.job;
				} else {
					this.errors.job = Helper.required(this.job);
				}
			}
		},
		grade: function () {
			if (this.check_form) {
				if (Helper.required(this.grade) === '') {
					delete this.errors.grade;
				} else {
					this.errors.grade = Helper.required(this.grade);
				}
			}
		},
		address: function () {
			if (this.check_form) {
				if (Helper.required(this.address) === '') {
					delete this.errors.address;
				} else {
					this.errors.address = Helper.required(this.address);
				}
			}
		},
		state: function () {
			if (this.check_form) {
				if (Helper.required(this.state) === '') {
					delete this.errors.state;
				} else {
					this.errors.state = Helper.required(this.state);
				}
			}
		},
		birthDate: function () {
			if (this.check_form) {
				if (Helper.required(this.birthDate) === '') {
					delete this.errors.birthDate;
				} else {
					this.errors.birthDate = Helper.required(this.birthDate);
				}
			}
		},
		birthPlace: function () {
			if (this.check_form) {
				if (Helper.required(this.birthPlace) === '') {
					delete this.errors.birthPlace;
				} else {
					this.errors.birthPlace = Helper.required(this.birthPlace);
				}
			}
		},
		startDate: function () {
			if (this.check_form) {
				if (Helper.required(this.startDate) === '') {
					delete this.errors.startDate;
				} else {
					this.errors.startDate = Helper.required(this.startDate);
				}
			}
		},
		firstPaymentDate: function () {
			if (this.check_form) {
				if (Helper.required(this.firstPaymentDate) === '') {
					delete this.errors.firstPaymentDate;
				} else {
					this.errors.firstPaymentDate = Helper.required(this.firstPaymentDate);
				}
			}
		},
		observation: function () {
			if (this.check_form) {
				if (Helper.required(this.observation) === '') {
					delete this.errors.observation;
				} else {
					this.errors.observation = Helper.required(this.observation);
				}
			}
		},
		rate: function () {
			if (this.check_form) {
				if (Helper.required(this.rate) === '') {
					delete this.errors.rate;
				} else {
					this.errors.rate = Helper.required(this.rate);
				}
			}
		},
		telNumber: function () {
			if (this.check_form) {
				if (Helper.required(this.telNumber) === '') {
					delete this.errors.telNumber;
				} else {
					this.errors.telNumber = Helper.required(this.telNumber);
				}
			}
		},
	},
};
</script>
